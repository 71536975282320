<template>
  <div>
    <v-divider></v-divider>

    <composition-info :composition="composition"></composition-info>

    <v-divider></v-divider>

    <carousel
      :items="items"
      :config="carouselConfig"
      :current-item-index="currentItemIndex"
      @refresh-data="initData"
    >
      <template #carouselItem="{ item, classList }">
        <SoundRecordingCard
          :recording="item"
          :titleMaxWidth="titleMaxWidth"
          :showCompositionDetails="false"
          :class="classList"
        />
      </template>
    </carousel>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CompositionPageMenu } from "@/mixins/composition-page-menu";
import { CarouselMixin } from "@/mixins/carousel-mixin";
import CompositionInfo from "@/pages/compositions/components/CompositionInfo";
import Carousel from "@/components/carousel/Carousel";
import { CarouselPagingByCursorMixin } from "@/mixins/carousel-paging-by-cursor-mixin";
import SoundRecordingCard from "@/pages/recordings/components/SoundRecordingCard";

export default {
  name: "PotentialRelationships",

  components: { SoundRecordingCard, CompositionInfo, Carousel },

  mixins: [CompositionPageMenu, CarouselMixin, CarouselPagingByCursorMixin],

  data() {
    return {
      composition: { meta: {} },
      itemRouteParamName: "recordingId",
      pagingCursorItemPropertyName: "potential_relationship_id"
    };
  },

  computed: {
    basicRoute() {
      return `/${this.userCurrentAccountId}/compositions/${this.$route.params.compositionId}/${this.$route.meta.status}`;
    },

    singleItemRoute() {
      const recordingId = this.getRequestParams(this.$route.meta.status)
        .recordingId;
      return `${this.basicRoute}/${recordingId}`;
    }
  },

  methods: {
    ...mapActions([
      "initCurrentComposition",
      "getSinglePotentialRelationship",
      "updatePotentialRelationship",
      "getPotentialRelationships",
      "getPotentialRelationshipsTotalCount"
    ]),

    updateItem(params) {
      return this.updatePotentialRelationship(params);
    },

    getItemsList(params) {
      return this.getPotentialRelationships(params);
    },

    getItem(params) {
      return this.getSinglePotentialRelationship(params);
    },

    getRequestParams(status = this.$route.meta.status) {
      let params = CarouselPagingByCursorMixin.methods.getRequestParams.call(
        this,
        status
      );

      params.compositionId = this.$route.params.compositionId;

      return params;
    },

    getTotalCounter(params) {
      return this.getPotentialRelationshipsTotalCount(params);
    }
  },

  created() {
    this.initCurrentComposition(this.$route.params.compositionId)
      .then(composition => {
        this.composition = composition;
      })
      .then(() => {
        this.initData();
      });
  },

  metaInfo: {
    title: "Potential Relationships"
  }
};
</script>
