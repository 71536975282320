<template>
  <v-alert
    :type="alertType"
    dismissible
    v-model="opened"
    v-if="alertMessage"
    class="alert"
    text
    style="z-index: 250;"
  >
    {{ alertMessage }}
  </v-alert>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Alert",
  data() {
    return {
      visible: false,
      timeoutId: null
    };
  },
  computed: {
    ...mapState({
      alertMessage: state => state.alert.alertMessage,
      alertType: state => state.alert.alertType,
      alertDisplayPeriod: state => state.alert.alertDisplayPeriod
    }),
    opened: {
      get() {
        return this.visible;
      },
      set(val) {
        this.visible = val;
        if (!val) {
          this.clearAlert();
          clearTimeout(this.timeoutId);
          this.timeoutId = null;
        }
      }
    }
  },
  methods: {
    ...mapActions(["clearAlert"])
  },
  watch: {
    alertMessage: {
      handler: function(val) {
        if (val) {
          this.opened = true;
          this.timeoutId = setTimeout(() => {
            this.opened = false;
          }, this.alertDisplayPeriod);
        }
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.alert
  z-index: 100
  position: fixed
  align-self: flex-end
  max-width: 40vw
  justify-self: flex-end
  bottom: 0
  margin-right: 10px

.alert ::v-deep .v-alert__icon
  align-self: center

.alert.v-alert--text:before
  z-index: -1
  opacity: 1

@import '~vuetify/src/styles/styles.sass'

.alert.success--text:before
  background-color: map-get($green,'lighten-5')

.alert.info--text:before
  background-color: map-get($blue,'lighten-5')

.alert.warning--text:before
  background-color: map-get($orange,'lighten-5')

.alert.error--text:before
  background-color: map-get($red,'lighten-5')
</style>
