<template>
  <v-card elevation="0" width="500">
    <v-card-title>Metrics info</v-card-title>

    <v-list>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.total_conflicting_recordings
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Total SR assets in conflict report</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.total_conflicting_approx_daily_views
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Total sr_approx_daily_views in conflict
            report</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.total_conflicting_views
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Total views in conflict</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.conflicting_recordings_percent
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Total assets in conflict report / total assets in asset
            report</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.conflicting_approx_daily_views_percent
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Total sr_approx_daily_views in conflict report / total
            sr_approx_daily_views in asset report</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.new_conflicting_recordings
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Count of assets in current day’s conflict report not in previous
            day’s conflict report</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="item-title">{{
            metrics.resolved_conflicting_recordings
          }}</v-list-item-title>
          <v-list-item-subtitle class="font-weight-light item-subtitle"
            >Count of assets in previous day’s conflict report not in current
            day’s conflict report</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { OWNERSHIP_CONFLICTS_ANALYTICS_METRICS } from "@/components/enums";

export default {
  name: "MetricsInfo",

  data: () => ({
    metrics: OWNERSHIP_CONFLICTS_ANALYTICS_METRICS
  })
};
</script>

<style scoped lang="sass">
.item-title
  font-size: 12px

.item-subtitle
  font-size: 12px

.v-card__title
  padding: 5px 16px 0 16px

.v-list
  padding: 0

  .v-list-item--two-line
    min-height: 0
</style>
