import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Modules
import auth from "@/store/modules/auth";
import users from "@/store/modules/users";
import navigation from "@/store/modules/navigation";
import navigation_details from "@/store/modules/navigation-details";
import labels from "@/store/modules/labels";
import compositions from "@/store/modules/compositions";
import sound_recordings from "@/store/modules/recordings";
import providers_data from "@/store/modules/providers-data";
import global_loading from "@/store/modules/loading";
import statistics from "@/store/modules/statistics";
import content_import from "@/store/modules/content-import";
import settings from "@/store/modules/settings";
import supervisor from "@/store/modules/supervisor";
import alert from "@/store/modules/alert";
import ownership_candidates from "@/store/modules/ownership-candidates";
import persistent from "@/store/modules/persistent";
import global_search from "@/store/modules/global-search";
import reports from "@/store/modules/reports";
import ownership_conflicts from "@/store/modules/ownership-conflicts";

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    apiPrefix: `/api`,
    cookiePath: `/sanctum/csrf-cookie`,
    // apiPrefix: "/api/<account_id>" to access account's specific data
    loading: false
  };
};

const state = getDefaultState();

export default new Vuex.Store({
  state: state,
  mutations: {},
  actions: {},
  modules: {
    auth,
    users,
    navigation,
    navigation_details,
    labels,
    compositions,
    global_loading,
    sound_recordings,
    providers_data,
    statistics,
    content_import,
    settings,
    supervisor,
    alert,
    ownership_candidates,
    persistent,
    global_search,
    reports,
    ownership_conflicts
  },
  plugins: [
    createPersistedState({
      key: "app.data.store",
      paths: ["auth", "navigation", "persistent", "ownership_conflicts"]
    })
  ]
});
