import moment from "moment/moment";

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function normalizeText(text) {
  return text
    ? text
        .toLocaleLowerCase()
        .trim()
        // remove diacritics
        .normalize("NFD")
        // Replace all diacritics symbols in the range \u0300-\u036f
        // {@link https://en.wikipedia.org/wiki/Combining_Diacritical_Marks}
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s.*]|_/g, " ")
        // Replace dot between 2 words without spaces
        .replace(/(?!\s)\.+(?!\s)/g, " ")
    : "";
}

export function clipboardCopy(newClip) {
  return navigator.clipboard.writeText(newClip).then(
    () => {
      console.log("Copied to clipboard");
    },
    () => {
      console.error("Copying to clipboard failed");
    }
  );
}

export function clipboardPaste() {
  return navigator.clipboard.readText();
}

export function objectsEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function formatDate(date, format) {
  if (!date) {
    return null;
  }

  return (
    moment(String(date))
      // .utc()
      .format(format)
  );
}
