<template>
  <v-card elevation="0">
    <v-switch
      :disabled="disabled"
      v-model="is_active"
      color="primary"
      inset
      :loading="loading"
      @click="updateSettings"
    ></v-switch>
  </v-card>
</template>

<script>
export default {
  name: "ActiveAccountSwitcher",

  props: {
    account: {
      type: Object
    },
    processing: {
      type: Function
    },
    disabled: {
      type: Boolean
    }
  },

  data: () => ({
    is_active: true,
    loading: false
  }),

  methods: {
    updateSettings() {
      this.loading = true;

      const data = {
        accountId: this.account.id,
        payload: {
          is_active: this.is_active
        }
      };
      this.processing(data).finally(() => {
        this.loading = false;
      });
    }
  },

  created() {
    this.is_active = this.account.is_active;
  }
};
</script>
