import { PageMenu } from "@/mixins/page-menu";

export const OwnershipConflictsPageMenu = Object.assign({}, PageMenu, {
  computed: {
    getMenuItems: function() {
      return [];
    },

    hasBackButton: function() {
      return true;
    },

    getPageDetailsSectionComponent: function() {
      return "OwnershipConflictsNavigationDetails";
    }
  }
});
