var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card-title',{staticClass:"pl-1"},[_vm._v(" Accounts ")])],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalResultsCount,"footer-props":_vm.tableFooterProps,"items-per-page":_vm.defaultItemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('active-account-switcher',{staticClass:"d-flex justify-center",attrs:{"disabled":_vm.isCurrentAccount(item),"account":item,"processing":_vm.onUpdateAccountSettings}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.settings.recordings_search_enabled",fn:function(ref){
var item = ref.item;
return [_c('settings-switcher',{staticClass:"d-flex justify-center",attrs:{"account":item,"processing":_vm.onUpdateAccountSettings,"setting-name":'recordings_search_enabled'}})]}},{key:"item.settings.backup_assets_reports",fn:function(ref){
var item = ref.item;
return [_c('settings-switcher',{staticClass:"d-flex justify-center",attrs:{"account":item,"processing":_vm.onUpdateAccountSettings,"setting-name":'backup_assets_reports'}})]}},{key:"item.settings.import_assets_reports",fn:function(ref){
var item = ref.item;
return [_c('settings-switcher',{staticClass:"d-flex justify-center",attrs:{"account":item,"processing":_vm.onUpdateAccountSettings,"setting-name":'import_assets_reports'}})]}},{key:"item.settings.api_access_enabled",fn:function(ref){
var item = ref.item;
return [_c('settings-switcher',{staticClass:"d-flex justify-center",attrs:{"account":item,"processing":_vm.onUpdateAccountSettings,"setting-name":'api_access_enabled'}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"data-table-actions-menu pa-0",attrs:{"nav":"","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editAccountClick(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s("Edit")}})],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.isCurrentAccount(item)},on:{"click":function($event){return _vm.deleteAccountClick(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s("Delete")}})],1)],1)],1)],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("accountTypeToHumanReadable")(item.type))+" ")]}}])})],1),(_vm.accountToEdit)?_c('edit-account',{attrs:{"opened":!!_vm.accountToEdit,"account":_vm.accountToEdit},on:{"onClose":_vm.onCloseEditModal,"onUpdate":_vm.onAccountUpdate}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }