<template>
  <v-card class="mx-auto mt-4 pa-md-4" elevation="0" width="400">
    <v-container class="d-flex flex-column align-center mb-16">
      <router-link to="/">
        <v-img
          max-height="100"
          max-width="150"
          :src="require('/public/logo-bw.png')"
        ></v-img>
      </router-link>
    </v-container>

    <v-container class="d-flex flex-column align-center mt-16 text-h5">
      <v-icon size="40" class="mb-4">mdi-cloud-question</v-icon>
      <span>Page not found</span>
    </v-container>
  </v-card>
</template>

<script>
export default {
  metaInfo: {
    title: "404"
  }
};
</script>
