<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="410px"
    v-blur-all-on-close-dialog
  >
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>Edit Account</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <text-field label="Name" v-model="formData.name" />
              <text-field
                label="Content owner ID"
                v-model="formData.content_owner_id"
              />
              <v-select
                label="Type"
                :items="accountTypes"
                v-model="formData.type"
              />
              <v-row>
                <v-col class="d-flex align-center">
                  <span class="v-label">Active</span>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-switch
                    v-model="formData.is_active"
                    color="primary"
                    inset
                    :false-value="false"
                    :true-value="true"
                    class="mt-0"
                    hide-details
                    :disabled="isCurrentAccount(account)"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <span class="v-label">Search SR</span>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-switch
                    v-model="formData.recordings_search_enabled"
                    color="primary"
                    inset
                    :false-value="false"
                    :true-value="true"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <span class="v-label">Backup assets reports</span>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-switch
                    v-model="formData.backup_assets_reports"
                    color="primary"
                    inset
                    :false-value="false"
                    :true-value="true"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <span class="v-label">Import assets reports</span>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-switch
                    v-model="formData.import_assets_reports"
                    color="primary"
                    inset
                    :false-value="false"
                    :true-value="true"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <span class="v-label">API access</span>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-switch
                    v-model="formData.api_access_enabled"
                    color="primary"
                    inset
                    :false-value="false"
                    :true-value="true"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="processing" @click="onClose">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="processing"
            :disabled="invalid || processing"
            @click.prevent="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextField from "@/components/forms/inputs/TextField";
import { ACCOUNT_TYPE, ACCOUNT_TYPE_NAME } from "@/components/enums";

const getDefaultFormData = () => {
  return {
    name: "",
    content_owner_id: "",
    is_active: false,
    recordings_search_enabled: false,
    backup_assets_reports: false,
    import_assets_reports: false,
    api_access_enabled: true,
    type: ACCOUNT_TYPE.publishing
  };
};

export default {
  name: "EditAccount",
  components: { TextField },

  data() {
    return {
      processing: false,
      invalid: false,
      formData: Object.assign({}, getDefaultFormData()),
      accountTypes: [
        {
          text: ACCOUNT_TYPE_NAME.publishing,
          value: ACCOUNT_TYPE.publishing
        },
        {
          text: ACCOUNT_TYPE_NAME.sound_recordings_management,
          value: ACCOUNT_TYPE.sound_recordings_management
        }
      ]
    };
  },

  props: {
    opened: {
      type: Boolean,
      default: false
    },

    account: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(["updateAccountSettings"]),

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.processing = true;
        this.updateAccountSettings({
          payload: {
            name: this.formData.name,
            content_owner_id: this.formData.content_owner_id,
            is_active: this.formData.is_active,
            settings: Object.assign({}, this.account.settings, {
              recordings_search_enabled: this.formData
                .recordings_search_enabled,
              backup_assets_reports: this.formData.backup_assets_reports,
              import_assets_reports: this.formData.import_assets_reports,
              api_access_enabled: this.formData.api_access_enabled
            }),
            type: this.formData.type
          },
          accountId: this.account.id
        })
          .then(() => {
            this.onClose();
            this.$emit("onUpdate");
          })
          .catch(error => {
            const errors = error?.response?.data?.data;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    onClose() {
      this.dialog = false;
    },

    isCurrentAccount(account) {
      return account.id === this.userCurrentAccountId;
    }
  },

  computed: {
    ...mapGetters(["userCurrentAccountId"]),

    dialog: {
      get() {
        return this.opened;
      },
      set(val) {
        if (val) {
          this.$emit("onOpen");
        } else {
          this.$emit("onClose");
        }
      }
    }
  },

  watch: {
    account: {
      immediate: true,
      deep: true,
      handler: function() {
        this.formData.recordings_search_enabled = this.account.settings.recordings_search_enabled;
        this.formData.backup_assets_reports = this.account.settings.backup_assets_reports;
        this.formData.import_assets_reports = this.account.settings.import_assets_reports;
        this.formData.api_access_enabled = this.account.settings.api_access_enabled;
        this.formData.is_active = this.account.is_active;
        this.formData.content_owner_id = this.account.content_owner_id;
        this.formData.name = this.account.name;
        this.formData.type = this.account.type;
      }
    }
  }
};
</script>
