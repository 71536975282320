<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Sound Recordings
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="text-start px-1 py-0">
              <v-hover v-slot="{ hover }" :open-delay="500">
                <v-container>
                  <span>{{ item.metadata.title || "(no title)" }}</span>
                  <br />
                  <template v-for="(label, index) in showLabels(item)">
                    <v-chip
                      label
                      link
                      x-small
                      class="mr-2 px-1 text-caption grey--text text--darken-2 font-weight-medium"
                      :key="index"
                      >{{ label }}
                    </v-chip>
                  </template>
                  <span class="caption grey--text text--darken-4">{{
                    getRemainder(item)
                  }}</span>
                  <template v-if="hover">
                    <v-card
                      max-width="450"
                      elevation="3"
                      class="ml-16 recording-block"
                    >
                      <sound-recording-metadata
                        :recording="item"
                      ></sound-recording-metadata>
                    </v-card>
                  </template>
                </v-container>
              </v-hover>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.youtube_asset_id | default_value }}</span
              ><br />
              <span>{{ item.metadata.custom_id | default_value }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.isrc | default_value }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.created_at | date }}</span
              ><br />
              <span class="grey--text text--darken-1">{{
                item.metadata.created_at | humanizeTime
              }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.updated_at | date }}</span
              ><br />
              <span class="grey--text text--darken-1">{{
                item.metadata.updated_at | humanizeTime
              }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CompositionPageMenu } from "@/mixins/composition-page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import SoundRecordingMetadata from "@/pages/recordings/components/SoundRecordingMetadata";
import { humanizeTime } from "@/filters/time";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";

export default {
  name: "SoundRecordings",
  mixins: [CompositionPageMenu, GridUtils, DataTableFooterProps],
  components: { SoundRecordingMetadata },
  filters: { humanizeTime },

  data: () => ({
    maxLabelsCount: 5,
    headers: [
      {
        text: "Title | Labels",
        align: "start",
        sortable: false,
        value: "meta.title"
      },
      {
        text: "Youtube Asset ID | Custom ID",
        align: "end",
        sortable: false,
        width: "200px"
      },
      {
        text: "ISRC",
        align: "end",
        sortable: false,
        width: "150px"
      },
      {
        text: "Created On",
        align: "end",
        sortable: false,
        value: "meta.created_at",
        width: "150px"
      },
      {
        text: "Updated On",
        align: "end",
        sortable: false,
        value: "meta.updated_at",
        width: "150px"
      }
    ]
  }),

  methods: {
    ...mapActions(["initCurrentComposition", "getRecordings"]),

    getQueryParams() {
      return {
        compositionId: this.$route.params.compositionId,
        page: this.options.page,
        size: this.options.itemsPerPage,
        query: this.search.length < this.searchMinCharsCount ? "" : this.search
      };
    },

    getResultsActionName() {
      return "getRecordings";
    },

    showLabels(item) {
      return item.metadata.labels.slice(0, this.maxLabelsCount);
    },

    getRemainder(item) {
      let remainder = item.metadata.labels.length - this.maxLabelsCount;
      if (remainder > 0) {
        return `(+${remainder})`;
      }
    }
  },

  mounted() {
    this.initCurrentComposition(this.$route.params.compositionId);
  },

  metaInfo: {
    title: "Sound Recordings"
  }
};
</script>

<style lang="sass" scoped>
.recording-block
  position: absolute
</style>
