<template>
  <span v-if="value" class="font-weight-light text-no-wrap">
    (Has Active References)</span
  >
  <span v-else class="red--text text--darken-1 font-weight-medium text-no-wrap"
    >(No Active References)</span
  >
</template>

<script>
export default {
  name: "SoundRecordingHasActiveReferencesMarker",

  props: {
    value: {
      type: Boolean
    }
  }
};
</script>
