<template>
  <v-card class="popup-container">
    <v-card-text>
      <div class="popup-label">Asset</div>
      <div class="text--primary popup-text-value">
        {{ item.meta.title }}
      </div>
      <div class="text-caption py-0 mb-3">
        <v-icon>mdi-playlist-music</v-icon>
        Composition -
        {{ item.meta.created_at | date }},
        {{ item.meta.created_at | humanizeTime }}
      </div>
      <v-container class="pa-0 mb-3" v-if="item.meta.labels.length">
        <div class="popup-label">Asset labels</div>
        <template v-for="(label, index) in item.meta.labels">
          <v-chip
            label
            link
            x-small
            class="mr-2 px-1 text-caption grey--text text--darken-2 font-weight-medium"
            :key="index"
            >{{ label.name }}
          </v-chip>
        </template>
      </v-container>
      <div class="popup-label">Identifiers</div>
      <v-list class="transparent pa-0 mb-3">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Asset ID
          </v-list-item-title>
          <v-hover v-slot="{ hover }">
            <v-list-item-subtitle
              class="text-left popup-text-value text--primary d-flex align-center"
            >
              <span class="overflow-ellipsis">{{
                item.meta.asset_id | default_value
              }}</span>
              <copy-text
                :value="item.meta.asset_id"
                v-if="item.meta.asset_id && hover"
                style="height: auto"
              />
            </v-list-item-subtitle>
          </v-hover>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Custom ID
          </v-list-item-title>
          <v-hover v-slot="{ hover }">
            <v-list-item-subtitle
              class="text-left popup-text-value text--primary d-flex align-center"
            >
              <span class="overflow-ellipsis">{{
                item.meta.custom_id | default_value
              }}</span>
              <copy-text
                :value="item.meta.custom_id"
                v-if="item.meta.custom_id && hover"
                style="height: auto"
              />
            </v-list-item-subtitle>
          </v-hover>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >YouTube Asset ID
          </v-list-item-title>
          <v-hover v-slot="{ hover }">
            <v-list-item-subtitle
              class="text-left popup-text-value text--primary d-flex align-center"
            >
              <span class="overflow-ellipsis">{{
                item.youtube_asset_id | default_value
              }}</span>
              <copy-text
                :value="item.youtube_asset_id"
                v-if="item.youtube_asset_id && hover"
                style="height: auto"
              />
            </v-list-item-subtitle>
          </v-hover>
        </v-list-item>
      </v-list>
      <div class="popup-label">Metadata</div>
      <v-list class="transparent pa-0">
        <v-list-item class="popup-data-row pa-0 mb-3">
          <v-list-item-title class="text-left popup-text-value"
            >Writers
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ item.meta.writers.join(", ") }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
      <div class="popup-label">Asset owners</div>
      <div class="popup-text-value text--primary">
        {{ userCurrentAccount.name }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { humanizeTime } from "@/filters/time";
import { mapGetters } from "vuex";
import CopyText from "@/components/CopyText";

export default {
  name: "CompositionPopupDetail",
  components: { CopyText },
  filters: { humanizeTime },
  props: ["item"],

  computed: {
    ...mapGetters(["userCurrentAccount"])
  }
};
</script>

<style lang="sass" scoped>
.popup-container
  .popup-data-row
    min-height: 20px

  .popup-label
    letter-spacing: 0.011em
    font-size: 12px

  .popup-text-value
    font-size: 0.78rem

  .v-list-item__title
    flex: 1 1 60%

  .v-list-item__subtitle
    min-height: 22px

  .overflow-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
</style>
