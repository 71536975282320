<template>
  <v-card elevation="0">
    <ChartJsLineChart
      :chart-data="chartData"
      :options="mergedOptions"
      :height="450"
    />
  </v-card>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { LineChart as ChartJsLineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import merge from "lodash.merge";

Chart.register(...registerables);

export default defineComponent({
  name: "LineChart",

  components: { ChartJsLineChart },

  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      defaultChartOptions: {
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "start",
            labels: {
              color: "#333",
              font: {
                size: 12
              }
            }
          },
          tooltip: {
            callbacks: {
              title: items => items[0].label,
              label: item => {
                const dataset = item.dataset;
                const label = dataset.label || "";
                const value = item.raw;
                return ` ${label}: ${value}`;
              }
            },
            interaction: {
              mode: "index",
              intersect: false
            },
            backgroundColor: "#ffffff",
            titleColor: "#000000",
            bodyColor: "#333333",
            borderColor: "#cccccc",
            borderWidth: 1,
            displayColors: true
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        animation: {
          duration: 500,
          easing: "easeInOutSine"
        },
        elements: {
          line: {
            borderWidth: 1,
            tension: 0
          },
          point: {
            radius: 2.5
          }
        }
      }
    };
  },

  computed: {
    mergedOptions() {
      return merge({}, this.defaultChartOptions, this.chartOptions);
    }
  }
});
</script>
