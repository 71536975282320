<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Content Import
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center">
        <import-wizard
          :import-type="importType"
          @onImportFinished="onImportFinished"
        />
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <p class="xs2">
          The current mechanism provides the ability to import structured
          compositions data into the current account.
          <br />
          The imported CSV file should be compressed to ZIP-archive (a CSV
          template can be found
          <a
            href="https://publishing-cms.s3.us-east-2.amazonaws.com/templates/template.csv"
            target="_blank"
            >here</a
          >). Expected columns (<span style="font-weight: bold"
            >in strict order & case-sensitive names</span
          >):
        </p>
        <ul>
          <li>sr_isrcs</li>
          <li>sr_artists</li>
          <li>composition_share_custom_id</li>
          <li>composition_share_asset_id</li>
          <li>composition_share_iswc</li>
          <li>composition_share_title</li>
          <li>
            composition_share_writers
          </li>
          <li>
            composition_share_asset_labels
          </li>
          <li>ownership_sync_percent</li>
          <li>
            ownership_sync_territories
          </li>
          <li>ownership_sync_coverage</li>
          <li>ownership_mech_percent</li>
          <li>
            ownership_mech_territories
          </li>
          <li>ownership_mech_coverage</li>
          <li>ownership_lyric_percent</li>
          <li>
            ownership_lyric_territories
          </li>
          <li>ownership_lyric_coverage</li>
          <li>ownership_perf_percent</li>
          <li>
            ownership_perf_territories
          </li>
          <li>ownership_perf_coverage</li>
        </ul>
        <p class="mt-3">
          Columns for some ownership types can be removed from the uploaded
          document if appropriate values don't exist (for example, a mechanical
          ownership rights' group: "ownership_mech_percent",
          "ownership_mech_territories" and "ownership_mech_coverage").
          <br />
          Values for territories columns should be specified using two-character
          country code, comma separated. If the coverage field is set to
          "global" - territories list must be omitted.
          <br />
          Artists and writers must use pipe "|" as a delimiter.
          <br />
          The import can also be used to enrich compositions with additional
          fields, like YouTube Asset ID (a CSV example can be found
          <a
            href="https://publishing-cms.s3.us-east-2.amazonaws.com/templates/enrich_compositions_example.csv"
            target="_blank"
            >here</a
          >)
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ImportWizard from "@/components/import/ImportWizard";
import { PageMenu } from "@/mixins/page-menu";
import { mapActions } from "vuex";
import { IMPORT_TYPES } from "@/components/enums";

export default {
  name: "CompositionsImport",

  mixins: [PageMenu],

  components: { ImportWizard },

  data: () => ({
    importType: IMPORT_TYPES.external_cms
  }),

  metaInfo: {
    title: "Import"
  },

  methods: {
    ...mapActions(["resetCurrentComposition"]),

    onImportFinished() {
      // Reset current composition to display valid data
      this.resetCurrentComposition();
    }
  }
};
</script>
