<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import AppLayout from "@/layouts/AppLayout";
import PublicAppLayout from "@/layouts/PublicAppLayout";
import { DEFAULT_LAYOUT, TITLE_TEMPLATE } from "@/components/constants";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    EmptyLayout,
    AppLayout,
    PublicAppLayout
  },
  computed: {
    ...mapGetters(["isAuthorized", "isConfirmSwitchAccountWindowActive"]),

    layout() {
      return this.$route.meta.layout || DEFAULT_LAYOUT;
    }
  },
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: TITLE_TEMPLATE
  },
  mounted() {
    window.isAuthenticationRequired = () => {
      return !this.isAuthorized;
    };
    // Show Switch Account Window if it was called before and by some reason page was reloaded
    if (this.isConfirmSwitchAccountWindowActive) {
      this.$store.dispatch("showSwitchingCurrentAccountModal");
    }
  }
};
</script>
