import store from "@/store"; // We need to import store like this here

export default (to, from, next) => {
  if (!store.getters.isSupervisor) {
    console.log(
      "Not-supervisor tries to reach forbidden page. Redirecting to Dashboard."
    );
    const accountId = to?.params?.accountId
      ? to?.params?.accountId
      : store.getters.userCurrentAccountId;

    next({
      name: "Dashboard",
      params: {
        accountId: accountId
      }
    });
  } else {
    next();
  }
};
