<template>
  <confirm-password-form @clicked="onSubmit" :processing="processing">
    <template v-slot:title>
      <v-container class="my-2 pa-0">
        <p class="text-left font-weight-light text-h6">
          Almost there!
        </p>
        <p class="text-left font-weight-light text-subtitle-2">
          Please set your new password:
        </p>
      </v-container>
    </template>
  </confirm-password-form>
</template>

<script>
import { mapActions } from "vuex";

import ConfirmPasswordForm from "@/components/forms/ConfirmPasswordForm";

export default {
  name: "ResetPassword",
  components: {
    ConfirmPasswordForm
  },
  data: () => ({
    processing: false
  }),
  methods: {
    ...mapActions(["resetPassword"]),
    onSubmit(values) {
      this.processing = true;
      let token = location.pathname.split("/").slice(-1);
      this.resetPassword({ password: values.password, token }).finally(() => {
        this.processing = false;
      });
    }
  },
  metaInfo: {
    title: "Reset Password"
  }
};
</script>
