import { request } from "@/services/api";

const getDefaultState = () => {
  return {
    id: null,
    processing_status: null,
    statistics: {
      successful_rows_count: 0,
      failed_rows_count: 0,
      total_rows_count: 0,
      imported_rows_count: 0
    }
  };
};

const state = getDefaultState();

export default {
  name: "content-import",
  state,
  actions: {
    contentUpload({ getters, rootState }, { file, importType }) {
      const accountId = getters.userCurrentAccountId;
      let formData = new FormData();
      formData.append("archive", file);
      formData.append("type", importType);

      return request.post(
        `${rootState.apiPrefix}/accounts/${accountId}/import/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    },

    contentImportExecute({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.post(
        `${rootState.apiPrefix}/accounts/${accountId}/import/${params.importId}/execute`,
        {
          import_id: params.importId,
          override: params.override
        }
      );
    },

    contentImportStatus({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/import/${params.importId}/status`
      );
    }
  },

  getters: {
    getNewImportModel(state) {
      return state;
    }
  }
};
