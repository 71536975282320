<template>
  <v-menu
    offset-y
    bottom
    close-on-click
    content-class="no-opacity"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        v-model="globalSearch"
        prepend-inner-icon="mdi-magnify"
        label="Search for your content"
        single-line
        hide-details
        height="40"
        outlined
        dense
        type="text"
        clearable
        @click:append-inner="onClear"
        role="text"
        :loading="isLoading"
      ></v-text-field>
    </template>
    <v-card class="pa-0" v-if="showSection">
      <v-tabs align-with-title v-if="false">
        <v-tab class="text-size">Assets</v-tab>
      </v-tabs>
      <template v-if="showNoDataPopUp">
        <v-list-item>
          <v-list-item-title class="text-center"
            >No data were found to match your search</v-list-item-title
          >
        </v-list-item>
      </template>
      <v-list two-line v-if="showDataPopUp">
        <v-list-item-group>
          <template v-for="(item, index) in searchResults">
            <v-list-item :key="item.id + index" @click="onRowClicked(item)">
              <div class="pa-3 mr-2 grey lighten-2">
                <v-icon>
                  mdi-playlist-music
                </v-icon>
              </div>
              <v-list-item-content>
                <v-list-item-title class="text-size"
                  >{{ item.meta.writers.join(", ") }} -
                  {{ item.meta.title }}</v-list-item-title
                >
                <v-list-item-subtitle class="text-size"
                  >{{ item.meta.custom_id }} • Composition
                  Share</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{
                  item.meta.created_at | date
                }}</v-list-item-action-text>
                <v-list-item-action-text>Created</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index < searchResults.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import {
  FETCH_DATA_TIMEOUT,
  GLOBAL_SEARCH_MIN_CHARS_COUNT,
  GLOBAL_SEARCH_PAGE_SIZE
} from "@/components/constants";
import { GLOBAL_SEARCH_SCOPES } from "@/components/enums";

export default {
  data: () => ({
    timeout: null,
    searchOptions: {},
    previousSearch: "",
    globalSearch: "",
    searchMinCharsCount: GLOBAL_SEARCH_MIN_CHARS_COUNT,
    isLoading: false,
    searchResults: []
  }),

  watch: {
    globalSearch: {
      handler(query) {
        if (query && query.length < this.searchMinCharsCount) {
          this.searchResults = [];
          clearTimeout(this.timeout);
          return false;
        }

        this.searchResults = [];
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.searchData, FETCH_DATA_TIMEOUT);
      }
    },

    searchOptions: {
      handler() {
        this.searchData();
      },
      deep: true
    }
  },

  computed: {
    showNoDataPopUp() {
      return (
        !this.isLoading &&
        this.globalSearch &&
        !this.searchResults.length &&
        this.globalSearch.length >= this.searchMinCharsCount &&
        this.previousSearch === this.globalSearch
      );
    },

    showSection() {
      return (
        !this.isLoading &&
        this.globalSearch &&
        this.globalSearch.length >= this.searchMinCharsCount
      );
    },

    showTabs() {
      return (
        !this.isLoading &&
        this.globalSearch.length >= this.searchMinCharsCount &&
        this.previousSearch === this.globalSearch
      );
    },

    showDataPopUp() {
      return !this.isLoading && this.searchResults.length;
    }
  },

  methods: {
    ...mapActions(["getGlobalSearchResults"]),

    searchData() {
      this.isLoading = true;
      let params = this.getQueryParams();
      this.getGlobalSearchResults(params)
        .then(({ data }) => {
          this.searchResults = data[GLOBAL_SEARCH_SCOPES.COMPOSITIONS];
        })
        .finally(() => {
          this.isLoading = false;
          this.previousSearch = params.query;
        });
    },

    getQueryParams() {
      return {
        page: 1,
        size: GLOBAL_SEARCH_PAGE_SIZE,
        query: this.globalSearch,
        scopes: [GLOBAL_SEARCH_SCOPES.COMPOSITIONS].join(",")
      };
    },

    onRowClicked(item) {
      this.globalSearch = "";
      this.$router.push({
        name: "CompositionMetadata",
        params: {
          compositionId: item.id
        }
      });
    },

    onClear() {
      return (this.searchResults = []);
    }
  }
};
</script>

<style lang="sass" scoped>

.text-size
  font-size: 0.78rem !important

.no-opacity
  background: rgba(255, 255, 255, 1) !important
  max-width: 35%
  cursor: text !important
</style>
