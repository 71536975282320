import store from "@/store";

export default (to, from, next) => {
  // Make sure we are trying to access an allowed account id
  if (
    to.params.accountId &&
    store.getters.userAvailableAccountIds.includes(to.params.accountId)
  ) {
    next();
  } else {
    console.log(
      `Account (${to.params.accountId}) is not available. Redirecting to 404`
    );

    next({
      name: "404",
      // Using "replace" mode to avoid extra entity in history list (PCMS-136)
      replace: true
    });
  }
};
