<template>
  <validation-observer ref="form">
    <v-form>
      <text-field
        label="Account name"
        v-model="accountName"
        validation-rules="required"
        name="name"
      ></text-field>
      <username-field
        label="User name"
        v-model="userName"
        name="user"
      ></username-field>
      <email-field label="E-mail" v-model="email" mode="lazy"></email-field>
      <text-field
        label="YouTube content owner ID"
        v-model="conventOwnerId"
        name="yt-owner-id"
      ></text-field>
      <v-select label="Type" v-model="type" :items="accountTypes"> </v-select>

      <v-row class="mb-2">
        <v-col class="d-flex align-center">
          <span class="v-label">API access</span>
        </v-col>
        <v-col class="d-flex justify-end pr-0">
          <v-switch
            v-model="settings.api_access_enabled"
            color="primary"
            inset
            :false-value="false"
            :true-value="true"
            class="mt-0"
            hide-details
          ></v-switch>
        </v-col>
      </v-row>

      <v-card-actions class="pa-0">
        <router-link class="text-subtitle-2" to="login"
          >Return to Login
        </router-link>

        <v-spacer></v-spacer>

        <v-btn
          :disabled="processing"
          :loading="processing"
          color="primary"
          type="submit"
          @click.prevent="onSubmit"
        >
          Sign Up
        </v-btn>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from "vuex";

import EmailField from "@/components/forms/inputs/Email";
import UsernameField from "@/components/forms/inputs/Username";
import TextField from "@/components/forms/inputs/TextField";

export default {
  name: "Signup",
  components: {
    EmailField,
    UsernameField,
    TextField
  },
  data: () => ({
    processing: false,
    userName: "",
    email: "",
    accountName: "",
    conventOwnerId: "",
    type: "publishing",
    settings: {
      api_access_enabled: true
    },
    accountTypes: [
      {
        text: "Publishing",
        value: "publishing"
      },
      {
        text: "Sound Recordings Management",
        value: "sound_recordings_management"
      }
    ]
  }),
  methods: {
    ...mapActions(["signup"]),
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.processing = true;
        const data = {
          email: this.email,
          user: this.userName,
          name: this.accountName,
          "yt-owner-id": this.conventOwnerId,
          type: this.type,
          settings: this.settings
        };

        this.signup(data)
          .catch(error => {
            const errors = error?.response?.data?.data;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.processing = false;
          });
      });
    }
  },
  metaInfo: {
    title: "Sign Up"
  }
};
</script>
