export const OWNERSHIP_COVERAGE_TYPES = {
  global: "global",
  include_territories: "include_territories",
  exclude_territories: "exclude_territories"
};

export const OWNERSHIP_LICENSE_TYPES = {
  synchronization: "synchronization",
  performance: "performance",
  mechanical: "mechanical",
  lyric: "lyric"
};

export const OWNERSHIP_LICENSE_TYPES_OPTIONS = [
  {
    title: "Synchronization",
    value: OWNERSHIP_LICENSE_TYPES.synchronization
  },
  {
    title: "Performance",
    value: OWNERSHIP_LICENSE_TYPES.performance
  },
  {
    title: "Mechanical",
    value: OWNERSHIP_LICENSE_TYPES.mechanical
  },
  {
    title: "Lyric",
    value: OWNERSHIP_LICENSE_TYPES.lyric
  }
];

export const OWNERSHIP_LICENSE_TYPES_LABELS = {
  [OWNERSHIP_LICENSE_TYPES.synchronization]: "Synchronization",
  [OWNERSHIP_LICENSE_TYPES.performance]: "Performance",
  [OWNERSHIP_LICENSE_TYPES.mechanical]: "Mechanical",
  [OWNERSHIP_LICENSE_TYPES.lyric]: "Lyric"
};
