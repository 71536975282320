<template>
  <div>
    <v-card
      v-if="items.length === 0 && !globalLoading"
      align="center"
      elevation="0"
    >
      <v-card-text>
        <p class="mt-16 font-weight-medium">
          {{ config.noDataText }}
        </p>
      </v-card-text>
    </v-card>

    <v-card
      v-else-if="config.visible"
      elevation="3"
      :max-width="config.style.maxWidth"
      class="carousel-item mt-2"
    >
      <v-carousel
        v-if="!config.redrawCarousel"
        v-model="model"
        :height="config.style.height"
        hide-delimiters
        :show-arrows="
          config.arrows.back.visible || config.arrows.forward.visible
        "
      >
        <v-carousel-item
          v-for="(recording, i) in items"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
          contentClass="overflow-hidden d-flex"
        >
          <slot
            name="carouselItem"
            :item="recording"
            :classList="'d-flex flex-column col pb-0'"
          />
        </v-carousel-item>

        <template v-slot:prev>
          <div
            v-if="config.multipleRecordsMode.enabled"
            class="total-items grey--text darken-1"
          >
            {{ config.multipleRecordsMode.counterText }}
          </div>
          <v-btn
            :class="config.arrows.back.visible ? 'primary' : ''"
            :disabled="!config.arrows.back.visible"
            icon
            @click="config.arrows.back.action"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>

        <template v-slot:next>
          <v-btn
            :class="config.arrows.forward.visible ? 'primary' : ''"
            :disabled="!config.arrows.forward.visible"
            icon
            @click="config.arrows.forward.action"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>

        <v-btn
          v-if="config.buttons.seeAll.visible"
          :disabled="config.buttons.seeAll.disabled"
          color="primary"
          text
          class="see-all-button"
          @click="config.buttons.seeAll.action"
        >
          {{ config.buttons.seeAll.text }}
        </v-btn>
      </v-carousel>

      <v-card-actions class="mt-0">
        <copy-text
          :value="location.href"
          copied-text="Copied"
          label="Copy link"
          icon="mdi-link-variant"
        ></copy-text>

        <v-spacer v-if="config.actionButtonsVisible"></v-spacer>

        <div v-if="config.actionButtonsVisible">
          <v-btn
            v-if="config.buttons.decline.visible"
            :disabled="config.buttons.decline.disabled"
            color="red darken-1"
            text
            @click="config.buttons.decline.action"
          >
            {{ config.buttons.decline.text }}
          </v-btn>

          <v-btn
            v-if="config.buttons.skip.visible"
            :disabled="config.buttons.skip.disabled"
            color="primary"
            text
            @click="config.buttons.skip.action"
          >
            {{ config.buttons.skip.text }}
          </v-btn>

          <v-btn
            v-if="config.buttons.approve.visible"
            :disabled="config.buttons.approve.disabled"
            color="primary"
            text
            @click="config.buttons.approve.action"
          >
            {{ config.buttons.approve.text }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CopyText from "../CopyText";

export default {
  name: "Carousel",

  components: {
    CopyText
  },

  data: () => ({
    location: location,
    model: null
  }),

  props: {
    items: {
      type: Array,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    currentItemIndex: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapGetters(["globalLoading"])
  },

  watch: {
    currentItemIndex: function(value) {
      this.model = value;
    }
  }
};
</script>

<style lang="sass">
$arrowPosition: absolute
$arrowTop: 15px

.total-items
  position: absolute
  right: 50px
  top: 7px

.see-all-button
  position: absolute
  right: 8px
  top: 10px

.carousel-item div
  .v-window__prev
    right: 45px
    left: calc(100% - 113px)
    position: $arrowPosition
    top: $arrowTop

  .v-window__next
    position: $arrowPosition
    top: $arrowTop
</style>
