<template>
  <div class="carousel-container">
    <approved-recordings-header
      v-if="downloadMetadataProps"
      :refresh-data="initData"
      :download-metadata-props="downloadMetadataProps"
    ></approved-recordings-header>
    <carousel
      :items="items"
      :config="carouselConfig"
      :current-item-index="currentItemIndex"
    >
      <template #carouselItem="{ item, classList }">
        <SoundRecordingOwnershipCandidateCard
          :recording="item"
          :titleMaxWidth="titleMaxWidth"
          :class="classList"
        />
      </template>
    </carousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Carousel from "@/components/carousel/Carousel";
import { CarouselMixin } from "@/mixins/carousel-mixin";
import { PageMenu } from "@/mixins/page-menu";
import { abbreviatedNumber } from "@/filters/numbers";
import ApprovedRecordingsHeader from "@/components/ApprovedRecordingsHeader";
import { CarouselPagingByCursorMixin } from "@/mixins/carousel-paging-by-cursor-mixin";
import SoundRecordingOwnershipCandidateCard from "@/pages/ownershipCandidates/components/SoundRecordingOwnershipCandidateCard";
import { RECORDING_STATUS } from "@/components/enums";

export default {
  name: "SoundRecordingOwnershipCandidates",

  components: {
    SoundRecordingOwnershipCandidateCard,
    Carousel,
    ApprovedRecordingsHeader
  },

  mixins: [CarouselMixin, PageMenu, CarouselPagingByCursorMixin],

  data() {
    return {
      itemRouteParamName: "recordingId",
      downloadMetadataProps: null
    };
  },

  computed: {
    ...mapGetters(["globalLoading", "approvedOwnershipCandidatesCountGetter"]),

    carouselConfig() {
      let config = CarouselPagingByCursorMixin.computed.carouselConfig.call(
        this
      );

      config.noDataText =
        "There are no SR ownership candidates in this group at the moment.";

      return config;
    },

    basicRoute() {
      return `/${this.userCurrentAccountId}/ownership_candidates/${this.$route.meta.status}`;
    },

    singleItemRoute() {
      const recordingId = this.getRequestParams(this.$route.meta.status)
        .recordingId;
      return `${this.basicRoute}/${recordingId}`;
    }
  },

  methods: {
    ...mapActions([
      "updateOwnershipCandidate",
      "getOwnershipCandidates",
      "getSingleOwnershipCandidate",
      "getOwnershipCandidatesTotalCount",
      "getDownloadOwnershipCandidatesMetadataUrl"
    ]),

    updateItem(params) {
      if (!params.recordingId) {
        params.recordingId = this.items[this.currentItemIndex]?.id;
      }

      return this.updateOwnershipCandidate(params);
    },

    getItemsList(params) {
      return this.getOwnershipCandidates(params);
    },

    getItem(params) {
      return this.getSingleOwnershipCandidate(params);
    },

    getTotalCounter(params) {
      return this.getOwnershipCandidatesTotalCount(params);
    },

    async initDownloadMetadataProps() {
      let downloadLimit = "";
      let downloadAllText = "";
      let hideOptions = false;

      switch (this.$route.meta.status) {
        case RECORDING_STATUS.approved:
          downloadLimit = abbreviatedNumber(
            this.approvedOwnershipCandidatesCountGetter ?? 0
          );

          downloadAllText =
            "This will start the download process for all ever approved records. " +
            "Current active approved records will not be hidden. Continue?";
          break;
        case RECORDING_STATUS.pending:
          hideOptions = true;
          downloadAllText =
            "This will start the download process for all pending records. " +
            "Continue?";
          break;
      }

      const formattedDialogText =
        `This will start the download process for the ${downloadLimit} approved record(s). ` +
        "Those records will be hidden from this page after that. " +
        "Continue?";

      const url = await this.getDownloadOwnershipCandidatesMetadataUrl({
        accountId: this.$route.params.accountId,
        status: this.$route.meta.status
      });

      this.downloadMetadataProps = {
        url: url,
        dialogText: formattedDialogText,
        downloadLimit: downloadLimit.toString(),
        downloadAllText,
        basicRoute: this.basicRoute,
        hideOptions
      };
    },

    getPrevPageRequestParams() {
      return {
        cursor: {
          "recording_ownership_candidates.id": this.items[0]["id"],
          "metadata.has_active_references": this.items[0]["candidate"][
            "metadata"
          ]["has_active_references"]
        },
        direction: "DESC"
      };
    },

    getNextPageRequestParams() {
      return {
        cursor: {
          "recording_ownership_candidates.id": this.items[
            this.items.length - 1
          ]["id"],
          "metadata.has_active_references": this.items[this.items.length - 1][
            "candidate"
          ]["metadata"]["has_active_references"]
        },
        direction: "ASC"
      };
    }
  },

  mounted() {
    this.initData();
  },

  metaInfo: {
    title: "SR Ownerships"
  },
  watch: {
    approvedOwnershipCandidatesCountGetter: {
      immediate: true,
      handler: function() {
        this.initDownloadMetadataProps();
      }
    },
    basicRoute: {
      immediate: true,
      handler: function() {
        this.initDownloadMetadataProps();
      }
    }
  }
};
</script>

<style lang="sass" scoped>
@import "~/src/assets/styles/carousel.sass"
</style>
