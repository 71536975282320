import { DATA_TABLE_PER_PAGE_OPTIONS } from "@/components/constants";

export const DataTableFooterProps = {
  data() {
    return {
      tableFooterProps: {
        "items-per-page-options": DATA_TABLE_PER_PAGE_OPTIONS
      }
    };
  }
};
