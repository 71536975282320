<template>
  <span v-html="highlightWords(text)"> </span>
</template>

<script>
import { HighlightedLabelsMixin } from "@/mixins/highlighted-labels-mixin";

export default {
  name: "HighlightedLabel",

  mixins: [HighlightedLabelsMixin],

  data: () => ({
    items: [],
    highlightedParts: []
  }),

  props: {
    text: {
      type: String
    },
    highlightedPart: {
      type: String
    }
  },

  watch: {
    text(value) {
      this.items = [value];
    },

    highlightedPart(value) {
      this.highlightedParts = [value];
    }
  },

  created() {
    this.items = [this.text];
    this.highlightedParts = [this.highlightedPart];
  }
};
</script>
