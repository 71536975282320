<template>
  <validation-observer ref="form">
    <v-container class="my-2 pa-0">
      <p class="text-left font-weight-light text-h6">
        <span v-if="success">Check your mailbox</span>
        <span v-else>That's all right</span>
      </p>
      <p class="text-left font-weight-light text-subtitle-2">
        <span v-if="success"
          >A letter with resetting password instructions was sent to your
          email.</span
        >
        <span v-else
          >Enter the email address associated with your account and we'll send
          you a link to reset your password.</span
        >
      </p>
    </v-container>

    <v-form v-if="!success">
      <email-field label="E-mail" v-model="email" mode="lazy"></email-field>

      <v-card-actions class="pa-0">
        <router-link class="text-subtitle-2" to="login"
          >Return to Login
        </router-link>

        <v-spacer></v-spacer>

        <v-btn
          type="submit"
          :disabled="processing"
          :loading="processing"
          color="primary"
          @click.prevent="onSubmit"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from "vuex";

import EmailField from "@/components/forms/inputs/Email";

export default {
  name: "ForgotPassword",
  components: {
    EmailField
  },
  data: () => ({
    success: false,
    processing: false,
    email: ""
  }),
  methods: {
    ...mapActions(["sendPasswordResetLink"]),
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.processing = true;
        const data = {
          email: this.email
        };

        this.sendPasswordResetLink(data)
          .then(() => {
            this.success = true;
          })
          .catch(error => {
            const errors = error?.response?.data?.data;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.processing = false;
          });
      });
    }
  },
  metaInfo: {
    title: "Forgot Password"
  }
};
</script>
