<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Analytics - Potential Relationships Processing
        </v-card-title>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex" sm="3">
        <v-select
          v-model="selectedMonth"
          :items="dateRangeKeys"
          @change="fetchData"
          clearable
          label="Month"
        ></v-select>
      </v-col>

      <v-col class="d-flex" sm="3">
        <v-autocomplete
          label="Account"
          v-model="selectedAccount"
          :items="accounts"
          @change="fetchData"
          clearable
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-data-table
      selectable
      :headers="headers"
      :items="results"
      :options.sync="options"
      :loading="loading"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:body.append v-if="!loading">
        <tr>
          <td><strong>Total</strong></td>
          <td v-for="(value, index) in totalRow" :key="index">
            {{ value }}
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span class="no-data"></span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { GridUtils } from "@/mixins/grid-utils";
import { SupervisorPageMenu } from "@/mixins/supervisor-page-menu";
import { mapActions } from "vuex";
import { EMPTY_VALUE } from "@/components/constants";

export default {
  name: "PotentialRelationshipsProcessing",
  mixins: [SupervisorPageMenu, GridUtils],

  data: () => ({
    dateRangeMonthsAgo: 6,
    selectedAccount: null,
    selectedMonth: null,
    totalRow: {},
    accounts: [],
    headers: [
      {
        text: "User",
        value: "user",
        width: "25%"
      },
      {
        text: "Approved",
        value: "approved",
        width: "25%"
      },
      {
        text: "Skipped",
        value: "skipped",
        width: "25%"
      },
      {
        text: "Declined",
        value: "declined",
        width: "25%"
      }
    ]
  }),

  computed: {
    dateRange() {
      const range = {};

      for (let i = 0; i < this.dateRangeMonthsAgo; i++) {
        let date = moment().subtract(i, "months");

        range[date.format("MMMM YYYY")] = {
          startDate: date.startOf("month").format("YYYY-MM-DD"),
          endDate: date.endOf("month").format("YYYY-MM-DD")
        };
      }

      return range;
    },

    dateRangeKeys() {
      return Object.keys(this.dateRange);
    }
  },

  methods: {
    ...mapActions(["getPotentialRelationshipsStatistics", "getAccounts"]),

    getQueryParams() {
      return {
        scope: this.selectedAccount,
        start_date: this.dateRange[this.selectedMonth]?.startDate,
        end_date: this.dateRange[this.selectedMonth]?.endDate
      };
    },

    getResultsActionName() {
      return "getPotentialRelationshipsStatistics";
    },

    makeTableRows(data) {
      let rows = [];

      const rowTemplate = {
        user: EMPTY_VALUE,
        approved: EMPTY_VALUE,
        skipped: EMPTY_VALUE,
        declined: EMPTY_VALUE
      };

      this.totalRow = {
        approved: 0,
        skipped: 0,
        declined: 0
      };

      for (const key in data) {
        const values = data[key];
        let row = { ...rowTemplate };

        row.user = key;

        for (const valueKey in values) {
          row[valueKey] = values[valueKey];
          this.totalRow[valueKey] += values[valueKey];
        }

        rows.push(row);
      }

      return rows;
    },

    fetchData() {
      this.loading = true;
      this.results = [];

      let params = this.getQueryParams();
      this[this.getResultsActionName()](params)
        .then(response => {
          this.results = this.makeTableRows(response);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  mounted() {
    this.selectedMonth = this.dateRangeKeys.shift();
    let params = { sort_by: "name", sort_direction: "ASC" };

    this.getAccounts(params)
      .then(({ data }) => {
        this.accounts = data;
      })
      .finally(() => {
        this.fetchData();
      });
  },

  metaInfo: {
    title: "Analytics"
  }
};
</script>

<style lang="sass">
// looks tricky but it works
.v-data-table__empty-wrapper:has(span.no-data)
  display: none
</style>
