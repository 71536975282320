<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="460"
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <template>
        {{ /* had to wrap it with div because direct placing of v-if for the button or menu didn't work */ }}
        <div v-if="hasFetchedReports">
          <v-menu v-on="on" offset-y max-width="270">
            <template v-slot:activator="{ on }">
              <v-btn
                :absolute="absolute"
                :fixed="fixed"
                right
                color="primary"
                :disabled="processing"
                v-bind="attrs"
                v-on="on"
                @click="onGenerateReportMenuActivatorClick"
                v-if="hasFetchedReports"
              >
                <v-progress-circular
                  v-if="loading"
                  size="20"
                  width="2"
                  indeterminate
                  class="mr-1"
                >
                </v-progress-circular>
                <span>{{ menuButtonText }}</span>
              </v-btn>
            </template>
            <v-list v-if="hasProcessedReport">
              <v-list-item link @click="onDownloadReportClick">
                <v-list-item-content>
                  <v-list-item-title>Download Report</v-list-item-title>
                  <v-list-item-subtitle class="text-caption">
                    Generated: {{ generatedReportDate | date }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link @click="onRegenerateReportClick">
                <v-list-item-content>
                  <v-list-item-title>Generate New Report</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-else></div>
      </template>
    </template>
    <v-card>
      <v-radio-group
        hide-details
        column
        @change="downloadType = $event"
        class="pa-5 pb-4 mt-0"
        :value="downloadType"
        v-if="!hideOptions"
      >
        <v-radio value="all" class="mb-1">
          <span slot="label" class="text-body-1">Export all records</span>
        </v-radio>
        <v-radio value="active_records" :disabled="isLimitedDownload">
          <span slot="label" class="text-body-1">{{
            `Export ${downloadLimit} record(s)`
          }}</span>
        </v-radio>
      </v-radio-group>
      <v-card-text class="pb-0 text-body-1" :class="hideOptions ? 'pt-4' : ''">
        {{ dialogMessage }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="onGenerateReportActionButtonClick">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { DOWNLOAD_METADATA_TYPES, REPORT_STATUS } from "@/components/enums";
import { REPORT_PROGRESS_MONITORING_INTERVAL } from "@/components/constants";
import { DownloadWindow } from "@/mixins/download-window";

export default {
  name: "DownloadReport",

  mixins: [DownloadWindow],

  data: () => ({
    loading: false,
    dialog: false,
    downloadTypes: DOWNLOAD_METADATA_TYPES,
    downloadTypeSelected: undefined,
    report: null,
    processing: false,
    fetchInterval: null,
    hasFetchedReports: false
  }),

  props: {
    dialogText: {
      type: String,
      required: true
    },

    downloadAllText: {
      type: String,
      required: true
    },

    downloadLimit: {
      type: String, // The limit can be represented as a string: 10K, 1M etc.
      required: true
    },

    label: {
      type: String,
      default: "Download Metadata Report"
    },

    generateReportLabel: {
      type: String,
      default: "Generate Metadata Report"
    },

    generatingReportLabel: {
      type: String,
      default: "Generating Report"
    },

    absolute: {
      type: Boolean,
      default: true
    },

    fixed: {
      type: Boolean,
      default: true
    },

    basicRoute: {
      type: String,
      required: true
    },

    hideOptions: {
      type: Boolean,
      default: false
    },

    reportType: {
      type: String,
      required: true
    },

    reportParams: {
      type: Object,
      default: null
    }
  },

  methods: {
    ...mapActions(["getReports", "createReport", "getReportDownloadUrl"]),

    onGenerateReportMenuActivatorClick() {
      if (!this.hasProcessedReport) {
        this.dialog = true;
      }
    },

    async onRegenerateReportClick() {
      const result = await this.$confirm(
        "By doing this action, an existing report will be removed.  " +
          "Are you sure you downloaded it before? " +
          "(All items will be still present in the full report)",
        { buttonTrueText: "Continue", buttonFalseText: "Cancel" }
      );

      if (result) {
        this.dialog = true;
      }
    },

    onGenerateReportActionButtonClick() {
      this.dialog = false;
      this.loading = true;
      this.processing = true;

      this.createReport({
        report_type: this.reportType,
        export_type: this.downloadType,
        params: this.reportParams
      }).then(({ data }) => {
        this.report = data;
        this.fetchReportUntilProcessed();
      });
    },

    async onDownloadReportClick() {
      this.dialog = false;
      this.loading = true;

      const downloadUrl = await this.getReportDownloadUrl(this.report.id);

      this.openDownloadWindow(downloadUrl, () => {
        this.loading = false;
      });
    },

    getLatestReportByType(reports) {
      return reports.find(report => report.report_type === this.reportType);
    },

    fetchReport() {
      this.getReports({
        page: 1,
        size: 100
      }).then(({ data }) => {
        this.report = this.getLatestReportByType(data);

        if (this.hasProcessingReport) {
          this.fetchReportUntilProcessed();
        }

        this.hasFetchedReports = true;
      });
    },

    fetchReportUntilProcessed() {
      this.loading = true;
      this.processing = true;

      this.clearExistingFetchInterval();

      this.fetchInterval = setInterval(() => {
        this.fetchReport();

        if (this.hasProcessedReport) {
          this.clearExistingFetchInterval();
          this.loading = false;
          this.processing = false;
          if (this.downloadType === DOWNLOAD_METADATA_TYPES.active_records) {
            this.$router.push(this.basicRoute);
            this.$emit("refresh-data");
          }
        }
      }, REPORT_PROGRESS_MONITORING_INTERVAL);
    },

    clearExistingFetchInterval() {
      if (this.fetchInterval) {
        clearInterval(this.fetchInterval);
      }
    }
  },

  computed: {
    isActiveRecordsDownloadTypeSelected() {
      return this.downloadType === DOWNLOAD_METADATA_TYPES.active_records;
    },

    downloadType: {
      get() {
        if (this.downloadTypeSelected) {
          return this.downloadTypeSelected;
        } else {
          return this.downloadLimit && this.downloadLimit !== "0"
            ? DOWNLOAD_METADATA_TYPES.active_records
            : DOWNLOAD_METADATA_TYPES.all;
        }
      },

      set(val) {
        this.downloadTypeSelected = val;
      }
    },

    hasProcessingReport() {
      return (
        this.report &&
        this.report.processing_status === REPORT_STATUS.processing
      );
    },

    hasProcessedReport() {
      return (
        this.report && this.report.processing_status === REPORT_STATUS.processed
      );
    },

    menuButtonText() {
      if (this.processing) {
        return this.generatingReportLabel;
      } else if (this.hasProcessedReport) {
        return this.label;
      } else {
        return this.generateReportLabel;
      }
    },

    dialogMessage() {
      const appendix =
        " Generating a report can take some time. You can stay on this page or return later. Continue?";
      let msg = this.isActiveRecordsDownloadTypeSelected
        ? this.dialogText
        : this.downloadAllText;

      return msg + appendix;
    },

    isLimitedDownload() {
      return !this.downloadLimit || this.downloadLimit === "0";
    },

    generatedReportDate() {
      return this.report.updated_at;
    }
  },

  beforeMount() {
    this.fetchReport();
  },

  beforeDestroy() {
    this.clearExistingFetchInterval();
  }
};
</script>
