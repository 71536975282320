<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1 justify-space-between">
          <span>
            Composition Conflicts Summary
          </span>
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto mt-1">
        <download-report
          :report-type="downloadReportProps.reportType"
          :download-limit="downloadReportProps.downloadLimit"
          :download-all-text="downloadReportProps.downloadAllText"
          :dialog-text="downloadReportProps.dialogText"
          :hide-options="downloadReportProps.hideOptions"
          :label="downloadReportProps.label"
          :basic-route="downloadReportProps.basicRoute"
          :fixed="downloadReportProps.fixed"
          :generate-report-label="downloadReportProps.generateReportLabel"
        ></download-report>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <v-hover v-slot="{ hover }">
              <td class="text-start clickable" @click="onRowClicked(item)">
                <span :class="'px-0 py-1 ' + [hover ? 'primary--text' : '']">
                  {{ item.title | default_value }}
                </span>
              </td>
            </v-hover>
            <td class="text-start table-secondary-column">
              <span>{{ item.youtube_asset_id | default_value }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{ item.custom_id | default_value }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{
                (item.writers ? item.writers.join(", ") : "") | default_value
              }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{
                (item.labels ? item.labels.join(", ") : "") | default_value
              }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{ item.conflicts_summary.conflicting_views }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{ item.conflicts_summary.conflicting_recordings }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import DownloadReport from "@/components/buttons/DownloadReport.vue";
import { OWNERSHIP_CONFLICTS_STATUS, REPORT_TYPES } from "@/components/enums";
import { mapActions } from "vuex";
import { DATA_TABLE_PER_PAGE_OPTIONS } from "@/components/constants";

export default {
  name: "CompositionConflictsSummary",

  components: { DownloadReport },

  mixins: [PageMenu, GridUtils, DataTableFooterProps],

  data: () => ({
    persistentStateKey: "ownership-conflicts-compositions",
    assetConflictsHistoryPersistentStateKey: "ownership-conflicts-history",
    headers: [
      {
        text: "Title",
        sortable: false,
        width: "22%"
      },
      {
        text: "Youtube Asset ID",
        sortable: false,
        align: "start"
      },
      {
        text: "Custom ID",
        sortable: false,
        width: "10%"
      },
      {
        text: "Writers",
        sortable: false,
        width: "21%"
      },
      {
        text: "Labels",
        sortable: false,
        width: "18%"
      },
      {
        text: "Views In Conflict",
        sortable: true,
        value: "conflicts.conflicting_views",
        align: "start"
      },
      {
        text: "SRs In Conflict",
        sortable: true,
        value: "conflicts.conflicting_recordings",
        align: "start"
      }
    ],
    downloadReportProps: {
      reportType: REPORT_TYPES.OwnershipCompositionConflictsHistory,
      dialogText: "",
      downloadLimit: "",
      downloadAllText: "",
      basicRoute: "",
      fixed: false,
      hideOptions: true,
      label: "Download report",
      generateReportLabel: "Generate report"
    }
  }),

  methods: {
    ...mapActions(["getCompositionsConflictsSummary"]),

    getResultsActionName() {
      return "getCompositionsConflictsSummary";
    },

    onRowClicked(item) {
      this.setPersistentData({
        key: this.assetConflictsHistoryPersistentStateKey,
        value: {
          page: 1,
          itemsPerPage: Array.from(DATA_TABLE_PER_PAGE_OPTIONS).shift(),
          sortBy: ["conflicts.conflicting_views"],
          sortDesc: [true],
          filter: {
            youtubeAssetId: item.youtube_asset_id,
            status: OWNERSHIP_CONFLICTS_STATUS.in_conflict
          }
        }
      });

      this.$router.push({
        name: "AssetsConflictsHistory"
      });
    }
  },

  metaInfo: {
    title: "Composition Conflicts Summary"
  }
};
</script>

<style scoped lang="sass"></style>
