import { request } from "@/services/api";
import { prepareRequestParamsForCursorPaging } from "@/store/helpers";
import { PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA } from "@/components/enums";

const getDefaultState = () => {
  return {
    loading: false,
    approvedRelationships: { count: null },
    pendingRelationships: { count: null },
    skippedRelationships: { count: null },
    declinedRelationships: { count: null }
  };
};

const state = getDefaultState();

export default {
  name: "sound_recordings",
  state,
  actions: {
    getRecordings({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${params.compositionId}/recordings?page=${params.page}&size=${params.size}&query=${params.query}`
      );
    },

    getAccountRecordings({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/recordings/list?page=${params.page}&size=${params.size}&query=${params.query}`
      );
    },

    getDownloadSoundRecordingsUrl({ rootState }, { account_id, query }) {
      return request.generateFullUrl(
        `${rootState.apiPrefix}/accounts/${account_id}/recordings/report?query=${query}`
      );
    },

    deleteRecording({ rootState }, { account_id, recording_id }) {
      return request.delete(
        `${rootState.apiPrefix}/accounts/${account_id}/recordings/${recording_id}`
      );
    },

    updateGlobalRecordingsCounts({ getters, rootState, commit }) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(`${rootState.apiPrefix}/accounts/${accountId}/recordings/counters`)
        .then(({ approved, pending, skipped, declined }) => {
          commit("setGlobalApprovedRelationshipsCount", approved);
          commit("setGlobalPendingRelationshipsCount", pending);
          commit("setGlobalSkippedRelationshipsCount", skipped);
          commit("setGlobalDeclinedRelationshipsCount", declined);
        });
    },

    getGlobalPotentialRelationships({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      // Remove status from params to prevent add status to query string
      let status = params.status;
      delete params.status;

      params = prepareRequestParamsForCursorPaging(
        params,
        PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA.GLOBAL_POTENTIAL_RELATIONSHIPS
      );

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings/${status}`,
          { params }
        )
        .then(response => {
          return response;
        });
    },

    getSingleGlobalPotentialRelationship({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings/${params.status}/${params.recordingId}`
        )
        .then(response => {
          return response;
        });
    },

    getGlobalPotentialRelationshipsTotalCount({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings/${params.status}/total-count`
        )
        .then(response => {
          return response;
        });
    }
  },

  getters: {
    globalApprovedRelationshipsCountGetter(state) {
      return state.approvedRelationships.count;
    },

    globalPendingRelationshipsCountGetter(state) {
      return state.pendingRelationships.count;
    },

    globalSkippedRelationshipsCountGetter(state) {
      return state.skippedRelationships.count;
    },

    globalDeclinedRelationshipsCountGetter(state) {
      return state.declinedRelationships.count;
    }
  },

  mutations: {
    setGlobalApprovedRelationshipsCount(state, value) {
      state.approvedRelationships.count = value || 0;
    },

    setGlobalPendingRelationshipsCount(state, value) {
      state.pendingRelationships.count = value || 0;
    },

    setGlobalSkippedRelationshipsCount(state, value) {
      state.skippedRelationships.count = value || 0;
    },

    setGlobalDeclinedRelationshipsCount(state, value) {
      state.declinedRelationships.count = value || 0;
    }
  }
};
