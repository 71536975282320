import { PageMenu } from "@/mixins/page-menu";
import { ACCOUNT_TYPE } from "@/components/enums";

export const SettingsPageMenu = Object.assign({}, PageMenu, {
  computed: {
    getMenuItems: function() {
      return [
        {
          title: "General",
          icon: "mdi-cog",
          routerName: "AccountManagement",
          visible: true
        },
        {
          title: "Users",
          icon: "mdi-account-cog",
          routerName: "UsersManagement",
          visible: true
        },
        {
          title: "Asset Labels",
          icon: "mdi-folder",
          routerName: "Labels",
          visible: true,
          accountTypes: [ACCOUNT_TYPE.publishing]
        }
      ];
    },

    hasBackButton: function() {
      return true;
    },

    getPageDetailsSectionComponent: function() {
      return null;
    }
  }
});
