<template>
  <div class="text-center">
    <v-menu
      v-model="isMenuVisible"
      :close-on-content-click="false"
      :nudge-width="150"
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-capitalize text-subtitle-2"
          color="gray"
          outlined
          v-bind="attrs"
          v-on="on"
          @click="refreshAccountsListAndMoveToInitialStep"
          text
          height="40"
        >
          {{ currentAccount.name }}
        </v-btn>
      </template>

      <v-card>
        <v-window v-model="step">
          <v-window-item :value="USER_MENU_STEPS.initial">
            <v-list dense>
              <v-list-item>
                <v-list-item-icon>
                  <account-type-icon :account="currentAccount" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ currentAccount.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ userName }} - {{ ROLES[currentAccount.role] }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list class="user-menu-items" dense>
              <v-list-item-group color="primary">
                <user-profile>
                  <template v-slot:activator="{ openUserProfile }">
                    <v-list-item @click="hideMenu(openUserProfile)">
                      <v-list-item-icon>
                        <v-icon>mdi-account-box</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                  </template>
                </user-profile>

                <v-list-item
                  @click.stop="moveToSwitchingAccountStep"
                  :disabled="refreshingAccounts"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-box-multiple</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Switch account</v-list-item-title>
                  <v-list-item-action>
                    <v-progress-circular
                      v-if="refreshingAccounts"
                      indeterminate
                      color="grey lighten-2"
                      size="20"
                      width="2"
                    ></v-progress-circular>
                    <v-btn
                      v-else
                      icon
                      x-small
                      @click.stop="moveToSwitchingAccountStep"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item @click.stop="signOut">
                  <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Sign out</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-window-item>

          <v-window-item :value="USER_MENU_STEPS.switching_account">
            <v-list dense>
              <v-list-item>
                <v-list-item-action class="my-1">
                  <v-btn icon @click.stop="moveToInitialStep">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-title>Accounts</v-list-item-title>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list dense max-height="700" class="overflow-y-auto">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="account in accounts"
                  :key="account.id"
                  @click.stop="switchCurrentAccount(account.id)"
                >
                  <v-list-item-icon>
                    <account-type-icon
                      :account="account"
                      :color="
                        account.id === currentAccount.id ? 'primary' : 'default'
                      "
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ account.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ ROLES[account.role] }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="account.id === currentAccount.id">
                    <v-icon color="primary"> mdi-check </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-window-item>
        </v-window>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import UserProfile from "@/layouts/components/menus/UserProfile";
import { ROLES_WITH_TITLES, USER_MENU_STEPS } from "@/components/enums";
import { mapActions, mapGetters } from "vuex";
import { AccountMixin } from "@/mixins/account";
import AccountTypeIcon from "@/layouts/components/AccountTypeIcon";

export default {
  name: "UserMenu",
  components: { AccountTypeIcon, UserProfile },
  props: ["currentAccount"],
  mixins: [AccountMixin],
  data: () => ({
    USER_MENU_STEPS,
    ROLES: ROLES_WITH_TITLES,

    userName: null,
    userEmail: null,
    accounts: null,
    isMenuVisible: false,
    refreshingAccounts: false,
    step: USER_MENU_STEPS.initial
  }),

  methods: {
    ...mapGetters(["userData", "userAvailableAccounts"]),
    ...mapActions(["logout", "switchCurrentAccountId", "getUser"]),

    initMenu() {
      const userData = this.userData();

      if (userData) {
        this.userName = userData.name;
        this.userEmail = userData.email;
        this.accounts = this.userAvailableAccounts();
      }
    },

    hideMenu(callback) {
      this.isMenuVisible = false;
      callback && callback();
    },

    moveToSwitchingAccountStep() {
      this.step = USER_MENU_STEPS.switching_account;
    },

    refreshAccountsListAndMoveToInitialStep() {
      this.refreshingAccounts = true;
      this.getUser().then(() => {
        this.moveToInitialStep();
        this.refreshingAccounts = false;
      });
    },

    moveToInitialStep() {
      this.initMenu();
      this.step = USER_MENU_STEPS.initial;
    },

    signOut() {
      this.logout();
    },

    switchCurrentAccount(accountId) {
      this.hideMenu();
      this.switchCurrentAccountId(accountId);
    }
  },

  mounted() {
    this.initMenu();
  }
};
</script>

<style lang="sass" scoped>
.user-menu-items .v-list-item__action:first-child,
.user-menu-items .v-list-item__icon:first-child
  margin-right: 16px
</style>
