<template>
  <confirm-password-form @clicked="onSubmit" :processing="processing">
    <template v-slot:title>
      <v-container class="my-2 pa-0">
        <p class="text-left font-weight-light text-h6">
          Welcome
        </p>
        <p class="text-left font-weight-light text-subtitle-2">
          Please set your new password before proceeding:
        </p>
      </v-container>
    </template>
  </confirm-password-form>
</template>

<script>
import { mapActions } from "vuex";

import ConfirmPasswordForm from "@/components/forms/ConfirmPasswordForm";

export default {
  name: "Onboarding",
  components: {
    ConfirmPasswordForm
  },
  data: () => ({
    processing: false
  }),
  methods: {
    ...mapActions(["onboardingSetPassword"]),
    onSubmit(values) {
      this.processing = true;
      this.onboardingSetPassword({
        code: this.$store.getters.invitationCode,
        password: values.password,
        password_confirmation: values.passwordConfirm
      }).finally(() => {
        this.processing = false;
      });
    }
  },
  metaInfo: {
    title: "Onboarding"
  }
};
</script>
