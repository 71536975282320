import { mapGetters, mapActions } from "vuex";
import { deepCopy } from "@/components/helpers";

export const SettingsMixin = {
  props: {
    account: {
      type: Object,
      required: false
    },
    processing: {
      type: Function
    },
    label: {
      type: String,
      required: false
    },
    settingName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    settings: {},
    loading: false
  }),

  methods: {
    ...mapActions(["setCurrentAccountSettings"]),

    updateSettings(val) {
      this.loading = true;
      this.$set(this.settings, this.settingName, val);
      const data = {
        payload: { settings: this.settings },
        accountId: this.account.id
      };
      this.processing(data)
        .then(responseBody => {
          if (responseBody) {
            this.settings = responseBody?.data?.settings;
          }
        })
        .catch(() => {
          this.$set(this.settings, this.settingName, false);
          this.onSettingsUpdateError();
        })
        .finally(() => {
          this.loading = false;
          if (this.account.id === this.userCurrentAccountId) {
            this.setCurrentAccountSettings(this.settings);
          }
        });
    },

    onSettingsUpdateError() {
      throw new Error("Must be implemented in a subclass");
    }
  },

  computed: {
    ...mapGetters(["userCurrentAccountId"])
  },

  created() {
    this.settings =
      typeof this.account?.settings === "object"
        ? deepCopy(this.account?.settings)
        : {};
  }
};
