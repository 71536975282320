<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Content Import
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center">
        <import-wizard
          :import-type="importType"
          :options="{ allowOverride: false }"
        />
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <p class="xs2">
          The current mechanism provides the ability to import structured sound
          recording data into the current account.
          <br />
          The imported CSV file should be compressed to ZIP-archive. Row with
          column names must be present in file. Expected columns (<span
            style="font-weight: bold"
            >case-sensitive</span
          >):
        </p>
        <ul>
          <li>asset_id - expected column name</li>
        </ul>
        <p class="mt-3">
          Imported file can include other columns.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ImportWizard from "@/components/import/ImportWizard";
import { PageMenu } from "@/mixins/page-menu";
import { IMPORT_TYPES } from "@/components/enums";

export default {
  name: "SoundRecordingsImport",

  mixins: [PageMenu],

  components: { ImportWizard },

  data: () => ({
    importType: IMPORT_TYPES.recordings_ownership_candidates
  }),

  metaInfo: {
    title: "Import"
  }
};
</script>
