import { mapActions, mapGetters } from "vuex";
import { RECORDING_STATUS } from "@/components/enums.js";

export const CarouselMixin = {
  data: () => ({
    itemsPerPage: 0,
    totalItems: 0,
    lastPage: 1,
    currentPage: 1,
    items: [],
    currentItemIndex: 0,
    multipleItemsMode: false,
    disabledButton: false,
    itemRouteParamName: null,
    preloadThreshold: 5,
    preloadedItems: {
      previous: [],
      next: []
    }
  }),

  watch: {
    $route(to, from) {
      if (to.name === from.name && to.path !== this.basicRoute) {
        return;
      } else {
        this.initData();
      }
    }
  },

  computed: {
    ...mapGetters(["globalLoading", "userCurrentAccountId"]),

    currentItem() {
      return (
        this.currentItemIndex + 1 + (this.currentPage - 1) * this.itemsPerPage
      );
    },

    showForwardArrow() {
      return this.multipleItemsMode && this.currentItem !== this.totalItems;
    },

    showBackArrow() {
      return (
        this.multipleItemsMode &&
        !(this.currentPage === 1 && this.currentItemIndex === 0)
      );
    },

    showDeclineButton() {
      return this.$route.meta.status !== RECORDING_STATUS.declined;
    },

    showSkipButton() {
      return this.$route.meta.status !== RECORDING_STATUS.skipped;
    },

    showSeeAllButton() {
      return !this.multipleItemsMode;
    },

    showActionButtons() {
      return [
        RECORDING_STATUS.pending,
        RECORDING_STATUS.skipped,
        RECORDING_STATUS.declined
      ].includes(this.$route.meta.status);
    },

    actionButtonsDisabled() {
      return this.disabledButton;
    },

    carouselConfig() {
      return {
        visible: this.items.length > 0 && !this.globalLoading,
        style: {
          height: 400,
          maxWidth: 700
        },
        noDataText:
          "There are no potential related sound items in this group at the moment.",
        multipleRecordsMode: {
          enabled: this.multipleItemsMode,
          counterText: `${this.currentItem}/${this.totalItems}`
        },
        arrows: {
          forward: {
            visible: this.showForwardArrow,
            action: this.scrollItemsForward
          },
          back: {
            visible: this.showBackArrow,
            action: this.scrollItemsBack
          }
        },
        actionButtonsVisible: this.showActionButtons,
        buttons: {
          approve: {
            visible: true,
            action: this.approveItem,
            disabled: this.actionButtonsDisabled,
            text: "Approve"
          },
          skip: {
            visible: this.showSkipButton,
            action: this.skipItem,
            disabled: this.actionButtonsDisabled,
            text: "Skip"
          },
          decline: {
            visible: this.showDeclineButton,
            action: this.declineItem,
            disabled: this.actionButtonsDisabled,
            text: "Decline"
          },
          seeAll: {
            visible: this.showSeeAllButton,
            action: this.showItemsList,
            disabled: false,
            text: "See All"
          }
        }
      };
    },

    basicRoute() {
      throw new Error("Not implemented");
    },

    singleItemRoute() {
      throw new Error("Not implemented");
    },

    isLastPage() {
      return this.currentPage === this.lastPage;
    }
  },

  methods: {
    ...mapActions(["setGlobalLoading"]),

    // eslint-disable-next-line no-unused-vars
    updateItem(params) {
      throw new Error("Not implemented");
    },

    // eslint-disable-next-line no-unused-vars
    getItemsList(params) {
      throw new Error("Not implemented");
    },

    // eslint-disable-next-line no-unused-vars
    getItem(params) {
      throw new Error("Not implemented");
    },

    getRequestParams(status = this.$route.meta.status) {
      let params = {
        page: this.currentPage,
        status: status
      };

      params[this.itemRouteParamName] = this.items[this.currentItemIndex]?.id;

      return params;
    },

    approveItem() {
      this.applyUpdateItem(RECORDING_STATUS.approved);
    },

    declineItem() {
      this.applyUpdateItem(RECORDING_STATUS.declined);
    },

    skipItem() {
      this.applyUpdateItem(RECORDING_STATUS.skipped);
    },

    incrementCurrentPage() {
      this.isLastPage ? (this.currentPage = this.lastPage) : this.currentPage++;
    },

    decrementCurrentPage() {
      this.currentPage < 2 ? (this.currentPage = 1) : this.currentPage--;
    },

    shiftItemsBack() {
      this.preloadedItems.previous = this.items;
      this.items = this.preloadedItems.next;
      this.currentItemIndex = 0;

      this.incrementCurrentPage();
    },

    shiftItemsForward() {
      this.preloadedItems.next = this.items;
      this.items = this.preloadedItems.previous;
      this.currentItemIndex =
        this.items.length < this.itemsPerPage
          ? this.itemsPerPage - 1
          : this.items.length - 1;

      this.decrementCurrentPage();
    },

    scrollItemsForward() {
      if (this.currentItemIndex === this.items.length - this.preloadThreshold) {
        this.currentItemIndex++;
        // If it's a last page - we cannot decrement page number because it will break pagination
        // We can preload next page items only if it's not a last page
        if (this.isLastPage) {
          this.preloadedItems.next = [];
        } else {
          // Incrementing current page has check for last page, but decrement current page works every/each time.
          this.incrementCurrentPage();

          this.fetchData().then(response => {
            this.preloadedItems.next = response.data || [];
          });

          this.decrementCurrentPage();
        }
      } else if (this.currentItemIndex >= this.items.length - 1) {
        this.shiftItemsBack();
      } else {
        this.currentItemIndex++;

        this.changePageUriToSingleItem();
      }
    },

    scrollItemsBack() {
      if (
        this.currentItemIndex === this.preloadThreshold &&
        this.currentPage !== 1
      ) {
        this.currentItemIndex--;
        this.decrementCurrentPage();

        this.fetchData().then(response => {
          this.preloadedItems.previous = response.data || [];
        });

        this.incrementCurrentPage();
      } else if (this.currentItemIndex === 0) {
        this.shiftItemsForward();
      } else {
        this.currentItemIndex--;

        this.changePageUriToSingleItem();
      }
    },

    changePageUriToSingleItem() {
      let params = this.getRequestParams();
      let accountId = this.$route.params.accountId;

      if (Object.values(params).every(x => x !== undefined) && accountId) {
        // We can't push router to the current uri
        if (this.$route.path !== this.singleItemRoute) {
          this.$router.push(this.singleItemRoute);
        }
      } else {
        console.info("Cannot redirect to single item page.");
      }
    },

    changePageUriToItemsList() {
      let params = this.getRequestParams();
      let accountId = this.$route.params.accountId;

      if (params && accountId) {
        this.$router.push(this.basicRoute);
      } else {
        console.info("Cannot redirect to current item's group page.");
      }
    },

    showItemsList() {
      /*
      Direct router push to the page
      does not work due to https://iscanning.atlassian.net/browse/PCMS-173
      */
      this.setGlobalLoading(true);
      let params = this.getRequestParams();
      this.getItems(params)
        .then(response => {
          this.items = response.data;
        })
        .finally(() => {
          this.setGlobalLoading(false);
          // Redirecting to a specific record's URL to provide a way to share a link to an item

          // This code added here because same code in getItems() executed before set this.items
          if (this.items.length) {
            this.changePageUriToSingleItem();
          }
        });
    },

    applyUpdateItem(status) {
      this.setGlobalLoading(true);
      this.disabledButton = true;

      let params = this.getRequestParams(status);

      this.updateItem(params)
        .then(() => {
          this.setGlobalLoading(false);

          // Items state was changed, and it should be removed from this group
          this.items = this.items.filter(
            item => item.id !== params[this.itemRouteParamName]
          );
          this.totalItems--;

          if (!this.items.length) {
            if (this.currentPage === 1 && this.lastPage === 1) {
              this.changePageUriToItemsList();
              return;
            }
            if (this.currentPage < this.lastPage) {
              this.scrollItemsForward();
            }
            if (this.isLastPage) {
              this.scrollItemsBack();
              this.lastPage--;
            }
          }
          // We can't change item index after we already did this above.  So we need "else" to prevent double paging processing.
          else if (this.items.length >= 1) {
            if (this.currentItemIndex >= this.items.length - 1) {
              if (this.currentPage < this.lastPage) {
                this.scrollItemsForward();
              } else if (this.currentItemIndex > 0) {
                this.currentItemIndex--;
              }
            }

            this.changePageUriToSingleItem();
          }
        })
        .finally(() => {
          this.disabledButton = false;
        });
    },

    processResponseData(response) {
      if (response.data) {
        if (Array.isArray(response.data)) {
          this.multipleItemsMode = true;
          this.itemsPerPage = response.meta.per_page;
          this.totalItems = response.meta.total;
        } else {
          this.multipleItemsMode = false;
        }
      }
    },

    initData() {
      if (!this.$route.params?.[this.itemRouteParamName]) {
        this.multipleItemsMode = true;
      }

      this.currentItemIndex = 0;
      this.currentPage = this.lastPage = 1;

      this.setGlobalLoading(true);

      return this.fetchData()
        .then(response => {
          this.items = Array.isArray(response.data)
            ? response.data
            : [response.data];
          // hotfix: sometimes when clicking the 'next' button too fast it shows the no data message without this
          this.preloadedItems.next = this.items;
        })
        .finally(() => this.setGlobalLoading(false));
    },

    fetchData() {
      let params = this.getRequestParams();

      if (!this.multipleItemsMode) {
        params[this.itemRouteParamName] = this.$route.params[
          this.itemRouteParamName
        ];

        return this.getItem(params)
          .then(data => {
            this.processResponseData(data);
            return data;
          })
          .catch(error => {
            if (error.response.status === 404) {
              return this.getItems(params);
            }
          });
      }

      return this.getItems(params);
    },

    getItems(params) {
      return this.getItemsList(params).then(response => {
        this.lastPage = response?.meta?.last_page || 1;
        this.processResponseData(response);
        return response;
      });
    },

    onKeyPress(event) {
      switch (event.key) {
        case "ArrowLeft":
          this.carouselConfig?.arrows?.back?.action && this.showBackArrow
            ? this.carouselConfig.arrows.back.action()
            : null;
          break;
        case "ArrowRight":
          this.carouselConfig?.arrows?.forward?.action && this.showForwardArrow
            ? this.carouselConfig.arrows.forward.action()
            : null;
          break;
      }
    },

    addKeyboardEventsListener() {
      document.addEventListener("keyup", this.onKeyPress);
    },

    removeKeyboardEventsListener() {
      document.removeEventListener("keyup", this.onKeyPress);
    }
  },

  beforeMount() {
    this.addKeyboardEventsListener();
  },

  beforeDestroy() {
    this.removeKeyboardEventsListener();
  }
};
