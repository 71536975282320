<template v-if="hasErrors">
  <div class="errors pt-6">
    <p class="errors-hint">
      We found some validation issues, please correct them and upload the file
      again:
    </p>
    <v-list
      class="errors-messages overflow-y-auto"
      v-if="isLineValidationErrors"
    >
      <ul>
        <template v-for="(messages, field, index) in errors.data">
          <li :key="index">{{ field }}</li>
          <v-list-item
            v-for="(message, index) in messages"
            :key="field + index"
          >
            <v-list-item-content>
              <v-list-item-title> - {{ message }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </ul>
    </v-list>
    <v-list class="errors-messages" v-else>
      <template v-for="messages in errors.data">
        <v-list-item v-for="(message, index) in messages" :key="index">
          <v-list-item-content>
            <v-list-item-title> {{ message }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import { IMPORT_ERRORS_TYPES } from "@/components/enums";

export default {
  name: "ValidationErrors",

  props: {
    errors: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasErrors() {
      return this.errors?.data;
    },

    isLineValidationErrors() {
      return this.errors.type === IMPORT_ERRORS_TYPES.line_validation;
    }
  }
};
</script>

<style scoped lang="sass">
.errors
  padding: 0 12px
  width: 850px

  .errors-hint
    margin-bottom: 5px

  .v-list.errors-messages
    padding: 0
    max-height: 150px

    .v-list-item
      min-height: 5px
      padding: 0

      .v-list-item__content
        padding: 0

        .v-list-item__title
          font-size: 14px
          padding-left: 10px
          padding-bottom: 5px
</style>
