import { request } from "@/services/api";
import {
  filterListParams,
  prepareRequestParamsForCursorPaging
} from "@/store/helpers";
import { PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA } from "@/components/enums";

const getDefaultState = () => {
  return {
    currentComposition: null,
    approvedRelationships: { count: null },
    pendingRelationships: { count: null },
    skippedRelationships: { count: null },
    declinedRelationships: { count: null }
  };
};

const state = getDefaultState();

export default {
  name: "compositions",
  state,
  actions: {
    initCurrentComposition({ getters, commit }, compositionId) {
      if (
        !getters.currentComposition ||
        getters.currentComposition.id !== compositionId
      ) {
        this.dispatch("resetCurrentComposition");
        return this.dispatch("getComposition", compositionId).then(
          responseBody => {
            if (responseBody?.data) {
              const compositionData = responseBody?.data;
              commit("setCurrentComposition", compositionData);
              this.dispatch("updateRecordingsCounts", compositionId);
            }

            let composition = responseBody?.data;
            if (!composition.meta) {
              composition.meta = {};
            }

            return composition;
          }
        );
      } else {
        return Promise.resolve(getters.currentComposition);
      }
    },

    updateRecordingsCounts({ getters, rootState, commit }, compositionId) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/compositions/${compositionId}/recordings/counters`
        )
        .then(({ approved, pending, skipped, declined }) => {
          commit("setApprovedRelationshipsCount", approved);
          commit("setPendingRelationshipsCount", pending);
          commit("setSkippedRelationshipsCount", skipped);
          commit("setDeclinedRelationshipsCount", declined);
        });
    },

    getComposition({ getters, rootState }, compositionId) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${compositionId}`
      );
    },

    createCurrentCompositionMeta({ getters, commit }, data) {
      const compositionId = getters.currentComposition.id;

      return this.dispatch("createCompositionMeta", {
        compositionId,
        ...data
      }).then(responseBody => {
        if (responseBody?.data) {
          const compositionMetaData = responseBody?.data;
          commit("setCurrentCompositionMeta", compositionMetaData);
        }

        return responseBody;
      });
    },

    createCompositionMeta({ getters, rootState }, data) {
      const accountId = getters.userCurrentAccountId;

      return request.post(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${data.compositionId}/metadata`,
        data
      );
    },

    getCompositions({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      const validParams = filterListParams(params);

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions?`,
        { params: validParams }
      );
    },

    getCurrentCompositionOwnership({ getters, rootState }) {
      const accountId = getters.userCurrentAccountId,
        compositionId = getters.currentComposition?.id;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${compositionId}/ownership`
      );
    },

    createCompositionOwnership({ getters, rootState }, data) {
      const accountId = getters.userCurrentAccountId;

      return request.post(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${data.compositionId}/ownership`,
        {
          shares: data.shares
        }
      );
    },

    updateCompositionOwnership({ getters, rootState }, data) {
      const accountId = getters.userCurrentAccountId;

      return request.put(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${data.compositionId}/ownership/${data.ownershipId}`,
        {
          shares: data.shares
        }
      );
    },

    deleteCompositionOwnership({ getters, rootState }, data) {
      const accountId = getters.userCurrentAccountId;

      return request.delete(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${data.compositionId}/ownership/${data.ownershipId}`,
        {
          shares_ids: data.shares_ids
        }
      );
    },

    getPotentialRelationships({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      // Remove status from params to prevent add status to query string
      let status = params.status;
      delete params.status;

      params = prepareRequestParamsForCursorPaging(
        params,
        PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA.POTENTIAL_RELATIONSHIPS_LIST
      );

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/compositions/${params.compositionId}/${status}`,
          { params }
        )
        .then(response => {
          this.dispatch("updateRecordingsCounts", params.compositionId);
          return response;
        });
    },

    getPotentialRelationshipsTotalCount({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/compositions/${params.compositionId}/${params.status}/total-count`
        )
        .then(response => {
          return response;
        });
    },

    getSinglePotentialRelationship({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/compositions/${params.compositionId}/${params.status}/${params.recordingId}`
        )
        .then(response => {
          this.dispatch("updateRecordingsCounts", params.compositionId);
          return response;
        });
    },

    updatePotentialRelationship({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .put(
          `${rootState.apiPrefix}/accounts/${accountId}/compositions/${params.compositionId}/${params.status}/${params.recordingId}`
        )
        .then(response => {
          this.dispatch("updateRecordingsCounts", params.compositionId);
          this.dispatch("updateGlobalRecordingsCounts");
          return response;
        });
    },

    getCountriesList({ rootState }) {
      return request.get(`${rootState.apiPrefix}/countries`);
    },

    resetCurrentComposition({ commit }) {
      commit("setCurrentComposition", null);
    },

    getDownloadCompositionsUrl({ rootState }, { account_id, query }) {
      return request.generateFullUrl(
        `${rootState.apiPrefix}/accounts/${account_id}/compositions/report?query=${query}`
      );
    },

    removeComposition({ rootState, getters }, compositionId) {
      const accountId = getters.userCurrentAccountId;
      return request.delete(
        `${rootState.apiPrefix}/accounts/${accountId}/compositions/${compositionId}`
      );
    }
  },

  getters: {
    currentComposition(state) {
      return state.currentComposition;
    },

    approvedRelationshipsCountGetter(state) {
      return state.approvedRelationships.count;
    },

    pendingRelationshipsCountGetter(state) {
      return state.pendingRelationships.count;
    },

    skippedRelationshipsCountGetter(state) {
      return state.skippedRelationships.count;
    },

    declinedRelationshipsCountGetter(state) {
      return state.declinedRelationships.count;
    }
  },

  mutations: {
    setCurrentComposition(state, composition) {
      state.currentComposition = composition;
    },

    setCurrentCompositionMeta(state, compositionMeta) {
      state.currentComposition.meta = compositionMeta;
    },

    setApprovedRelationshipsCount(state, value) {
      state.approvedRelationships.count = value || 0;
    },

    setPendingRelationshipsCount(state, value) {
      state.pendingRelationships.count = value || 0;
    },

    setSkippedRelationshipsCount(state, value) {
      state.skippedRelationships.count = value || 0;
    },

    setDeclinedRelationshipsCount(state, value) {
      state.declinedRelationships.count = value || 0;
    }
  }
};
