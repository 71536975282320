export function filterListParams(params) {
  // Add validation to prevent send unused params to API
  const validParamKeys = [
    "page",
    "size",
    "sort_by",
    "sort_direction",
    "query",
    "scope",
    "scopes"
  ];
  const validParams = {};
  validParamKeys.forEach(key => {
    if (params[key]) validParams[key] = params[key];
  });
  return validParams;
}

export function prepareRequestParamsForCursorPaging(params, key) {
  if (params.cursor && params.direction) {
    let obj = { _pointsToNextItems: params.direction === "ASC" };
    if (params.cursor instanceof Object) {
      // In case cursor contain more than one field
      Object.assign(obj, params.cursor);
    } else {
      obj[key] = params.cursor;
    }
    params.cursor = btoa(JSON.stringify(obj));
    // Remove direction param to prevent add direction to query string
    delete params.direction;
  }

  return params;
}
