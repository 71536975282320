var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card-title',{staticClass:"pl-1 justify-space-between"},[_vm._v(" Analytics "),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-menu',{attrs:{"open-on-hover":"","close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!hover)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1):_vm._e()]}}],null,true)},[_c('metrics-info')],1)]}}])})],1)],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.metrics,"return-object":"","item-text":"label","item-value":"key","label":"Metric","multiple":"","chips":"","small-chips":"","deletable-chips":!_vm.isOnlySelectedMetric},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){return _vm.onMetricSelect(item)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":item,"disabled":_vm.isOnlySelectedMetric},on:{"change":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onMetricSelect(item)}},model:{value:(_vm.selectedMetrics),callback:function ($$v) {_vm.selectedMetrics=$$v},expression:"selectedMetrics"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)],1)]}}]),model:{value:(_vm.selectedMetrics),callback:function ($$v) {_vm.selectedMetrics=$$v},expression:"selectedMetrics"}})],1),(_vm.hasSupervisorAccess)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.accounts,"return-object":"","item-text":"name","item-value":"id","label":"Account","multiple":"","chips":"","small-chips":"","deletable-chips":!_vm.isOnlySelectedAccount},model:{value:(_vm.filter.owner),callback:function ($$v) {_vm.$set(_vm.filter, "owner", $$v)},expression:"filter.owner"}})],1):_vm._e(),_c('v-col',{staticClass:"custom-col",attrs:{"cols":"2"}},[_c('datepicker',{attrs:{"label":"Date","range-mode":""},model:{value:(_vm.filter.importDate),callback:function ($$v) {_vm.$set(_vm.filter, "importDate", $$v)},expression:"filter.importDate"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('div',[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","disabled":_vm.globalLoading || _vm.isEmptyFilter},on:{"click":_vm.filterData}},[_vm._v(" Filter ")])],1)])],1)],1),(_vm.hasCollectionData)?_c('line-chart',{attrs:{"chart-data":_vm.chartData}}):_c('empty-data')],1)}
var staticRenderFns = []

export { render, staticRenderFns }