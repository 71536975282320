<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400px"
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Invite
      </v-btn>
    </template>
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>Invite user</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <username-field
                label="Username"
                v-model="formData.username"
              ></username-field>
              <email-field label="Email" v-model="formData.email"></email-field>
              <v-select
                v-model="formData.role"
                :items="roles"
                item-text="title"
                item-value="value"
                class="pt-1 mb-n2 text-body-1"
                label="Role"
                single-line
              ></v-select>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="processing" @click="onClosed">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="processing"
            :disabled="invalid || processing"
            @click.prevent="onSubmit"
          >
            Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { ACCESS_SELECTION_OPTIONS, ROLES } from "@/components/enums";
import { mapActions } from "vuex";

import EmailField from "@/components/forms/inputs/Email";
import UsernameField from "@/components/forms/inputs/Username";

const getDefaultFormData = () => {
  return {
    username: "",
    email: "",
    role: ROLES.content_manager
  };
};

export default {
  name: "InviteUser",
  components: {
    EmailField,
    UsernameField
  },
  data: () => ({
    processing: false,
    invalid: false,
    dialog: false,
    formData: Object.assign({}, getDefaultFormData())
  }),
  props: {
    roles: {
      type: Array,
      default: () => ACCESS_SELECTION_OPTIONS
    },
    callback: {
      type: Function
    }
  },
  watch: {
    dialog: function(value) {
      // Reset form each time we close the dialog
      if (!value) {
        this._resetForm();
      }
    }
  },
  methods: {
    ...mapActions(["inviteUser"]),

    _resetForm() {
      // Reset validation. It also should reset inputs, but it doesn't in our case,
      // not sure why, so we do it manually
      this.$refs.form.reset();
      this.formData = Object.assign({}, getDefaultFormData());
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.processing = true;
        this.inviteUser({
          name: this.formData.username,
          email: this.formData.email,
          role: this.formData.role
        })
          .then(data => {
            this.onClosed();

            this.callback && this.callback(data);
          })
          .catch(error => {
            const errors = error?.response?.data?.data;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    onClosed() {
      this.dialog = false;
    }
  }
};
</script>
