<template>
  <div>
    <approved-recordings-header
      v-if="downloadMetadataProps"
      :refresh-data="onMetadataDownloaded"
      :download-metadata-props="downloadMetadataProps"
      :list-is-empty="!items.length"
    ></approved-recordings-header>
    <carousel
      :items="items"
      :config="carouselConfig"
      :current-item-index="currentItemIndex"
    >
      <template #carouselItem="{ item, classList }">
        <RecordingCardWithRelationships :recording="item" :class="classList" />
      </template>
    </carousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Carousel from "@/components/carousel/Carousel";
import { CarouselMixin } from "@/mixins/carousel-mixin";
import { PageMenu } from "@/mixins/page-menu";
import ApprovedRecordingsHeader from "@/components/ApprovedRecordingsHeader";
import { abbreviatedNumber } from "@/filters/numbers";
import { CarouselPagingByCursorMixin } from "@/mixins/carousel-paging-by-cursor-mixin";
import RecordingCardWithRelationships from "@/pages/providersData/RecordingCardWithRelationships";

export default {
  name: "RecordingsAnalysis",

  components: {
    RecordingCardWithRelationships,
    ApprovedRecordingsHeader,
    Carousel
  },

  mixins: [CarouselMixin, PageMenu, CarouselPagingByCursorMixin],

  data() {
    return {
      itemRouteParamName: "recordingId",
      downloadMetadataProps: null
    };
  },

  computed: {
    ...mapGetters(["globalApprovedProvidersDataCountGetter"]),

    carouselConfig() {
      let config = CarouselPagingByCursorMixin.computed.carouselConfig.call(
        this
      );

      // Providers Data Analysis doesn't require the Decline button
      config.buttons.decline.visible = false;
      config.noDataText = "There are no providers items at the moment.";
      config.buttons.approve.text = "Confirm invalid matches";
      config.style.maxWidth = 1000;

      return config;
    },

    basicRoute() {
      return `/${this.userCurrentAccountId}/recordings-analysis/${this.$route.meta.status}`;
    },

    singleItemRoute() {
      const recordingId = this.getRequestParams(this.$route.meta.status)
        .recordingId;
      return `${this.basicRoute}/${recordingId}`;
    },

    relatedMetadata() {
      return (
        this.items[this.currentItemIndex]?.related_metadata.map(metadata => ({
          share_metadata_id: metadata.id,
          is_valid: !metadata.is_suspicious
        })) || []
      );
    }
  },

  methods: {
    ...mapActions([
      "processProvidersData",
      "getProvidersData",
      "getSingleProvidersDataRecording",
      "updateGlobalProvidersDataCounts",
      "getProvidersDataTotalCount",
      "getDownloadRecordingAnalysisMetadataUrl"
    ]),

    updateItem(params) {
      params.relatedMetadata = this.relatedMetadata;

      return this.processProvidersData(params);
    },

    getItemsList(params) {
      return this.getProvidersData(params);
    },

    getItem(params) {
      return this.getSingleProvidersDataRecording(params);
    },

    onMetadataDownloaded() {
      this.updateGlobalProvidersDataCounts();
      this.initData();
    },

    getTotalCounter(params) {
      return this.getProvidersDataTotalCount(params);
    },

    async initDownloadMetadataProps() {
      const downloadLimit = abbreviatedNumber(
        this.globalApprovedProvidersDataCountGetter ?? 0
      );

      const formattedDialogText = `This will start the download process for the ${downloadLimit} approved record(s).
          Those records will be hidden from this page after that. Continue?`;

      const downloadAllText =
        "This will start the download process for all ever approved records. " +
        "Current active approved records will not be hidden. Continue?";

      const url = await this.getDownloadRecordingAnalysisMetadataUrl(
        this.$route.params.accountId
      );

      this.downloadMetadataProps = {
        url: url,
        dialogText: formattedDialogText,
        downloadLimit: downloadLimit.toString(),
        downloadAllText,
        basicRoute: this.basicRoute
      };
    }
  },

  mounted() {
    this.initData();
  },

  metaInfo: {
    title: "Providers Data Analysis"
  },

  watch: {
    globalApprovedProvidersDataCountGetter: {
      immediate: true,
      handler: function() {
        this.initDownloadMetadataProps();
      }
    },
    basicRoute: {
      immediate: true,
      handler: function() {
        this.initDownloadMetadataProps();
      }
    }
  }
};
</script>

<style lang="sass" scoped>
@import "~/src/assets/styles/carousel.sass"
</style>
