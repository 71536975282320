<template>
  <v-app>
    <v-app-bar app clipped-left class="white">
      <v-app-bar-nav-icon @click="toggleSidebar" />
      <v-toolbar-title>
        <router-link to="/" class="link-as-text">
          Publishing CMS
        </router-link>
      </v-toolbar-title>
      <span class="text-caption ml-1 mt-2">v.{{ appVersion }}</span>

      <v-spacer></v-spacer>
      <global-search v-if="isCurrentAccountHasPublishingType" />
      <v-spacer></v-spacer>

      <slot name="header" />

      <v-progress-linear
        height="3"
        :active="globalLoading"
        :indeterminate="globalLoading"
        absolute
        bottom
      ></v-progress-linear>
    </v-app-bar>

    <slot name="menu" :miniSideBar="miniSideBar" />

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <alert />
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Alert from "@/layouts/components/Alert";
import GlobalSearch from "@/layouts/components/GlobalSearch";
import { AccountMixin } from "@/mixins/account";

export default {
  components: { Alert, GlobalSearch },
  mixins: [AccountMixin],

  computed: {
    ...mapGetters(["globalLoading"]),

    ...mapState({
      miniSideBar: state => state.navigation.miniSideBar
    })
  },

  data: () => ({
    appVersion: process.env.VUE_APP_VERSION
  }),

  methods: {
    toggleSidebar() {
      this.$store.commit("setMiniSideBar", !this.miniSideBar);
    }
  }
};
</script>
