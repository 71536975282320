import { mapActions } from "vuex";

export const RemoveRecording = {
  data() {
    return {
      dialogText:
        "This will remove the recording and all related entities, should we proceed? This operation can not be undone."
    };
  },

  methods: {
    ...mapActions(["deleteRecording"]),
    async removeRecording(recordingId) {
      const result = await this.$confirm(this.dialogText, {
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
        persistent: true
      });

      if (result) {
        await this.deleteRecording({
          account_id: this.$route.params.accountId,
          recording_id: recordingId
        });
        this.afterRemoveRecording(recordingId);
      }
    },

    afterRemoveRecording(recordingId) {}
  }
};
