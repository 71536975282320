<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1"> Analytics - {{ pageTitle }} </v-card-title>
      </v-col>
      <v-col cols="auto">
        <v-btn right color="primary" @click="onDownloadClick">Download</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex" sm="3">
        <v-select v-model="currentChartLine" :items="chartLines"></v-select>
      </v-col>

      <v-col class="d-flex" sm="3">
        <v-autocomplete
          label="Account"
          v-model="currentScope"
          :items="accounts"
          clearable
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-checkbox label="Cumulative chart" v-model="cumulativeProgression" />
      </v-col>

      <v-col class="d-flex flex-row-reverse">
        <v-btn-toggle
          v-model="currentDaysShift"
          tile
          color="grey accent-3"
          group
        >
          <v-btn v-for="number in daysAgoList" :value="number" :key="number">
            {{ number }} days
          </v-btn>
          <v-btn :value="periodAll">
            All time
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <line-chart
      v-if="isDataAvailable"
      :chart-data="collectionData"
      :chart-options="options"
    ></line-chart>
    <empty-data v-else></empty-data>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import LineChart from "@/pages/supervisor/components/LineChart.vue";
import EmptyData from "@/pages/supervisor/components/EmptyData";
import {
  ANALYTICS_FIELD_BY_CHARTLINE_NAME,
  CHARTLINE_NAME,
  CHARTLINES_BY_GROUPS
} from "@/components/enums";
import {
  ANALYTICS_CHART_PERIOD_ALL,
  ANALYTICS_CHART_PERIODS_LIST,
  DAY_FORMAT,
  MONTH_FORMAT
} from "@/components/constants";
import { date } from "@/filters/date";
import { SupervisorPageMenu } from "@/mixins/supervisor-page-menu";
import { DownloadWindow } from "@/mixins/download-window";

const CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false
    }
  }
};

export default {
  name: "Analytics",
  components: { LineChart, EmptyData },

  mixins: [SupervisorPageMenu, DownloadWindow],

  data: () => ({
    currentScope: null,
    accounts: [],
    isDataAvailable: true,
    daysAgoList: ANALYTICS_CHART_PERIODS_LIST,
    periodAll: ANALYTICS_CHART_PERIOD_ALL,
    currentDaysShift: ANALYTICS_CHART_PERIODS_LIST[0],
    counters: null,
    collectionData: {},
    currentChartLine: Object.values(CHARTLINE_NAME)[0],
    options: CHART_OPTIONS,
    chartLinesBySection: CHARTLINES_BY_GROUPS,
    cumulativeProgression: false,
    persistentDataApplied: false // Prevent data fetching in watchers before all filters applied
  }),

  watch: {
    currentChartLine: function() {
      this.fetchData();
    },
    currentDaysShift: function() {
      this.fetchData();
    },
    currentScope: function() {
      this.fetchData();
    },
    $route: {
      handler: function() {
        this.persistentDataApplied = false;
        this.cumulativeProgression = false;
        this.currentChartLine = this.chartLines[0];
        this.loadPersistentState(); // Apply persistent filters after change analytics page
      },
      immediate: true
    },
    cumulativeProgression: function() {
      this.collectionData = this.getLineByName(this.currentChartLine);
      this.storePersistentState();
    }
  },

  computed: {
    ...mapState({
      persistentData: state => state.persistent.persistentData
    }),
    chartLines() {
      return this.chartLinesBySection[this.$route.meta.section];
    },
    pageTitle() {
      return this.$route.meta.pageTitle ?? "Analytics";
    },
    persistentStorageKey() {
      return `supervisor.analytics.${this.$route.meta.section}`;
    }
  },

  mounted() {
    this.setGlobalLoading(true);
    let params = { sort_by: "name", sort_direction: "ASC" };
    this.loadPersistentState();
    this.getAccounts(params)
      .then(({ data }) => {
        this.accounts = data;
      })
      .finally(() => {
        this.fetchData();
      });
  },

  methods: {
    ...mapActions([
      "getAnalyticsCounters",
      "setGlobalLoading",
      "getAccounts",
      "downloadAnalyticsUrl",
      "setPersistentData"
    ]),

    getFieldName(chartLineName) {
      return ANALYTICS_FIELD_BY_CHARTLINE_NAME[chartLineName];
    },

    getTimestamp(daysShift) {
      return moment()
        .subtract(daysShift, "days")
        .format("X");
    },

    getDataQueryParams() {
      let params = {
        name: this.getFieldName(this.currentChartLine),
        cumulative_progression: this.cumulativeProgression ? 1 : 0
      };

      if (this.currentDaysShift !== this.periodAll) {
        params.from = this.getTimestamp(this.currentDaysShift);
      }

      if (this.currentScope) {
        params["scope"] = this.currentScope;
      }

      return params;
    },

    fetchData() {
      // We need to wait until all persistent filters are applied
      if (!this.persistentDataApplied) {
        return;
      }
      this.setGlobalLoading(true);

      const params = this.getDataQueryParams();
      // Store applied filters
      this.storePersistentState();

      this.getAnalyticsCounters(params)
        .then(({ data }) => {
          this.counters = data;
        })
        .finally(() => {
          this.collectionData = this.getLineByName(this.currentChartLine);
          this.setGlobalLoading(false);
        });
    },

    getLineByName(lineName) {
      let fieldName = this.getFieldName(lineName);

      if (this.counters[fieldName]) {
        this.isDataAvailable = this.counters[fieldName].length > 0;
        let cumulativeValue = 0;

        return {
          labels: this.counters[fieldName].map(item =>
            date(
              item[0],
              this.currentDaysShift === this.periodAll
                ? MONTH_FORMAT
                : DAY_FORMAT
            )
          ),
          datasets: [
            {
              label: lineName,
              data: this.counters[fieldName].map(item => {
                cumulativeValue += item[1];
                return this.cumulativeProgression ? cumulativeValue : item[1];
              }),
              borderColor: "#3366cc",
              backgroundColor: "#3366cc",
              borderWidth: 2
            }
          ]
        };
      } else {
        return null;
      }
    },

    async onDownloadClick() {
      const params = this.getDataQueryParams();
      this.openDownloadWindow(await this.downloadAnalyticsUrl(params));
    },

    loadPersistentState() {
      const state = this.persistentData[this.persistentStorageKey];
      if (state) {
        Object.keys(state).forEach(key => {
          this[key] = state[key];
        });
      }

      this.persistentDataApplied = true;
    },

    storePersistentState() {
      this.setPersistentData({
        key: this.persistentStorageKey,
        value: {
          currentScope: this.currentScope,
          currentDaysShift: this.currentDaysShift,
          currentChartLine: this.currentChartLine,
          cumulativeProgression: this.cumulativeProgression
        }
      });
    }
  },

  metaInfo: {
    title: "Analytics"
  }
};
</script>
