import { request } from "@/services/api";
import { filterListParams } from "@/store/helpers";

export default {
  name: "supervisor",

  actions: {
    getAnalyticsCounters({ rootState }, params) {
      return request.get(`${rootState.apiPrefix}/supervisor/analytics`, {
        params: params
      });
    },

    getAccounts({ rootState }, params = null) {
      const queryParams =
        params === null ? { params: {} } : { params: filterListParams(params) };

      return request.get(
        `${rootState.apiPrefix}/supervisor/accounts?`,
        queryParams
      );
    },

    getPotentialRelationshipsStatistics({ rootState }, params) {
      return request.get(
        `${rootState.apiPrefix}/supervisor/recordings/statistics`,
        { params: params }
      );
    },

    updateAccountSettings({ rootState }, data) {
      const { accountId, payload } = data;

      return request
        .patch(
          `${rootState.apiPrefix}/supervisor/accounts/${accountId}`,
          payload
        )
        .then(responseBody => {
          // Need to update component's local state.
          return responseBody;
        });
    },

    deleteAccount({ rootState }, id) {
      return request.delete(`${rootState.apiPrefix}/supervisor/accounts/${id}`);
    },

    downloadAnalyticsUrl({ rootState }, params) {
      const query = new URLSearchParams(params).toString();
      return request.generateFullUrl(
        `${rootState.apiPrefix}/supervisor/analytics/download?${query}`
      );
    },

    getLimits({ rootState }) {
      return request.get(`${rootState.apiPrefix}/supervisor/limits/list`);
    },

    setLimits({ rootState }, data) {
      return request.post(`${rootState.apiPrefix}/supervisor/limits/set`, data);
    }
  }
};
