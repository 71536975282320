<template>
  <v-card class="popup-container">
    <v-card-text>
      <div class="popup-label">Recording</div>
      <div class="text--primary popup-text-value">
        {{ recording.metadata.title | default_value }}
      </div>
      <div class="text-caption py-0 mb-3">
        <v-icon>mdi-music-note</v-icon>
        Sound recording -
        {{ recording.metadata.created_at | date }},
        {{ recording.metadata.created_at | humanizeTime }}
      </div>

      <v-container
        class="pa-0 mb-3"
        v-if="recording.metadata.labels.length && !hideLabels"
      >
        <div class="popup-label">Asset labels</div>
        <template v-for="(label, index) in recording.metadata.labels">
          <v-chip
            label
            link
            x-small
            class="mr-2 px-1 text-caption grey--text text--darken-2 font-weight-medium"
            :key="index"
            >{{ label }}
          </v-chip>
        </template>
      </v-container>
      <div class="popup-label">Identifiers</div>
      <v-list class="transparent pa-0 mb-3">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >YouTube Asset ID
          </v-list-item-title>
          <v-hover v-slot="{ hover }">
            <v-list-item-subtitle
              class="text-left popup-text-value text--primary d-flex align-center"
            >
              <span class="overflow-ellipsis">{{
                recording.youtube_asset_id | default_value
              }}</span>
              <copy-text
                :value="recording.youtube_asset_id"
                v-if="recording.youtube_asset_id && hover"
                style="height: auto"
              />
            </v-list-item-subtitle>
          </v-hover>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Custom ID
          </v-list-item-title>
          <v-hover v-slot="{ hover }">
            <v-list-item-subtitle
              class="text-left popup-text-value text--primary d-flex align-center"
            >
              <span class="overflow-ellipsis">{{
                recording.metadata.custom_id | default_value
              }}</span>
              <copy-text
                :value="recording.metadata.custom_id"
                v-if="recording.metadata.custom_id && hover"
                style="height: auto"
              />
            </v-list-item-subtitle>
          </v-hover>
        </v-list-item>
      </v-list>

      <div class="popup-label">Metadata</div>
      <v-list class="transparent pa-0">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >ISRC
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.metadata.isrc | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >GRID
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.metadata.grid | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Record label
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.metadata.record_label | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0 mb-3">
          <v-list-item-title class="text-left popup-text-value"
            >Artists
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ artists | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { humanizeTime } from "@/filters/time";
import CopyText from "@/components/CopyText";

export default {
  name: "SoundRecordingMetadata",
  components: { CopyText },
  filters: { humanizeTime },
  props: {
    recording: Object,
    hideLabels: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    artists() {
      return this.recording.metadata.artists.join(", ");
    }
  }
};
</script>

<style lang="sass" scoped>
.popup-container
  .popup-data-row
    min-height: 20px

  .popup-label
    letter-spacing: 0.011em
    font-size: 12px

  .popup-text-value
    font-size: 0.78rem

  .v-list-item__title
    flex: 1 1 60%

  .v-list-item__subtitle
    min-height: 22px

  .overflow-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
</style>
