<template>
  <div>
    <recording-card :recording="recording"></recording-card>

    <v-divider class="grey lighten-2 mx-4 mt-1"></v-divider>

    <recording-metadata-card
      :effective_metadata="recording.effective_metadata"
      :related_metadata="recording.related_metadata"
    ></recording-metadata-card>
  </div>
</template>

<script>
import RecordingCard from "@/pages/providersData/RecordingCard";
import RecordingMetadataCard from "@/pages/providersData/RecordingMetadataCard";

export default {
  name: "RecordingCardWithRelationships",

  components: {
    RecordingCard,
    RecordingMetadataCard
  },

  props: {
    recording: {
      type: Object,
      required: true
    }
  }
};
</script>
