<template>
  <validation-observer ref="form">
    <google-one-tap
      :client_id="googleClientId"
      @google-one-tap-oauth-success="onOauthSuccess"
    ></google-one-tap>

    <v-form>
      <email-field label="E-mail" v-model="email" mode="lazy"></email-field>

      <password-field
        label="Password"
        v-model="password"
        mode="lazy"
        validation-rules="required"
      ></password-field>

      <v-card-actions class="pa-0">
        <router-link class="text-subtitle-2" to="forgot-password"
          >Forgot password?
        </router-link>

        <v-spacer></v-spacer>

        <v-btn :disabled="processing" href="/signup">Sign Up</v-btn>

        <v-btn
          :disabled="processing"
          :loading="processing"
          color="primary"
          type="submit"
          @click.prevent="onSubmit"
        >
          Login
        </v-btn>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from "vuex";

import EmailField from "@/components/forms/inputs/Email";
import PasswordField from "@/components/forms/inputs/Password";
import GoogleOneTap from "@/components/auth/GoogleOneTap";

export default {
  name: "Login",

  components: {
    EmailField,
    PasswordField,
    GoogleOneTap
  },

  data: () => ({
    processing: false,
    password: "",
    email: "",
    googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
  }),

  methods: {
    ...mapActions(["login", "oauthLogin", "clearAlert"]),

    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.processing = true;
        const data = {
          email: this.email,
          password: this.password
        };

        this.login(data)
          .catch(error => {
            this.clearAlert();
            const errors = error?.response?.data?.data;
            this.$refs.form.setErrors(errors);
          })
          .finally(() => {
            this.processing = false;
          });
      });
    },

    onOauthSuccess(token) {
      this.loginWithOauthCredentials(token);
    },

    loginWithOauthCredentials(token) {
      this.processing = true;
      this.oauthLogin(token)
        .catch(error => {
          this.clearAlert();
          const errors = error?.response?.data?.data;
          this.$refs.form.setErrors({ email: errors.error });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  },

  metaInfo: {
    title: "Login"
  }
};
</script>
