<template>
  <v-navigation-drawer app clipped flat permanent :mini-variant="toggle">
    <v-list dense>
      <v-list-item
        v-if="prevPage"
        class="list-item text-body-1"
        :to="prevPage"
        :exact="true"
      >
        <v-list-item-icon>
          <v-icon>mdi-arrow-left</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-subtitle-2">Back</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <component
        v-if="detailsSectionComponent && !toggle"
        :is="detailsSectionComponent"
        :params="detailsSectionComponentProps"
      ></component>

      <template v-for="(item, index) in items">
        <v-divider v-if="item.divider && item.visible" :key="index"></v-divider>
        <component
          :tooltip-disabled="!toggle"
          :item="item"
          :key="item.title"
          v-if="
            !item.divider &&
              item.visible &&
              isItemAllowedForCurrentAccountType(item)
          "
          :is="item.nestedItems ? 'SidebarMenuGroup' : 'SidebarMenuSingleItem'"
          :tooltip-open-delay="tooltipOpenDelay"
          :show-nested-items-left-margin="!toggle"
        />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import DashboardNavigationDetails from "@/pages/dashboard/DashboardNavigationDetails";
import CompositionNavigationDetails from "@/pages/compositions/CompositionNavigationDetails";
import SidebarMenuGroup from "@/layouts/components/menus/SidebarMenuGroup";
import SidebarMenuSingleItem from "@/layouts/components/menus/SidebarMenuSingleItem";
import OwnershipConflictsNavigationDetails from "@/pages/ownershipConflicts/history/components/OwnershipConflictsNavigationDetails.vue";

export default {
  name: "SidebarMenu",

  components: {
    SidebarMenuSingleItem,
    SidebarMenuGroup,
    DashboardNavigationDetails,
    CompositionNavigationDetails,
    OwnershipConflictsNavigationDetails
  },

  props: {
    items: Array,
    prevPage: String,
    detailsSectionComponent: {
      type: String,
      required: false
    },
    detailsSectionComponentProps: {
      type: Object,
      required: false
    },
    toggle: {
      type: Boolean,
      default: false
    },
    currentAccount: {}
  },

  methods: {
    isItemAllowedForCurrentAccountType(item) {
      return (
        this.currentAccount &&
        (!(item.accountTypes?.length ?? 0) ||
          item.accountTypes.includes(this.currentAccount.type))
      );
    }
  },

  data() {
    return {
      tooltipOpenDelay: 300
    };
  }
};
</script>

<style scoped lang="sass">

::v-deep .counter
  position: absolute
  right: 10px

::v-deep .list-item
  height: 48px

  .v-list-item__icon
    height: 32px

::v-deep .active-list-item
  box-shadow: 5px 0 0 var(--v-primary-base) inset

  .v-list-item__icon, .v-list-item__content
    color: var(--v-primary-base)
</style>

<style lang="sass">
.v-application--is-ltr
  .v-list-item__icon:first-child
    margin-right: 8px

  .v-list--dense .v-list-item
    min-height: 48px

    .v-list-item__icon
      height: 32px

  .v-list-group--sub-group .v-list-group__items .v-list-item
    padding-left: 48px

  .v-list-item__icon:last-of-type:not(:only-child)
    margin-left: 0

  .nested-list-item
    margin-left: 12px
</style>
