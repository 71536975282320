export const DATE_FORMAT = "MMM DD, YYYY";
export const DATETIME_FORMAT = "MMM DD, YYYY, hh:mm A";
export const DAY_FORMAT = "MMM D";
export const MONTH_FORMAT = "MMM";
export const DEFAULT_ALERT_DISPLAY_PERIOD = 10000;
export const FETCH_DATA_TIMEOUT = 500;
export const DATA_TABLE_PER_PAGE_OPTIONS = [10, 30, 50];
export const MENU_GROUP_TITLE_ACTIVE_COLOR = "grey darken-2";
export const TERRITORIES_COVERAGE_TYPE = "territories";
export const MAX_LABELS_COUNT = 30;
export const MAX_WRITERS_COUNT = 50;
export const MAX_DISPLAYED_TERRITORIES = 1000;
export const EMPTY_VALUE = "–";
export const IMPORT_PROGRESS_MONITORING_INTERVAL = 10000;
export const VALIDATION_PROGRESS_MONITORING_INTERVAL = 10000;
export const REPORT_PROGRESS_MONITORING_INTERVAL = 20000;
export const REMOVE_ACCESS_VALUE = "remove_access";
export const ANALYTICS_CHART_PERIODS_LIST = [14, 90];
export const ANALYTICS_CHART_PERIOD_ALL = "all";
export const LOGIN_PAGE_PATH = "/login";
export const OAUTH_LOGIN_PAGE_PATH = "/oauth/login";
export const LOGOUT_PAGE_PATH = "/logout";
export const SIGNUP_PAGE_PATH = "/signup";
export const CONFLICTS_HISTORY_PAGE_PATH = "/ownership-conflicts/history";
export const DEFAULT_LAYOUT = "app-layout";
export const TITLE_TEMPLATE = "%s | CMS";
export const HUMANIZE_TIME_FORMAT = "hh:mm A";
export const SIDEBAR_MENU_TOOLTIP_TRANSITION_DURATION = 200;
export const SIDEBAR_MENU_LIST_ITEM_HEIGHT = 48;
export const GLOBAL_SEARCH_MIN_CHARS_COUNT = 2;
export const GLOBAL_SEARCH_PAGE_SIZE = 10;
