<template>
  <div>
    <validation-observer ref="form">
      <v-row>
        <v-col>
          <v-card-title class="pl-1 justify-space-between">
            Limits
            <v-btn
              color="primary"
              @click="save"
              :disabled="saving || !changed"
              :loading="saving"
            >
              Save
            </v-btn>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
      </v-row>
      <v-row>
        <v-col class="col-2">
          <number-positive
            label="PRs search (per account/per day)"
            name="limits.POTENTIAL_RECORDINGS_SEARCH_PER_ACCOUNT_DAILY"
            v-model="limits.POTENTIAL_RECORDINGS_SEARCH_PER_ACCOUNT_DAILY"
          />
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SupervisorPageMenu } from "@/mixins/supervisor-page-menu";
import { deepCopy, objectsEqual } from "@/components/helpers";
import NumberPositive from "@/components/forms/inputs/NumberPositive.vue";

export default {
  name: "Limits",
  components: { NumberPositive },
  mixins: [SupervisorPageMenu],

  data() {
    return {
      limits: {
        POTENTIAL_RECORDINGS_SEARCH_PER_ACCOUNT_DAILY: undefined
      },
      prevLimits: {},
      saving: false
    };
  },

  methods: {
    ...mapActions([
      "getLimits",
      "setLimits",
      "showAlert",
      "clearAlert",
      "setGlobalLoading"
    ]),

    async save() {
      this.saving = true;
      try {
        await this.setLimits({ limits: this.limits });
        this.prevLimits = deepCopy(this.limits);
      } catch (error) {
        this.clearAlert();
        const errors = error?.response?.data?.data;
        this.$refs.form.setErrors(errors);
      } finally {
        this.saving = false;
      }
    }
  },

  async mounted() {
    this.setGlobalLoading(true);
    this.limits = await this.getLimits();
    this.prevLimits = deepCopy(this.limits);
    this.setGlobalLoading(false);
  },

  computed: {
    changed() {
      return !objectsEqual(this.limits, this.prevLimits);
    }
  },

  metaInfo: {
    title: "Limits"
  }
};
</script>

<style scoped lang="sass">
.saved-notification
  position: fixed
  margin: auto auto 100px
</style>
