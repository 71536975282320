<template>
  <v-switch
    v-model="innerValue"
    color="primary"
    inset
    :loading="loading"
    :label="label"
    @change="updateSettings"
    :false-value="false"
    :true-value="true"
  ></v-switch>
</template>

<script>
import { SettingsMixin } from "@/mixins/settings-mixin";

export default {
  name: "SettingsSwitcher",

  mixins: [SettingsMixin],

  data: () => ({
    innerValue: undefined
  }),

  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
      this.settings[this.settingName] = newVal;
    }
  },

  created() {
    SettingsMixin.created.call(this);
    this.innerValue = this.settings[this.settingName];
  }
};
</script>
