<template>
  <v-container class="text--primary px-0 pt-0 pb-4 overflow-y-auto">
    <div v-if="showCompositionDetails">
      <v-container class="pa-0">
        <v-card-text
          class="pb-0 text-truncate"
          :style="{ 'max-width': titleMaxWidth + 'px' }"
        >
          {{ innerComposition.meta.title }}
        </v-card-text>
        <v-card-subtitle class="text-caption py-0">
          <v-icon small color="grey darken-1">mdi-playlist-music</v-icon>
          Composition -
          {{ innerComposition.meta.created_at | date(DATETIME_FORMAT) }}
        </v-card-subtitle>
      </v-container>

      <v-container class="pa-0">
        <v-card-subtitle class="text-caption pb-0">Metadata</v-card-subtitle>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">YouTube Asset ID</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">{{
              innerComposition.youtube_asset_id | default_value
            }}</v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">Custom ID</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">{{
              innerComposition.meta.custom_id | default_value
            }}</v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">ISWC</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0 text-truncate">{{
              innerComposition.meta.iswc | default_value
            }}</v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">HFA</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0 text-truncate">
              {{ innerComposition.meta.hfa | default_value }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">Writers</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">
              {{
                innerComposition.meta.writers
                  ? innerComposition.meta.writers.join(", ")
                  : "" | default_value
              }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="grey lighten-2 mx-4 mt-4"></v-divider>
    </div>
    <v-container class="pa-0">
      <div>
        <v-card-text
          class="pb-0 text-truncate"
          :style="{
            'max-width': recordingTitleMaxWidth,
            display: 'inline-block',
            'vertical-align': 'bottom',
            width: 'auto'
          }"
        >
          <highlighted-label
            v-if="recording.metadata.title"
            :text="recording.metadata.title"
            :highlighted-part="innerComposition.meta.title"
          >
          </highlighted-label>
        </v-card-text>
        <sound-recording-has-active-references-marker
          :value="recording.metadata.has_active_references"
          class="v-card__text pb-0 pl-0"
        />
      </div>
      <v-card-subtitle class="text-caption py-0">
        <v-icon small color="grey darken-1">mdi-music-note</v-icon>
        Sound recording -
        {{ recording.metadata.created_at | date(DATETIME_FORMAT) }}
      </v-card-subtitle>
    </v-container>

    <v-container class="pa-0">
      <v-card-subtitle class="text-caption pb-0">Metadata</v-card-subtitle>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card-text class="py-0">YouTube Asset ID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.youtube_asset_id
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Custom ID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.metadata.custom_id | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">ISRC</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.metadata.isrc | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">GRID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.metadata.grid | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Record label</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0 text-truncate">{{
            recording.metadata.record_label | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Artists</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-labels
              :items="recording.metadata.artists"
              :highlighted-parts="innerComposition.meta.writers"
            >
            </highlighted-labels>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Owner</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <template v-if="recording.owners && recording.owners.length">{{
              recording.owners.join(", ")
            }}</template>
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pa-0">
      <v-card-subtitle class="text-caption pb-0">
        Related composition metadata
      </v-card-subtitle>
      <div v-if="recording.similar_composition_metadata">
        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">Title</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0 text-truncate" style="max-width: 450px">
              <highlighted-label
                :text="
                  recording.similar_composition_metadata.title | default_value
                "
                :highlighted-part="innerComposition.meta.title"
              >
              </highlighted-label
            ></v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">Provider</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">{{
              recording.similar_composition_metadata.owner | default_value
            }}</v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">ISWC</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">
              <highlighted-label
                :text="
                  recording.similar_composition_metadata.iswc | default_value
                "
                :highlighted-part="innerComposition.meta.iswc"
              >
              </highlighted-label>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">HFA</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">
              <highlighted-label
                :text="
                  recording.similar_composition_metadata.hfa | default_value
                "
                :highlighted-part="innerComposition.meta.hfa"
              >
              </highlighted-label>
            </v-card-text>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-0">
          <v-col cols="4">
            <v-card-text class="py-0">Writers</v-card-text>
          </v-col>
          <v-col cols="8">
            <v-card-text class="py-0">
              <highlighted-labels
                :items="recording.similar_composition_metadata.writers"
                :highlighted-parts="innerComposition.meta.writers"
              >
              </highlighted-labels>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row no-gutters class="ma-0">
          <v-col cols="8">
            <v-card-text class="py-0">{{ "" | default_value }}</v-card-text>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { DATETIME_FORMAT } from "@/components/constants";
import { mapGetters } from "vuex";
import HighlightedLabels from "@/components/forms/HighlightedLabels";
import HighlightedLabel from "@/components/forms/HighlightedLabel";
import SoundRecordingHasActiveReferencesMarker from "@/pages/recordings/components/SoundRecordingHasActiveReferencesMarker";

export default {
  name: "SoundRecordingCard",

  components: {
    HighlightedLabels,
    HighlightedLabel,
    SoundRecordingHasActiveReferencesMarker
  },

  props: {
    recording: {
      type: Object
    },
    showCompositionDetails: {
      type: Boolean,
      default: false
    },
    titleMaxWidth: {
      type: Number,
      default: 600
    }
  },

  computed: {
    ...mapGetters(["currentComposition"]),

    innerComposition() {
      return this.recording.composition || this.currentComposition;
    },
    recordingTitleMaxWidth() {
      return this.showCompositionDetails
        ? "494px" // 684px - 190px for references label
        : this.titleMaxWidth - 190 + "px";
    }
  },

  data: () => ({
    DATETIME_FORMAT
  })
};
</script>
