<template>
  <div style="height: 36px;" class="d-flex align-center">
    <span
      v-if="!copied"
      class="primary--text text-subtitle-2"
      style="margin-left: 5px;"
    >
      <a style="text-decoration: none;" @click="copyValue()">
        <v-icon small class="primary--text">
          {{ icon }}
        </v-icon>
        {{ label }}
      </a>
    </span>

    <span
      v-else
      class="primary--text text-subtitle-2"
      style="margin-left: 5px;"
    >
      <v-icon small class="primary--text">
        mdi-check
      </v-icon>
      {{ copiedText }}
    </span>
  </div>
</template>

<script>
import { clipboardCopy } from "@/components/helpers";

export default {
  name: "CopyText",

  props: {
    value: {
      type: String
    },
    timeout: {
      type: Number,
      default: 3000
    },
    label: {
      type: String
    },
    icon: {
      type: String,
      default: "mdi-content-copy"
    },
    copiedText: {
      type: String
    }
  },

  data: () => ({
    copied: false
  }),

  methods: {
    copyValue() {
      this.copied = true;
      setTimeout(() => (this.copied = false), this.timeout);
      clipboardCopy(this.value);
    }
  }
};
</script>
