<template>
  <div>
    <approved-recordings-header
      v-if="downloadMetadataProps"
      :refresh-data="initData"
      :download-metadata-props="downloadMetadataProps"
      :component="'DownloadReport'"
    ></approved-recordings-header>
    <carousel
      :items="items"
      :config="carouselConfig"
      :current-item-index="currentItemIndex"
    >
      <template #carouselItem="{ item, classList }">
        <SoundRecordingCard
          :recording="item"
          :titleMaxWidth="titleMaxWidth"
          :showCompositionDetails="true"
          :class="classList"
        />
      </template>
    </carousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Carousel from "@/components/carousel/Carousel";
import { CarouselMixin } from "@/mixins/carousel-mixin";
import { PageMenu } from "@/mixins/page-menu";
import { abbreviatedNumber } from "@/filters/numbers";
import ApprovedRecordingsHeader from "@/components/ApprovedRecordingsHeader";
import { CarouselPagingByCursorMixin } from "@/mixins/carousel-paging-by-cursor-mixin";
import SoundRecordingCard from "@/pages/recordings/components/SoundRecordingCard";
import { RECORDING_STATUS, REPORT_TYPES } from "@/components/enums";

export default {
  name: "GlobalPotentialRelationships",

  components: {
    SoundRecordingCard,
    Carousel,
    ApprovedRecordingsHeader
  },

  mixins: [CarouselMixin, PageMenu, CarouselPagingByCursorMixin],

  data() {
    return {
      itemRouteParamName: "recordingId",
      downloadMetadataProps: null
    };
  },

  computed: {
    ...mapGetters(["globalLoading", "globalApprovedRelationshipsCountGetter"]),

    basicRoute() {
      return `/${this.userCurrentAccountId}/recordings/${this.$route.meta.status}`;
    },

    singleItemRoute() {
      const recordingId = this.getRequestParams(this.$route.meta.status)
        .recordingId;
      return `${this.basicRoute}/${recordingId}`;
    }
  },

  methods: {
    ...mapActions([
      "updatePotentialRelationship",
      "getGlobalPotentialRelationships",
      "getSingleGlobalPotentialRelationship",
      "getGlobalPotentialRelationshipsTotalCount"
    ]),

    updateItem(params) {
      if (!params.recordingId) {
        params.recordingId = this.items[this.currentItemIndex]?.id;
      }

      if (!params.compositionId) {
        params.compositionId = this.items[
          this.currentItemIndex
        ]?.composition?.id;
      }

      const response = this.updatePotentialRelationship(params);

      response.then(data => {
        if (data?.count) {
          /** Update totalItems counter based by response. Must increment by 1 because it will be reduced later */
          this.totalItems = data.count + 1;
        }

        if (params.status === RECORDING_STATUS.approved) {
          /** Filter items with same recording_asset_id */
          this.items = this.items.filter((item, index) => {
            if (index === this.currentItemIndex) {
              return false;
            }
            return (
              item.recording_asset_id !==
              this.items[this.currentItemIndex].recording_asset_id
            );
          });
        }
      });

      return response;
    },

    getItemsList(params) {
      return this.getGlobalPotentialRelationships(params);
    },

    getItem(params) {
      return this.getSingleGlobalPotentialRelationship(params);
    },

    getTotalCounter(params) {
      return this.getGlobalPotentialRelationshipsTotalCount(params);
    },

    async initDownloadMetadataProps() {
      const downloadLimit = abbreviatedNumber(
        this.globalApprovedRelationshipsCountGetter ?? 0
      );

      const formattedDialogText =
        `This will start the download process for the ${downloadLimit} approved record(s). ` +
        "Those records will be hidden from this page after that, but will still be available on the composition detail page.";

      const downloadAllText =
        "This will start the download process for all ever approved records. " +
        "Current active approved records will not be hidden.";

      this.downloadMetadataProps = {
        dialogText: formattedDialogText,
        downloadLimit: downloadLimit.toString(),
        downloadAllText,
        basicRoute: this.basicRoute,
        reportType: REPORT_TYPES.ApprovedPotentialRelationships
      };
    },

    getPrevPageRequestParams() {
      return {
        cursor: {
          "potential_relationships.id": this.items[0]["id"],
          "metadata.has_active_references": this.items[0]["metadata"][
            "has_active_references"
          ]
        },
        direction: "DESC"
      };
    },

    getNextPageRequestParams() {
      return {
        cursor: {
          "potential_relationships.id": this.items[this.items.length - 1]["id"],
          "metadata.has_active_references": this.items[this.items.length - 1][
            "metadata"
          ]["has_active_references"]
        },
        direction: "ASC"
      };
    }
  },

  mounted() {
    this.initData();
  },

  metaInfo: {
    title: "Potential Relationships"
  },
  watch: {
    globalApprovedRelationshipsCountGetter: {
      immediate: true,
      handler: function() {
        this.initDownloadMetadataProps();
      }
    },
    basicRoute: {
      immediate: true,
      handler: function() {
        this.initDownloadMetadataProps();
      }
    }
  }
};
</script>

<style lang="sass" scoped>
@import "~/src/assets/styles/carousel.sass"
</style>
