import { request } from "@/services/api";

export default {
  name: "statistics",

  actions: {
    getStatistics({ getters, rootState }) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/statistics`
      );
    }
  }
};
