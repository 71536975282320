<template>
  <div v-if="hasItems">
    <span
      v-for="(item, index) in allItems"
      :key="index"
      v-html="highlightWords(item)"
    >
    </span>
  </div>
  <div v-else>
    {{ "" | default_value }}
  </div>
</template>

<script>
import { HighlightedLabelsMixin } from "@/mixins/highlighted-labels-mixin";

export default {
  name: "HighlightedLabels",

  mixins: [HighlightedLabelsMixin],

  props: {
    items: {
      type: Array
    },
    highlightedParts: {
      type: Array
    }
  }
};
</script>
