var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"right":"","disabled":_vm.tooltipDisabled,"nudge-top":_vm.tooltipNudge,"open-delay":_vm.tooltipOpenDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-group',_vm._g(_vm._b({ref:"group",attrs:{"prepend-icon":_vm.item.icon,"color":_vm.item.color || 'primary',"group":_vm.item.routeGroupRegex,"value":_vm.expanded},on:{"mouseup":_vm.onGroupClick},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.item.title))])],1)]},proxy:true}],null,true)},'v-list-group',attrs,false),on),_vm._l((_vm.item.nestedItems),function(nestedItem,i){return _c('v-tooltip',{key:i,attrs:{"right":"","disabled":_vm.tooltipDisabled,"open-delay":_vm.tooltipOpenDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(nestedItem.visible)?_c('v-list-item',_vm._g(_vm._b({staticClass:"list-item text-body-1",class:{
              'router-link-active': nestedItem.routerName === _vm.$route.name,
              'nested-list-item': _vm.showNestedItemsLeftMargin
            },attrs:{"link":"","exact":!nestedItem.highlightDuringPartialRouteMatch,"to":{ name: nestedItem.routerName },"active-class":"active-list-item"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{style:(nestedItem.iconStyle)},[_vm._v(_vm._s(nestedItem.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(nestedItem.title)+" "),(nestedItem.hasCounter && nestedItem.getCounter)?_c('span',{staticClass:"counter grey--text darken-1"},[_vm._v(" "+_vm._s(_vm._f("abbreviatedNumber")(nestedItem.getCounter()))+" ")]):_vm._e()])],1)],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(nestedItem.onHoverTitle || nestedItem.title))])])}),1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.item.onHoverTitle || _vm.item.title))])])}
var staticRenderFns = []

export { render, staticRenderFns }