import Vue from "vue";

export default {
  name: "persistent",

  state: {
    persistentData: {}
  },

  actions: {
    setPersistentData({ commit }, { key, value }) {
      commit("setPersistentData", { key, value });
    },

    resetPersistentKey({ commit }, key) {
      commit("resetPersistentData", key);
    }
  },

  mutations: {
    setPersistentData: (s, data) => {
      Vue.set(
        s.persistentData,
        data.key,
        Object.assign({}, s.persistentData[data.key], data.value)
      );
    },

    resetPersistentData: (state, key) => {
      Vue.delete(state.persistentData, key);
    }
  }
};
