<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1 justify-space-between">
          Compositions
          <v-btn color="primary" @click="downloadCompositions">Download</v-btn>
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="text-start clickable" @click="onRowClicked(item)">
              <v-menu
                open-on-hover
                bottom
                right
                offset-y
                nudge-right="60"
                max-width="410"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
                    <v-container
                      v-bind="attrs"
                      v-on="on"
                      :class="'px-0 py-1 ' + [hover ? 'primary--text' : '']"
                    >
                      <span>
                        {{ item.meta.title }}
                      </span>
                      <br />
                      <template v-for="(label, index) in showLabels(item)">
                        <v-chip
                          label
                          link
                          x-small
                          class="mr-2 px-1 text-caption grey--text text--darken-2 font-weight-medium"
                          :key="index"
                          >{{ label.name }}
                        </v-chip>
                      </template>
                      <span class="caption grey--text text--darken-4">{{
                        getRemainder(item)
                      }}</span>
                    </v-container>
                  </v-hover>
                </template>
                <composition-popup-detail :item="item" />
              </v-menu>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.youtube_asset_id | default_value }}</span
              ><br />
              <span>{{ item.meta.custom_id | default_value }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.meta.created_at | date }}</span
              ><br />
              <span class="grey--text text--darken-1">{{
                item.meta.created_at | humanizeTime
              }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.meta.updated_at | date }}</span
              ><br />
              <span class="grey--text text--darken-1">{{
                item.meta.updated_at | humanizeTime
              }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list nav dense class="data-table-actions-menu pa-0">
                  <v-list-item @click="removeCompositionClick(item)">
                    <v-list-item-content>
                      <v-list-item-title v-text="`Delete`" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { humanizeTime } from "@/filters/time";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import { DeleteComposition } from "@/mixins/remove-composition-mixin";
import CompositionPopupDetail from "@/pages/compositions/components/CompositionPopupDetail";
import { DownloadWindow } from "@/mixins/download-window";

export default {
  name: "Compositions",
  mixins: [
    PageMenu,
    GridUtils,
    DataTableFooterProps,
    DeleteComposition,
    DownloadWindow
  ],
  components: { CompositionPopupDetail },
  filters: { humanizeTime },

  data: () => ({
    maxLabelsCount: 4,
    headers: [
      {
        text: "Title | Labels",
        align: "start",
        sortable: false,
        value: "meta.title"
      },
      {
        text: "Youtube Asset ID | Custom ID",
        align: "end",
        sortable: false,
        width: "200px"
      },
      {
        text: "Created On",
        align: "end",
        sortable: true,
        value: "meta.created_at",
        width: "150px"
      },
      {
        text: "Updated On",
        align: "end",
        sortable: true,
        value: "meta.updated_at",
        width: "150px"
      },
      {
        text: "Actions",
        align: "end",
        value: "actions",
        sortable: false,
        width: "100px"
      }
    ],
    persistentStateKey: "compositions"
  }),

  methods: {
    ...mapActions(["getCompositions", "getDownloadCompositionsUrl"]),

    getResultsActionName() {
      return "getCompositions";
    },

    onRowClicked(item) {
      this.$router.push({
        name: "CompositionMetadata",
        params: {
          compositionId: item.id
        }
      });
    },

    showLabels(item) {
      return item.meta.labels.slice(0, this.maxLabelsCount);
    },

    getRemainder(item) {
      let remainder = item.meta.labels.length - this.maxLabelsCount;
      if (remainder > 0) {
        return `(+${remainder})`;
      }
    },

    async downloadCompositions() {
      const query = this.getQueryParams();
      this.openDownloadWindow(
        await this.getDownloadCompositionsUrl({
          account_id: this.$route.params.accountId,
          query: query.query
        })
      );
    },

    removeCompositionClick(item) {
      this.deleteComposition(item.id);
    },

    // Overrides mixin's method
    afterDeleteComposition() {
      this.fetchData();
    }
  },

  metaInfo: {
    title: "Compositions"
  }
};
</script>

<style lang="sass" scoped></style>
