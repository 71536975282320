<template>
  <v-card class="popup-container">
    <v-card-text>
      <div class="text-caption py-0 mb-3">
        <v-icon>mdi-music-note</v-icon>
        Sound recording
      </div>

      <div class="text--primary popup-text-value mb-3">
        {{ recording.title | default_value }}
      </div>

      <div class="popup-label">Identifiers</div>
      <v-list class="transparent pa-0 mb-3">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >YouTube Asset ID
          </v-list-item-title>

          <v-list-item-subtitle
            class="text-left popup-text-value text--primary d-flex align-center"
          >
            <span class="overflow-ellipsis">{{
              recording.youtube_asset_id | default_value
            }}</span>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Custom ID
          </v-list-item-title>

          <v-list-item-subtitle
            class="text-left popup-text-value text--primary d-flex align-center"
          >
            <span class="overflow-ellipsis">{{
              recording.custom_id | default_value
            }}</span>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>

      <div class="popup-label">Metadata</div>
      <v-list class="transparent pa-0">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >ISRC
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.isrc | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Artists
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ artists | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Labels
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recordingLabels | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Approx. daily views
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.approx_daily_views }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-text v-if="recording.composition">
      <div class="text-caption py-0">
        <v-icon>mdi-playlist-music</v-icon>
        Composition
      </div>

      <div class="text--primary popup-text-value mb-3">
        {{ recording.composition.title | default_value }}
      </div>

      <div class="popup-label">Identifiers</div>
      <v-list class="transparent pa-0 mb-3">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >YouTube Asset ID
          </v-list-item-title>

          <v-list-item-subtitle
            class="text-left popup-text-value text--primary d-flex align-center"
          >
            <span class="overflow-ellipsis">{{
              recording.composition.youtube_asset_id | default_value
            }}</span>
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Custom ID
          </v-list-item-title>

          <v-list-item-subtitle
            class="text-left popup-text-value text--primary d-flex align-center"
          >
            <span class="overflow-ellipsis">{{
              recording.composition.custom_id | default_value
            }}</span>
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>

      <div class="popup-label">Metadata</div>
      <v-list class="transparent pa-0">
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >ISWC
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.composition.iswc | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >HFA
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ recording.composition.hfa | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Writers
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ writers | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="popup-data-row pa-0">
          <v-list-item-title class="text-left popup-text-value"
            >Labels
          </v-list-item-title>
          <v-list-item-subtitle
            class="text-left text--primary popup-text-value text-wrap"
          >
            {{ compositionLabels | default_value }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { humanizeTime } from "@/filters/time";

export default {
  name: "ConflictMetadata",

  filters: { humanizeTime },

  props: {
    recording: Object
  },

  computed: {
    artists() {
      return this.joinArray(this.recording.artists);
    },

    recordingLabels() {
      return this.joinArray(this.recording.labels);
    },

    compositionLabels() {
      return this.joinArray(this.recording.composition.labels);
    },

    writers() {
      return this.joinArray(this.recording.composition.writers);
    }
  },

  methods: {
    joinArray(array) {
      return Array.isArray(array) && array.length ? array.join(", ") : null;
    }
  }
};
</script>

<style lang="sass" scoped>
.popup-container
  .popup-data-row
    min-height: 20px

  .popup-label
    letter-spacing: 0.011em
    font-size: 12px

  .popup-text-value
    font-size: 0.78rem

  .v-list-item__title
    flex: 1 1 60%

  .v-list-item__subtitle
    min-height: 22px

  .overflow-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
</style>
