import { ALERT_TYPE } from "@/components/enums";
import { DEFAULT_ALERT_DISPLAY_PERIOD } from "@/components/constants";

const getDefaultState = () => {
  return {
    alertDisplayPeriod: DEFAULT_ALERT_DISPLAY_PERIOD,
    alertMessage: undefined,
    /** @see ALERT_TYPE in /src/components/enums.js **/
    alertType: undefined
  };
};

const state = getDefaultState();

export default {
  state,
  mutations: {
    alert: (state, { text, type, displayPeriod }) => {
      state.alertMessage = text ?? undefined;
      state.alertType = type ?? ALERT_TYPE.info;
      state.displayPeriod = displayPeriod ?? DEFAULT_ALERT_DISPLAY_PERIOD;
    }
  },
  actions: {
    showAlert({ commit }, alert) {
      commit("alert", alert);
    },
    clearAlert({ commit }) {
      commit("alert", {});
    }
  }
};
