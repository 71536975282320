function convertSharesTerritories(shares, territories) {
  let convertedShares = [];

  for (let s of shares) {
    let share = { ...s };
    share.convertedTerritories = s.territories.map(v => [
      v,
      territories?.[v] ?? v
    ]);

    convertedShares.push(share);
  }

  return convertedShares;
}

export { convertSharesTerritories };
