export const ROLES = {
  admin: "admin",
  content_manager: "content_manager",
  conflicts_manager: "conflicts_manager",
  supervisor: "supervisor"
};

export const ROLES_WITH_TITLES = {
  admin: "Administrator",
  content_manager: "Content Manager",
  conflicts_manager: "Conflicts Manager",
  supervisor: "Supervisor"
};

export const ACCESS_SELECTION_OPTIONS = [
  {
    title: ROLES_WITH_TITLES[ROLES.admin],
    value: ROLES.admin
  },
  {
    title: ROLES_WITH_TITLES[ROLES.content_manager],
    value: ROLES.content_manager
  },
  {
    title: ROLES_WITH_TITLES[ROLES.conflicts_manager],
    value: ROLES.conflicts_manager
  }
];

export const IMPORT_STATUS = {
  pending: "pending",
  processing: "processing",
  processed: "processed"
};

export const IMPORT_WIZARD_STEPS = {
  file_select: "file_select",
  validate: "validate",
  import: "import"
};

export const IMPORT_TYPES = {
  external_cms: "external_cms",
  recordings_ownership_candidates: "recordings_ownership_candidates"
};

export const IMPORT_ERRORS_TYPES = {
  line_validation: "line_validation",
  other: "other"
};

export const RECORDING_STATUS = {
  pending: "pending",
  approved: "approved",
  skipped: "skipped",
  declined: "declined"
};

export const ZIP_FILES_MIME_TYPES = [
  "application/x-zip-compressed",
  "application/x-zip",
  "application/zip"
];

export const DOWNLOAD_METADATA_TYPES = {
  active_records: "active_records",
  all: "all"
};

export const CHARTLINE_NAME = {
  PendingPotentialRelationship: "Pending Potential Relationships",
  ApprovedPotentialRelationship: "Approved Potential Relationships",
  ProcessedCompositions: "Processed Compositions",
  PendingCompositions: "Pending Compositions",
  NewSoundRecordings: "Sound Recordings",
  NewCompositions: "Compositions",
  PendingDataAnalysis: "Pending Data Analysis",
  ApprovedDataAnalysis: "Approved Data Analysis"
};

export const CHARTLINE_GROUPS = {
  general: "general",
  recordings: "recordings",
  recordings_analysis: "recordings-analysis"
};

export const CHARTLINES_BY_GROUPS = (() => {
  let result = [];
  result[CHARTLINE_GROUPS.general] = [
    CHARTLINE_NAME.NewSoundRecordings,
    CHARTLINE_NAME.NewCompositions
  ];
  result[CHARTLINE_GROUPS.recordings] = [
    CHARTLINE_NAME.PendingPotentialRelationship,
    CHARTLINE_NAME.ApprovedPotentialRelationship,
    // CHARTLINE_NAME.PendingCompositions,
    CHARTLINE_NAME.ProcessedCompositions
  ];
  result[CHARTLINE_GROUPS.recordings_analysis] = [
    CHARTLINE_NAME.PendingDataAnalysis,
    CHARTLINE_NAME.ApprovedDataAnalysis
  ];
  return result;
})();

export const ANALYTICS_FIELD_BY_CHARTLINE_NAME = (() => {
  let result = [];
  result[CHARTLINE_NAME.ProcessedCompositions] = "compositions_processed";
  result[CHARTLINE_NAME.PendingCompositions] = "compositions_pending";
  result[CHARTLINE_NAME.NewSoundRecordings] = "recordings_new";
  result[CHARTLINE_NAME.PendingPotentialRelationship] =
    "potential_recordings_pending";
  result[CHARTLINE_NAME.PendingDataAnalysis] = "recordings_analysis_pending";
  result[CHARTLINE_NAME.ApprovedPotentialRelationship] =
    "potential_recordings_approved";
  result[CHARTLINE_NAME.NewCompositions] = "compositions_new";
  result[CHARTLINE_NAME.ApprovedDataAnalysis] = "recordings_analysis_approved";
  return result;
})();

export const ALERT_TYPE = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error"
};

export const ACCOUNT_TYPE = {
  publishing: "publishing",
  sound_recordings_management: "sound_recordings_management"
};

export const ACCOUNT_TYPE_NAME = {
  publishing: "Publishing",
  sound_recordings_management: "SR Management"
};

export const USER_MENU_STEPS = {
  initial: "initial",
  switching_account: "switching_account"
};

export const PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA = {
  POTENTIAL_RELATIONSHIPS_LIST: "potential_relationships.id",
  OWNERSHIP_CANDIDATES: "recording_ownership_candidates.id",
  RECORDINGS_ANALYSIS: "cms_scraper.scraping_recording.id",
  GLOBAL_POTENTIAL_RELATIONSHIPS: "potential_relationships.id"
};

export const GLOBAL_SEARCH_SCOPES = {
  COMPOSITIONS: "compositions",
  POTENTIAL_RELATIONSHIPS: "potential_relationships",
  PROVIDERS_DATA: "providers_data"
};

export const REPORT_TYPES = {
  ApprovedPotentialRelationships: "approved_potential_relationships",
  OwnershipAssetConflictsHistory: "ownership_asset_conflicts_history",
  OwnershipCompositionConflictsHistory:
    "ownership_composition_conflicts_history"
};

export const REPORT_STATUS = {
  processing: "processing",
  processed: "processed"
};

export const OWNERSHIP_CONFLICTS_STATUS = {
  resolved: "resolved",
  in_conflict: "in_conflict"
};

export const OWNERSHIP_CONFLICTS_ANALYTICS_METRICS = {
  total_conflicting_recordings: "Number of Assets in Conflict",
  total_conflicting_approx_daily_views: "Number of SR daily Views",
  total_conflicting_views: "Number of Conflict Views",
  conflicting_recordings_percent: "Percent of Assets in Conflict",
  conflicting_approx_daily_views_percent: "Percent of Views",
  new_conflicting_recordings: "New Conflicts",
  resolved_conflicting_recordings: "Resolved Conflicts"
};
