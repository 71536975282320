import {
  FETCH_DATA_TIMEOUT,
  DATA_TABLE_PER_PAGE_OPTIONS
} from "@/components/constants";
import { mapActions, mapState } from "vuex";
import { deepCopy } from "@/components/helpers";

export const GridUtils = {
  data: () => ({
    timeout: null,
    optionsData: {},
    totalResultsCount: 0,
    search: "",
    searchMinCharsCount: 2,
    loading: false,
    results: [],
    persistentStateKey: "",
    filter: {}
  }),

  computed: {
    ...mapState({
      persistentData: state => state.persistent.persistentData
    }),

    isEmptyFilter() {
      return Object.values(this.filter).every(value => value === null);
    },

    options: {
      get() {
        if (!this.persistentStateKey) {
          return this.optionsData;
        }

        let sortBy = [];
        let sortDesc = [];
        let page = 1;
        let itemsPerPage = Array.from(DATA_TABLE_PER_PAGE_OPTIONS).shift();
        if (this.persistentData[this.persistentStateKey]) {
          sortBy = this.persistentData[this.persistentStateKey].sortBy;
          sortDesc = this.persistentData[this.persistentStateKey].sortDesc;
          page = this.persistentData[this.persistentStateKey].page;
          itemsPerPage = this.persistentData[this.persistentStateKey]
            .itemsPerPage;
        }

        return Object.assign({}, this.optionsData, {
          sortBy,
          sortDesc,
          page,
          itemsPerPage
        });
      },
      set(value) {
        this.optionsData = value;
        if (!this.persistentStateKey) {
          return false;
        }
        const { sortBy, sortDesc, page, itemsPerPage } = value;
        this.setPersistentData({
          key: this.persistentStateKey,
          value: { sortBy, sortDesc, page, itemsPerPage }
        });
      }
    },

    filterData() {
      if (!this.persistentStateKey) {
        return this.filter;
      }

      return (
        this.persistentData[this.persistentStateKey]?.filter ?? this.filter
      );
    }
  },

  watch: {
    search: {
      handler(query) {
        if (query === "") {
          this.timeout = setTimeout(this.fetchData, FETCH_DATA_TIMEOUT);
        } else if (query.length < this.searchMinCharsCount) {
          clearTimeout(this.timeout);
          return false;
        }

        this.options.page = 1;
        this.totalResultsCount = 0;
        this.results = [];
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.fetchData, FETCH_DATA_TIMEOUT);
      }
    },

    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    },

    isEmptyFilter(newVal) {
      if (newVal) {
        // reset filter and reload in case of empty filter
        this.resetFilter();
        this.fetchData();
      }
    }
  },

  methods: {
    ...mapActions(["setPersistentData", "resetPersistentKey"]),

    fetchData() {
      this.loading = true;
      let params = this.getQueryParams();
      this[this.getResultsActionName()](params)
        .then(({ data, meta }) => {
          this.results = data;
          this.totalResultsCount = meta.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getQueryParams() {
      let params = {
        page: this.options.page,
        size: this.options.itemsPerPage,
        query: this.search.length < this.searchMinCharsCount ? "" : this.search
      };
      // sort_by && sort_direction is not required, need to check it before set to prevent undefined properties in object
      if (this.options.sortBy?.length) {
        params["sort_by"] = this.options.sortBy[0];
      }
      if (this.options.sortDesc?.length) {
        params["sort_direction"] = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      // set filter param
      if (this.filterData) {
        params["filter"] = this.filterData;
      }

      return params;
    },

    getResultsActionName() {
      throw new Error("Must be implemented in a subclass");
    },

    setFilter() {
      if (this.persistentStateKey) {
        // here we just need to persist filter and then watchers & computed props will do the magic
        let persistentData = this.persistentData[this.persistentStateKey];

        this.setPersistentData({
          key: this.persistentStateKey,
          value: Object.assign({}, persistentData, {
            filter: this.filter,
            page: 1
          })
        });
      } else {
        this.fetchData();
      }
    },

    resetFilter() {
      Object.keys(this.filter).forEach(key => (this.filter[key] = null));

      let persistentData = this.persistentData[this.persistentStateKey];

      delete persistentData.filter;

      this.setPersistentData({
        key: this.persistentStateKey,
        value: Object.assign({}, persistentData, { page: 1 })
      });
    }
  },

  beforeRouteLeave(to, from, next) {
    if (!to.path.startsWith(from.path) && this.persistentStateKey) {
      this.resetPersistentKey(this.persistentStateKey);
    }

    next();
  },

  created() {
    if (this.filterData) {
      this.filter = deepCopy(this.filterData);
    }
  }
};
