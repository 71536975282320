<template>
  <v-card elevation="0" class="pa-2" align="center" max-height="230">
    <v-container></v-container>
    <v-container class="image-block blue darken-1 pt-6">
      <router-link to="/">
        <v-icon large dark style="font-size: 70px">
          mdi-domain
        </v-icon>
      </router-link>
    </v-container>
    <v-container>
      <p class="text-subtitle-2 mb-0">{{ this.role }}</p>
      <p class="text-caption mb-0">{{ this.userName }}</p>
    </v-container>
  </v-card>
</template>

<script>
import { ROLES_WITH_TITLES } from "@/components/enums";
import { mapGetters } from "vuex";

export default {
  name: "DashboardNavigationDetails",

  data() {
    return {
      role: null,
      userName: null
    };
  },

  computed: {
    ...mapGetters(["userData", "userCurrentAccount"])
  },

  methods: {
    _initData() {
      this.role = this.userCurrentAccount
        ? ROLES_WITH_TITLES[this.userCurrentAccount.role]
        : undefined;
      this.userName = this.userData.name;
    }
  },

  mounted() {
    this._initData();
  },

  watch: {
    userData: {
      deep: true,
      handler: function() {
        this._initData();
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.image-block
  width: 120px
  height: 120px
  /* There is a special class for first v-card's child */
  border-radius: 60px !important
</style>
