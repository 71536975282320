<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1 justify-space-between">
          <span>
            Ownership Conflicts
          </span>
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto mt-1">
        <download-report
          :report-type="downloadReportProps.reportType"
          :download-limit="downloadReportProps.downloadLimit"
          :download-all-text="downloadReportProps.downloadAllText"
          :dialog-text="downloadReportProps.dialogText"
          :hide-options="downloadReportProps.hideOptions"
          :label="downloadReportProps.label"
          :basic-route="downloadReportProps.basicRoute"
          :fixed="downloadReportProps.fixed"
          :generate-report-label="downloadReportProps.generateReportLabel"
          :report-params="reportParams"
        ></download-report>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-row>
        <v-col cols="2">
          <datepicker
            v-model="filter.conflictStartDate"
            label="In Conflict"
            range-mode
          ></datepicker>
        </v-col>

        <v-col cols="2">
          <datepicker
            v-model="filter.conflictResolveDate"
            label="Conflict Resolved"
            range-mode
          ></datepicker>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="filter.youtubeAssetId"
            :label="youtubeAssetIdFilterTitle"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-text-field
            v-model="filter.customId"
            :label="customIdFilterTitle"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="filter.status"
            :items="statusFilterChoices"
            clearable
            label="Resolved"
          ></v-select>
        </v-col>

        <v-col cols="1">
          <div>
            <v-btn
              class="mt-3"
              color="primary"
              @click="setFilter"
              :disabled="loading || isEmptyFilter"
            >
              Filter
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-data-table
        selectable
        :headers="headersByAccountType"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <v-hover v-slot="{ hover }">
              <td class="text-start clickable" @click="onRowClicked(item)">
                <v-menu
                  open-on-hover
                  bottom
                  right
                  offset-y
                  nudge-right="60"
                  max-width="410"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-container
                      v-bind="attrs"
                      v-on="on"
                      :class="'px-0 py-1 ' + [hover ? 'primary--text' : '']"
                    >
                      <span>
                        {{ item.title | default_value }}
                      </span>
                    </v-container>
                  </template>
                  <conflict-metadata :recording="item"></conflict-metadata>
                </v-menu>
              </td>
            </v-hover>
            <td class="text-start table-secondary-column">
              <span>{{ item.youtube_asset_id | default_value }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{
                (item.artists ? item.artists.join(", ") : "") | default_value
              }}</span>
            </td>
            <template v-if="isPublishingAccount">
              <td class="text-start table-secondary-column">
                <span>{{ item.composition.title | default_value }}</span
                ><br />
                <span class="text--secondary">{{
                  item.composition.youtube_asset_id | default_value
                }}</span>
                <br />
                <span class="text--secondary">{{
                  item.composition.custom_id | default_value
                }}</span>
              </td>
            </template>
            <template v-else>
              <td class="text-start table-secondary-column">
                <span>{{ item.custom_id | default_value }}</span>
              </td>
            </template>
            <td class="text-start table-secondary-column">
              <span>{{ item.approx_daily_views }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{ item.conflicts.conflicting_views }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{ item.latest_conflict.conflict_started_at | date }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{
                item.status === conflictStatuses.resolved
                  ? item.latest_conflict.conflict_resolved_at
                  : null | date
              }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import Datepicker from "@/components/forms/inputs/Datepicker.vue";
import DownloadReport from "@/components/buttons/DownloadReport.vue";
import { deepCopy } from "@/components/helpers";
import {
  ACCOUNT_TYPE,
  OWNERSHIP_CONFLICTS_STATUS,
  REPORT_TYPES
} from "@/components/enums";
import ConflictMetadata from "@/pages/ownershipConflicts/history/components/ConflictMetadata.vue";

export default {
  name: "AssetsConflictsHistory",
  components: { ConflictMetadata, Datepicker, DownloadReport },

  mixins: [PageMenu, GridUtils, DataTableFooterProps],

  data: () => ({
    persistentStateKey: "ownership-conflicts-history",
    filter: {
      conflictStartDate: null,
      conflictResolveDate: null,
      youtubeAssetId: null,
      customId: null,
      status: null
    },
    conflictStatuses: OWNERSHIP_CONFLICTS_STATUS,
    statusFilterChoices: [
      {
        text: "Yes",
        value: OWNERSHIP_CONFLICTS_STATUS.resolved
      },
      {
        text: "No",
        value: OWNERSHIP_CONFLICTS_STATUS.in_conflict
      }
    ],
    headers: [
      {
        text: "Title",
        sortable: false,
        width: "18%"
      },
      {
        text: "Youtube Asset ID",
        sortable: false,
        align: "start"
      },
      {
        text: "Artist",
        sortable: false,
        width: "18%"
      },
      {
        text: "Approx. Daily Views",
        sortable: true,
        value: "approx_daily_views",
        align: "start"
      },
      {
        text: "Views In Conflict",
        sortable: true,
        value: "conflicts.conflicting_views",
        align: "start"
      },
      {
        text: "In Conflict",
        sortable: true,
        value: "latest_conflict.conflict_started_at",
        align: "start"
      },
      {
        text: "Conflict Resolved",
        sortable: true,
        value: "latest_conflict.conflict_resolved_at",
        align: "start"
      }
    ],
    downloadReportProps: {
      reportType: REPORT_TYPES.OwnershipAssetConflictsHistory,
      dialogText: "",
      downloadLimit: "",
      downloadAllText: "",
      basicRoute: "",
      fixed: false,
      hideOptions: true,
      label: "Download report",
      generateReportLabel: "Generate report"
    }
  }),

  computed: {
    ...mapGetters(["userCurrentAccount"]),

    isPublishingAccount() {
      return this.userCurrentAccount.type === ACCOUNT_TYPE.publishing;
    },

    youtubeAssetIdFilterTitle() {
      return this.isPublishingAccount ? "Composition Asset ID" : "Asset ID";
    },

    customIdFilterTitle() {
      return this.isPublishingAccount ? "Composition Custom ID" : "Custom ID";
    },

    headersByAccountType() {
      const compositionInfoColumn = {
        text: this.isPublishingAccount ? "Composition" : "Custom ID",
        sortable: false,
        align: "start"
      };

      const index = this.headers.findIndex(
        column => column.text === "Approx. Daily Views"
      );

      const headersCopy = deepCopy(this.headers);

      headersCopy.splice(index, 0, compositionInfoColumn);

      return headersCopy;
    },

    reportParams() {
      const params = {
        ...((this.filter.conflictStartDate?.[0] ||
          this.filter.conflictStartDate?.[1]) && {
          "latest_conflict.conflict_started_at": {
            from: this.filter.conflictStartDate?.[0],
            to: this.filter.conflictStartDate?.[1]
          }
        }),
        ...((this.filter.conflictResolveDate?.[0] ||
          this.filter.conflictResolveDate?.[1]) && {
          "latest_conflict.conflict_resolved_at": {
            from: this.filter.conflictResolveDate?.[0],
            to: this.filter.conflictResolveDate?.[1]
          }
        }),
        ...(this.filter.youtubeAssetId && {
          youtube_asset_id: this.filter.youtubeAssetId
        }),
        ...(this.filter.customId && { custom_id: this.filter.customId }),
        ...(this.filter.status && { status: this.filter.status })
      };

      return Object.keys(params).length > 0 ? params : null;
    }
  },

  methods: {
    ...mapActions([
      "getAssetsConflictsHistory",
      "setCurrentConflictingRecording"
    ]),

    getResultsActionName() {
      return "getAssetsConflictsHistory";
    },

    onRowClicked(item) {
      this.setCurrentConflictingRecording(item);

      this.$router.push({
        name: "SingleAssetConflictsHistory",
        params: {
          recordingId: item.id
        }
      });
    }
  },
  metaInfo: {
    title: "Conflicts History"
  }
};
</script>

<style scoped lang="sass">
.conflict-popup-block
  position: absolute
</style>
