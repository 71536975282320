<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" :color="color">
        {{ accountTypeIcon }}
      </v-icon>
    </template>
    <span>{{ accountTypeIconTooltipText }}</span>
  </v-tooltip>
</template>

<script>
import { ACCOUNT_TYPE, ACCOUNT_TYPE_NAME } from "@/components/enums";

export default {
  name: "AccountTypeIcon",
  props: {
    account: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    accountTypeIconTooltipText() {
      return ACCOUNT_TYPE_NAME[this.account?.type] + " account";
    },
    accountTypeIcon() {
      return this.account?.type === ACCOUNT_TYPE.publishing
        ? "mdi-music-box-multiple"
        : "mdi-music-note";
    }
  }
};
</script>
