<template>
  <v-card-text>
    <div class="composition-meta-info">
      <h4>Global</h4>
      <v-row class="mb-1">
        <v-col class="pb-0">
          <span class="label">YouTube Asset ID</span>
          <span class="content" v-if="composition.id">{{
            composition.youtube_asset_id
          }}</span>
          <v-skeleton-loader type="heading" v-else />
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col class="pb-0">
          <span class="label">Created on</span>
          <span class="content" v-if="composition.id">{{
            composition.meta.created_at | date
          }}</span>
          <v-skeleton-loader type="heading" v-else />
        </v-col>
        <v-divider vertical inset></v-divider>
        <v-col>
          <span class="label">Updated on</span>
          <span class="content" v-if="composition.id">{{
            composition.meta.updated_at | date
          }}</span>
          <v-skeleton-loader type="heading" v-else />
        </v-col>
      </v-row>
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "CompositionInfo",
  props: {
    composition: {
      type: Object
    }
  }
};
</script>

<style lang="sass" scoped>
div.composition-meta-info
  width: 50%

div.composition-meta-info span.label
  color: #8b8b8b
  font-size: 0.85em

div.composition-meta-info span.content
  display: flex

.v-text-field.v-text-field--enclosed .v-text-field__details
  padding: 0
</style>
