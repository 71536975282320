import { mapActions } from "vuex";

export const DeleteComposition = {
  data() {
    return {
      dialogText:
        "This will remove the composition and all related entities, should we proceed? This operation can not be undone."
    };
  },

  methods: {
    ...mapActions(["removeComposition"]),
    async deleteComposition(compositionId) {
      const result = await this.$confirm(this.dialogText, {
        buttonTrueText: "Continue",
        buttonFalseText: "Cancel",
        persistent: true
      });

      if (result) {
        await this.removeComposition(compositionId);
        this.afterDeleteComposition();
      }
    },

    afterDeleteComposition() {
      this.$router.push({ name: "Compositions" });
    }
  }
};
