import { mapGetters } from "vuex";
import { ACCOUNT_TYPE } from "@/components/enums";
import { abbreviatedNumber } from "@/filters/numbers";

export const DashboardSections = {
  data: () => ({
    statistics: {}
  }),

  computed: {
    ...mapGetters(["userCurrentAccount"]),
    availableSections() {
      let sections = [];

      if (this.userCurrentAccount?.type === ACCOUNT_TYPE.publishing) {
        sections.push({
          title: "General",
          items: [
            {
              title: "Total Compositions",
              value: this.statistics.compositions.total
            },
            {
              title: "Total Sound Recordings",
              value: this.statistics.recordings.total
            },
            {
              title: "Pending Data Analysis",
              value: this.statistics.recordings_analysis.pending
            },
            {
              title: "Approved Data Analysis",
              value: this.statistics.recordings_analysis.approved
            }
          ],
          divider: true
        });

        sections.push({
          title: "Potential relationship search",
          items: [
            {
              title: "Compositions in processing",
              value: this.statistics.compositions.processing
            },
            {
              title: "Pending Compositions",
              value: this.statistics.compositions.pending
            },
            {
              title: "Pending Potential Relationships",
              value: this.statistics.recordings.pending,
              subtext: this.statistics.recordings.with_references?.pending
                ? abbreviatedNumber(
                    this.statistics.recordings.with_references?.pending
                  ) + " with references"
                : ""
            },
            {
              title: "Approved Potential Relationships",
              value: this.statistics.recordings.approved,
              subtext: this.statistics.recordings.with_references?.approved
                ? abbreviatedNumber(
                    this.statistics.recordings.with_references?.approved
                  ) + " with references"
                : ""
            }
          ],
          divider: true
        });
      }

      if (
        this.userCurrentAccount?.type ===
        ACCOUNT_TYPE.sound_recordings_management
      ) {
        sections.push({
          title: "Sound Recording Processing",
          items: [
            {
              title: "Total Sound Recordings",
              value: this.statistics.sr_ownerships.sr_total
            },
            {
              title: "Pending Sound Recordings",
              value: this.statistics.sr_ownerships.sr_pending
            },
            {
              title: "Sound Recordings in processing",
              value: this.statistics.sr_ownerships.sr_processing
            }
          ],
          divider: true
        });
        sections.push({
          title: "Sound Recording Ownership Processing",
          items: [
            {
              title: "Total Sound Recordings Ownership ",
              value: this.statistics.sr_ownerships.ownership_total,
              subtext: this.statistics.sr_ownerships
                .ownership_total_with_references
                ? abbreviatedNumber(
                    this.statistics.sr_ownerships
                      .ownership_total_with_references
                  ) + " with references"
                : ""
            },
            {
              title: "Pending SR Ownership",
              value: this.statistics.sr_ownerships.ownership_pending
            },
            {
              title: "Approved SR Ownership",
              value: this.statistics.sr_ownerships.ownership_approved
            }
          ],
          divider: true
        });
      }
      return sections;
    }
  }
};
