<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <slot name="title"></slot>
    <v-card elevation="0">
      <v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!changed || processing"
            :loading="false"
            color="primary"
            @click.prevent="undoChanges"
          >
            Undo changes
          </v-btn>
          <v-btn
            type="submit"
            :disabled="!changed || processing || invalid"
            :loading="processing"
            color="primary"
            @click.prevent="onSubmit"
          >
            Save
          </v-btn>
          <v-btn
            class="ml-6"
            :loading="false"
            color="error"
            @click.prevent="removeCompositionClick"
          >
            DELETE
          </v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <composition-info :composition="composition"></composition-info>

        <v-divider></v-divider>
        <v-card-text class="mb-2">
          <div class="composition-meta-info">
            <h4>Your metadata</h4>
          </div>
        </v-card-text>
        <v-col cols="8">
          <v-row>
            <v-col class="py-0">
              <text-field
                label="Asset title (required)"
                name="title"
                v-model="composition.meta.title"
                validation-rules="required|max:255"
                outlined
                dense
              ></text-field>
            </v-col>
            <v-col class="py-0">
              <text-field
                label="Custom ID (required)"
                name="custom_id"
                v-model="composition.meta.custom_id"
                :validation-rules="{
                  required: true,
                  max: 64,
                  regex: /^[a-z_\-0-9.@:\/]+$/i
                }"
                outlined
                dense
              ></text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <text-field
                label="ISWC"
                name="iswc"
                v-model="composition.meta.iswc"
                autocomplete="off"
                hint="Formats: T0000000000 or T-000.000.000-0"
                :validation-rules="{
                  regex: /(?:T\d{10}$)|(?:T-\d{3}.\d{3}.\d{3}-\d$)/
                }"
                outlined
                dense
              ></text-field>
              <combo-box
                label="Writers"
                name="writers"
                v-model="composition.meta.writers"
                aria-multiline="true"
                :delimiters="['|']"
                outlined
                hint="Enter pipe '|' separated values"
                :counter="maxWritersCount"
                :validation-rules="{ maxItems: maxWritersCount }"
              ></combo-box>
            </v-col>
            <v-col class="py-0">
              <text-field
                label="HFA"
                name="hfa"
                hint="Starts with 1 uppercase letter and 5 digits"
                v-model="composition.meta.hfa"
                :validation-rules="{ regex: /^([A-Z0-9]{6})$/ }"
                outlined
                dense
              ></text-field>
              <text-area-field
                label="Notes"
                name="notes"
                v-model="composition.meta.notes"
                rows="3"
                rules="max:500"
              ></text-area-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col class="py-0" cols="12">
              <labels-autocomplete
                label="Asset labels"
                name="labels"
                v-model="composition.meta.labels"
                :fetch-callback="getLabels"
                :create-callback="createLabel"
                key-attr="name"
                outlined
                :counter="maxLabelsCount"
                :validation-rules="{
                  maxItems: maxLabelsCount,
                  regex: /^[^<>*%:&,\;|]+$/i,
                  min: 2,
                  max: 35
                }"
              >
              </labels-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CompositionPageMenu } from "@/mixins/composition-page-menu";
import TextField from "@/components/forms/inputs/TextField";
import TextAreaField from "@/components/forms/inputs/TextArea";
import ComboBox from "@/components/forms/inputs/ComboBox";
import LabelsAutocomplete from "@/pages/compositions/components/LabelsAutocomplete";
import CompositionInfo from "@/pages/compositions/components/CompositionInfo";
import { deepCopy, objectsEqual } from "@/components/helpers";
import { DeleteComposition } from "@/mixins/remove-composition-mixin";
import { MAX_LABELS_COUNT, MAX_WRITERS_COUNT } from "@/components/constants";

export default {
  name: "CompositionMetadata",

  mixins: [CompositionPageMenu, DeleteComposition],

  components: {
    TextField,
    TextAreaField,
    ComboBox,
    LabelsAutocomplete,
    CompositionInfo
  },

  data() {
    return {
      invalid: false,
      changed: false,
      processing: false,
      composition: { meta: {} },
      prevComposition: { meta: {} }
    };
  },

  computed: {
    maxLabelsCount: function() {
      return MAX_LABELS_COUNT;
    },

    maxWritersCount: function() {
      return MAX_WRITERS_COUNT;
    }
  },

  watch: {
    composition: {
      deep: true,
      handler: function(val, oldVal) {
        const oldMeta = JSON.parse(JSON.stringify(oldVal));

        if (Object.keys(oldMeta.meta).length > 0) {
          this.changed = !objectsEqual(this.composition, this.prevComposition);
        }
      }
    }
  },

  methods: {
    ...mapActions([
      "initCurrentComposition",
      "createCurrentCompositionMeta",
      "getLabels",
      "createLabel",
      "setGlobalLoading",
      "storePageDetailsSectionComponentProps",
      "resetPageDetailsSectionComponentProps",
      "resetCurrentComposition"
    ]),
    ...mapGetters(["globalLoading"]),

    onSubmit() {
      this.processing = true;

      // No need to update store.currentComposition manually here, this method
      // does the job for us
      this.createCurrentCompositionMeta(this._prepareRequestData())
        .then(() => {
          this._initPrevCompositionWithCurrent();
        })
        .catch(error => {
          const errors = error?.response?.data?.data;
          this.$refs.form.setErrors(errors);
        })
        .finally(() => {
          this.changed = false;
          this.processing = false;
        });
    },

    undoChanges() {
      this.composition = deepCopy(this.prevComposition);
    },

    _initPrevCompositionWithCurrent() {
      this.prevComposition = deepCopy(this.composition);
    },

    _prepareRequestData() {
      let meta = {
        title: this.composition.meta.title,
        custom_id: this.composition.meta.custom_id,
        notes: this.composition.meta.notes
      };

      if (this.composition.meta.writers.length) {
        meta.writers = this.composition.meta.writers;
      }

      if (this.composition.meta.labels.length) {
        meta.labels = this.composition.meta.labels;
      }

      if (this.composition.meta.iswc) {
        meta.iswc = this.composition.meta.iswc;
      }

      if (this.composition.meta.hfa) {
        meta.hfa = this.composition.meta.hfa;
      }

      return meta;
    },

    removeCompositionClick() {
      this.deleteComposition(this.$route.params.compositionId);
    }
  },

  beforeMount() {
    this.setGlobalLoading(true);
    this.resetPageDetailsSectionComponentProps();
  },

  mounted() {
    this.initCurrentComposition(this.$route.params.compositionId).then(
      composition => {
        // We cannot reference the same composition instance in form, so we create
        // a deep copy and use it as component's data variable
        this.composition = deepCopy(composition);
        this._initPrevCompositionWithCurrent();

        this.setGlobalLoading(false);
      }
    );
  },

  metaInfo: {
    title: "Composition details"
  }
};
</script>

<style lang="sass" scoped>
::v-deep .v-chip.v-size--default
  max-width: 100% !important
::v-deep .v-chip .v-chip__content span
  max-width: 1000000px !important
</style>
