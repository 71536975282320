import axios from "axios";
import store from "@/store/index";
import { ALERT_TYPE } from "@/components/enums";

// Required for cookie-based authentication (backend Sanctum package)
axios.defaults.withCredentials = true;

const API_URL = process.env.VUE_APP_API_URL;

export function request(
  path,
  data,
  { raw = false, handle4xx = true, ...options } = {}
) {
  let url = API_URL + path;
  return axios({
    url,
    method: "get",
    data,
    ...options
  })
    .then(response => {
      return raw ? response : response?.data;
    })
    .catch(err => {
      if (process.env.NODE_ENV === "development") {
        console.error("HTTP error:", err);
      }

      let status = err?.response?.status;

      // We assume that redirect status will be used only for redirecting user to the Home page,
      // so there is no need to check other fields from the response at the moment.
      if ([302].includes(status)) {
        store.dispatch("forcePageReload");
        // We don't return Promise from dispatch call because we don't want to give back
        // control to the original caller. Page will be refreshed anyway, so it's OK.
        return;
      }

      if (handle4xx && [401 /*, 403*/].includes(status)) {
        return store.dispatch("silentLogout");
      }

      if (status === 423) {
        return store
          .dispatch("removeCurrentAccount")
          .then(() => store.dispatch("forceSwitchingCurrentAccount"));
      }

      if (status === 403) {
        return store.dispatch("operationForbidden");
      }

      if (status == null || status === 500) {
        // Displaying error popup
      }

      if (status === 204) {
        return Promise.resolve({});
      }

      // We don't need to show an alert on a 404 error
      const excludeStatus = [404];

      if (status >= 400 && status < 500 && !excludeStatus.includes(status)) {
        const message = err?.response?.data?.error;
        // We don't return Promise from dispatch call because we don't want to give back
        // control to the original caller. We want to show alert on the page
        store.dispatch("showAlert", { text: message, type: ALERT_TYPE.error });
      }

      throw err;
    });
}

request.generateFullUrl = url => API_URL + url;
request.get = (url, options = {}) =>
  request(url, {}, { ...options, method: "get" });
request.post = (url, data, options = {}) =>
  request(url, data, { ...options, method: "post" });
request.patch = (url, data, options = {}) =>
  request(url, data, { ...options, method: "patch" });
request.put = (url, data, options = {}) =>
  request(url, data, { ...options, method: "put" });
request.delete = (url, data, options = {}) =>
  request(url, data, { ...options, method: "delete" });
