import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "@/pages/dashboard/Dashboard.vue";
import Login from "@/pages/login/Login.vue";
import Signup from "@/pages/signup/Signup.vue";
import Onboarding from "@/pages/onboarding/Onboarding.vue";
import InvitationCheck from "@/pages/onboarding/InvitationCheck.vue";
import ForgotPassword from "@/pages/forgotPassword/ForgotPassword.vue";
import ResetPassword from "@/pages/resetPassword/ResetPassword.vue";
import NotFound from "@/pages/NotFound.vue";
import UsersManagement from "@/pages/settings/UsersManagement.vue";
import AccountManagement from "@/pages/settings/AccountManagement.vue";
import AssetLabels from "@/pages/assetLabels/AssetLabels.vue";
import CompositionMetadata from "@/pages/compositions/CompositionMetadata.vue";
import CompositionOwnership from "@/pages/compositions/CompositionOwnership.vue";
import PotentialRelationships from "@/pages/compositions/PotentialRelationships.vue";
import SoundRecordings from "@/pages/compositions/SoundRecordings.vue";
import GlobalPotentialRelationship from "@/pages/recordings/GlobalPotentialRelationship.vue";
import CompositionsImport from "@/pages/compositions/CompositionsImport.vue";
import SoundRecordingsImport from "@/pages/recordings/SoundRecordingsImport.vue";
import AccountSoundRecordings from "@/pages/recordings/SoundRecordings.vue";
import Accounts from "@/pages/supervisor/Accounts.vue";
import Analytics from "@/pages/supervisor/Analytics.vue";
import Compositions from "@/pages/compositions/Compositions.vue";
import RecordingsAnalysis from "@/pages/providersData/RecordingsAnalysis.vue";
import SoundRecordingOwnershipCandidates from "@/pages/ownershipCandidates/SoundRecordingOwnershipCandidates.vue";
import Limits from "@/pages/supervisor/Limits.vue";
import PotentialRelationshipsProcessing from "@/pages/supervisor/PotentialRelationshipsProcessing.vue";
import AssetsConflictsHistory from "@/pages/ownershipConflicts/history/AssetsConflictsHistory.vue";
import SingleAssetConflictsHistory from "@/pages/ownershipConflicts/history/SingleAssetConflictsHistory.vue";
import CompositionConflictsSummary from "@/pages/ownershipConflicts/compositionsSummary/CompositionConflictsSummary.vue";
import ConflictsAnalytics from "@/pages/ownershipConflicts/analytics/ConflictsAnalytics.vue";

import AuthGuard from "./guards/auth-guard";
import AdminGuard from "./guards/admin-guard";
import SupervisorGuard from "./guards/supervisor-guard";
import PublicPageGuard from "./guards/public-page-guard";
import HomePageGuard from "./guards/home-page-guard";
import AccountAccessGuard from "./guards/account-access-guard";
import multiguard from "vue-router-multiguard";

import { CHARTLINE_GROUPS, RECORDING_STATUS } from "@/components/enums";

Vue.use(VueRouter);

const PATTERNS = {
  uuid:
    "\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b",
  integer: "\\b[0-9]+\\b"
};

const routes = [
  // Supervisor's section
  {
    name: "Accounts",
    path: `/:accountId(${PATTERNS.uuid})/accounts`,
    component: Accounts,
    beforeEnter: multiguard([AuthGuard, SupervisorGuard])
  },
  {
    name: "AnalyticsGeneral",
    path: `/:accountId(${PATTERNS.uuid})/analytics/general`,
    component: Analytics,
    beforeEnter: multiguard([AuthGuard, SupervisorGuard]),
    meta: {
      section: CHARTLINE_GROUPS.general,
      pageTitle: "General"
    }
  },
  {
    name: "AnalyticsPotentialRelationships",
    path: `/:accountId(${PATTERNS.uuid})/analytics/recordings`,
    component: Analytics,
    beforeEnter: multiguard([AuthGuard, SupervisorGuard]),
    meta: {
      section: CHARTLINE_GROUPS.recordings,
      pageTitle: "Potential Relationships"
    }
  },
  {
    name: "AnalyticsPotentialRelationshipsProcessing",
    path: `/:accountId(${PATTERNS.uuid})/analytics/recordings-processing`,
    component: PotentialRelationshipsProcessing,
    beforeEnter: multiguard([AuthGuard, SupervisorGuard])
  },
  {
    name: "AnalyticsProvidersDataAnalysis",
    path: `/:accountId(${PATTERNS.uuid})/analytics/recordings-analysis`,
    component: Analytics,
    beforeEnter: multiguard([AuthGuard, SupervisorGuard]),
    meta: {
      section: CHARTLINE_GROUPS.recordings_analysis,
      pageTitle: "Providers Data Analysis"
    }
  },
  {
    name: "Limits",
    path: `/:accountId(${PATTERNS.uuid})/limits`,
    component: Limits,
    beforeEnter: multiguard([AuthGuard, SupervisorGuard])
  },
  // Regular app's section
  {
    name: "Login",
    path: "/login",
    component: Login,
    beforeEnter: PublicPageGuard,
    meta: {
      // It's not default layout, that why we need to specify it
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Signup",
    path: "/signup",
    component: Signup,
    beforeEnter: PublicPageGuard,
    meta: {
      // It's not default layout, that why we need to specify it
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "InvitationCheck",
    path: "/invite/:inviteCode",
    component: InvitationCheck,
    beforeEnter: PublicPageGuard,
    meta: {
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Onboarding",
    path: "/onboarding",
    component: Onboarding,
    beforeEnter: PublicPageGuard,
    meta: {
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: ForgotPassword,
    beforeEnter: PublicPageGuard,
    meta: {
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Reset Password",
    path: "/forgot-password/reset/:token",
    component: ResetPassword,
    beforeEnter: PublicPageGuard,
    meta: {
      layout: "public-app-layout",
      public: true
    }
  },
  {
    name: "Home",
    path: "/",
    redirect: HomePageGuard
  },
  {
    name: "Dashboard",
    path: `/:accountId(${PATTERNS.uuid})`,
    component: Dashboard,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true
    }
  },
  {
    name: "UsersManagement",
    path: `/:accountId(${PATTERNS.uuid})/users`,
    component: UsersManagement,
    beforeEnter: multiguard([AuthGuard, AdminGuard, AccountAccessGuard])
  },
  {
    name: "AccountManagement",
    path: `/:accountId(${PATTERNS.uuid})/account`,
    component: AccountManagement,
    beforeEnter: multiguard([AuthGuard, AdminGuard, AccountAccessGuard])
  },
  {
    name: "PendingProvidersDataAnalysis",
    path: `/:accountId(${PATTERNS.uuid})/recordings-analysis/pending/:recordingId(${PATTERNS.integer})?`,
    component: RecordingsAnalysis,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true,
      status: RECORDING_STATUS.pending
    }
  },
  {
    name: "ApprovedProvidersDataAnalysis",
    path: `/:accountId(${PATTERNS.uuid})/recordings-analysis/approved/:recordingId(${PATTERNS.integer})?`,
    component: RecordingsAnalysis,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true,
      status: RECORDING_STATUS.approved,
      showDownloadMetadataHeader: true
    }
  },
  {
    name: "SkippedProvidersDataAnalysis",
    path: `/:accountId(${PATTERNS.uuid})/recordings-analysis/skipped/:recordingId(${PATTERNS.integer})?`,
    component: RecordingsAnalysis,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true,
      status: RECORDING_STATUS.skipped
    }
  },
  {
    name: "Compositions",
    path: `/:accountId(${PATTERNS.uuid})/compositions`,
    component: Compositions,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true
    }
  },
  {
    name: "CompositionMetadata",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})`,
    component: CompositionMetadata,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard])
  },
  {
    name: "CompositionOwnership",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})/ownership`,
    component: CompositionOwnership,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard])
  },
  {
    name: "ApprovedRelationships",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})/approved/:recordingId(${PATTERNS.integer})?`,
    component: PotentialRelationships,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.approved
    }
  },
  {
    name: "PendingRelationships",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})/pending/:recordingId(${PATTERNS.integer})?`,
    component: PotentialRelationships,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.pending
    }
  },
  {
    name: "SkippedRelationships",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})/skipped/:recordingId(${PATTERNS.integer})?`,
    component: PotentialRelationships,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.skipped
    }
  },
  {
    name: "DeclinedRelationships",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})/declined/:recordingId(${PATTERNS.integer})?`,
    component: PotentialRelationships,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.declined
    }
  },
  {
    name: "SoundRecordings",
    path: `/:accountId(${PATTERNS.uuid})/compositions/:compositionId(${PATTERNS.uuid})/recordings`,
    component: SoundRecordings,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard])
  },
  {
    name: "Labels",
    path: `/:accountId(${PATTERNS.uuid})/labels`,
    component: AssetLabels
  },
  {
    name: "GlobalApprovedRelationships",
    path: `/:accountId(${PATTERNS.uuid})/recordings/approved/:recordingId(${PATTERNS.integer})?`,
    component: GlobalPotentialRelationship,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.approved,
      showDownloadMetadataHeader: true
    }
  },
  {
    name: "GlobalPendingRelationships",
    path: `/:accountId(${PATTERNS.uuid})/recordings/pending/:recordingId(${PATTERNS.integer})?`,
    component: GlobalPotentialRelationship,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.pending
    }
  },
  {
    name: "GlobalSkippedRelationships",
    path: `/:accountId(${PATTERNS.uuid})/recordings/skipped/:recordingId(${PATTERNS.integer})?`,
    component: GlobalPotentialRelationship,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.skipped
    }
  },
  {
    name: "GlobalDeclinedRelationships",
    path: `/:accountId(${PATTERNS.uuid})/recordings/declined/:recordingId(${PATTERNS.integer})?`,
    component: GlobalPotentialRelationship,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.declined
    }
  },
  {
    name: "ApprovedOwnershipCandidates",
    path: `/:accountId(${PATTERNS.uuid})/ownership_candidates/approved/:recordingId(${PATTERNS.integer})?`,
    component: SoundRecordingOwnershipCandidates,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.approved,
      showDownloadMetadataHeader: true
    }
  },
  {
    name: "PendingOwnershipCandidates",
    path: `/:accountId(${PATTERNS.uuid})/ownership_candidates/pending/:recordingId(${PATTERNS.integer})?`,
    component: SoundRecordingOwnershipCandidates,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.pending,
      showDownloadMetadataHeader: true
    }
  },
  {
    name: "SkippedOwnershipCandidates",
    path: `/:accountId(${PATTERNS.uuid})/ownership_candidates/skipped/:recordingId(${PATTERNS.integer})?`,
    component: SoundRecordingOwnershipCandidates,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.skipped
    }
  },
  {
    name: "DeclinedOwnershipCandidates",
    path: `/:accountId(${PATTERNS.uuid})/ownership_candidates/declined/:recordingId(${PATTERNS.integer})?`,
    component: SoundRecordingOwnershipCandidates,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      status: RECORDING_STATUS.declined
    }
  },
  {
    name: "AccountSoundRecordings",
    path: `/:accountId(${PATTERNS.uuid})/recordings`,
    component: AccountSoundRecordings,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true
    }
  },
  {
    name: "CompositionsImport",
    path: `/:accountId(${PATTERNS.uuid})/import`,
    component: CompositionsImport
  },
  {
    name: "SoundRecordingsImport",
    path: `/:accountId(${PATTERNS.uuid})/import`,
    component: SoundRecordingsImport
  },
  {
    name: "AssetsConflictsHistory",
    path: `/:accountId(${PATTERNS.uuid})/ownership-conflicts/history`,
    component: AssetsConflictsHistory,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard]),
    meta: {
      canBeUsedAsBackButtonTarget: true
    }
  },
  {
    name: "SingleAssetConflictsHistory",
    path: `/:accountId(${PATTERNS.uuid})/ownership-conflicts/history/:recordingId(${PATTERNS.uuid})`,
    component: SingleAssetConflictsHistory,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard])
  },
  {
    name: "CompositionConflictsSummary",
    path: `/:accountId(${PATTERNS.uuid})/ownership-conflicts/compositions`,
    component: CompositionConflictsSummary,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard])
  },
  {
    name: "ConflictsAnalytics",
    path: `/:accountId(${PATTERNS.uuid})/ownership-conflicts/analytics`,
    component: ConflictsAnalytics,
    beforeEnter: multiguard([AuthGuard, AccountAccessGuard])
  },
  {
    name: "404",
    path: "/404",
    component: NotFound,
    meta: {
      layout: "empty-layout",
      public: true
    }
  },
  {
    name: "Unknown",
    path: "*",
    component: NotFound,
    meta: {
      layout: "empty-layout",
      public: true
    }
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: "history"
});

export default router;
