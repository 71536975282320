<template>
  <div class="text-center progress-block">
    <v-progress-circular color="primary" indeterminate></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "InvitationCheck",
  components: {},
  created() {
    this.checkInvitationCode(this.$route.params.inviteCode).catch(error => {
      console.error("Wasn't able to verify invite code: ", error);
      this.$router.push("/login");
    });
  },
  methods: {
    ...mapActions(["checkInvitationCode"])
  },
  metaInfo: {
    title: "InvitationCheck"
  }
};
</script>

<style lang="sass" scoped>
.progress-block
  min-height: 50px
  padding-top: 10px
</style>
