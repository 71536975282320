<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="selectedValue"
    transition="scroll-y-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <text-field
        v-model="formattedDate"
        :label="label"
        :validation-rules="validationRules"
        :disabled="disabled"
        autocomplete="off"
        readonly
        clearable
        v-bind="attrs"
        v-on="on"
        @click:clear="clearDate"
      >
      </text-field>
    </template>
    <v-date-picker
      v-if="rangeMode"
      v-model="range"
      range
      elevation="0"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(range)">
        OK
      </v-btn>
    </v-date-picker>
    <v-date-picker v-else v-model="date" elevation="0" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import TextField from "@/components/forms/inputs/TextField";
import { DATE_FORMAT } from "@/components/constants";
import { formatDate } from "@/components/helpers";

export default {
  name: "Datepicker",
  components: { TextField },

  data: () => ({
    menu: false,
    date: null,
    range: null,
    formattedDate: null
  }),

  props: {
    value: {
      type: [String, Array],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    validationRules: {
      type: [String, Object]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: DATE_FORMAT
    },
    rangeMode: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selectedValue: {
      get() {
        return this.rangeMode ? this.range : this.date;
      },
      set(newValue) {
        if (this.rangeMode) {
          this.range = newValue;
        } else {
          this.date = newValue;
        }
      }
    }
  },

  watch: {
    selectedValue(newVal) {
      this.$emit("input", newVal);

      if (this.rangeMode && newVal) {
        const [startDate, endDate] = newVal;
        this.formattedDate = `${formatDate(startDate, this.format)} - ${
          endDate ? formatDate(endDate, this.format) : "..."
        }`;
      } else if (!this.rangeMode && newVal) {
        this.formattedDate = formatDate(newVal, this.format);
      } else {
        this.formattedDate = null;
      }
    }
  },

  methods: {
    clearDate() {
      if (this.rangeMode) {
        this.range = null;
      } else {
        this.date = null;
      }

      this.formattedDate = null;
    }
  },

  created() {
    if (this.rangeMode) {
      this.range = Array.isArray(this.value) ? this.value : null;
    } else {
      this.date = this.value;
    }
  }
};
</script>

<style scoped></style>
