import { PageMenu } from "@/mixins/page-menu";
import { MENU_GROUP_TITLE_ACTIVE_COLOR } from "@/components/constants";

export const SupervisorPageMenu = Object.assign({}, PageMenu, {
  computed: {
    getMenuItems: function() {
      return [
        {
          title: "Accounts",
          icon: "mdi-account-box-multiple",
          routerName: "Accounts",
          visible: true
        },
        {
          title: "Analytics",
          icon: "mdi-google-analytics",
          routeGroupRegex: /\/[a-z0-9-]*\/analytics\//,
          visible: true,
          color: MENU_GROUP_TITLE_ACTIVE_COLOR,
          nestedItems: [
            {
              title: "General",
              icon: "mdi-home-analytics",
              routerName: "AnalyticsGeneral",
              visible: true,
              highlightDuringPartialRouteMatch: true
            },
            {
              title: "PR Search",
              icon: "mdi-link",
              routerName: "AnalyticsPotentialRelationships",
              visible: true,
              highlightDuringPartialRouteMatch: true
            },
            {
              title: "PR Processing",
              icon: "mdi-table-clock",
              routerName: "AnalyticsPotentialRelationshipsProcessing",
              visible: true
            },
            {
              title: "Providers Data Analysis",
              icon: "mdi-table",
              routerName: "AnalyticsProvidersDataAnalysis",
              visible: true,
              highlightDuringPartialRouteMatch: true
            }
          ]
        },
        {
          title: "Limits",
          icon: "mdi-car-speed-limiter",
          routerName: "Limits",
          visible: true
        }
      ];
    },

    hasBackButton: function() {
      return true;
    },

    getPageDetailsSectionComponent: function() {
      return null;
    }
  }
});
