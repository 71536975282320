<template>
  <v-card
    elevation="3"
    max-width="344"
    height="150"
    min-height="150"
    style="overflow: hidden"
  >
    <v-skeleton-loader
      class="statistics-item-loader"
      type="card"
      v-if="loading"
    />

    <template v-else>
      <v-card-title class="text-h3 text-truncate pb-1">
        {{ count | abbreviatedNumber }}
      </v-card-title>
      <v-card-text class="subtext" v-if="subtext">{{ subtext }}</v-card-text>

      <v-spacer></v-spacer>

      <v-card-subtitle
        align="end"
        class="text-xl-subtitle-1 text-lg-subtitle-2 text-xl-subtitle-2 text-sm-caption font-weight-bold"
        style="position: absolute; bottom: 0; right: 0; line-height: 1.375rem;"
      >
        {{ title }}
      </v-card-subtitle>
    </template>
  </v-card>
</template>

<script>
import { abbreviatedNumber } from "@/filters/numbers";

export default {
  name: "StatisticsItem",

  filters: { abbreviatedNumber },

  props: {
    title: {
      type: String
    },
    subtext: {
      type: String
    },
    count: {
      type: Number
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass">

.statistics-item-loader
  max-height: 150px

  .v-skeleton-loader__card
    height: 150px
    max-height: 150px

  .v-skeleton-loader__image
    height: 105px
    max-height: 105px

  .v-skeleton-loader__card-heading .v-skeleton-loader__heading
    margin: 10px 10px
    float: right
</style>

<style lang="sass" scoped>
.subtext
  font-size: 14px
  font-weight: bold
  color: green !important
</style>
