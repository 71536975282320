<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Users
        </v-card-title>
      </v-col>
      <v-col cols="auto" class="mr-auto align-self-center">
        <invite-user :callback="this.onUserInvited"></invite-user>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          hide-details
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="results"
        :loading="loading"
        :search="search"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item.role="{ item }">
          <v-select
            :items="accessSelectionOptions"
            :value="item.role"
            class="pt-1 mb-n2 text-body-2"
            dense
            item-text="title"
            item-value="value"
            single-line
            @input="onUserAccessChanged(item.id, $event, item.role)"
          ></v-select>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ACCESS_SELECTION_OPTIONS } from "@/components/enums";
import { REMOVE_ACCESS_VALUE } from "@/components/constants";
import InviteUser from "@/pages/settings/components/InviteUser";
import { SettingsPageMenu } from "@/mixins/settings-page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";

export default {
  name: "UsersManagement",
  components: { InviteUser },
  mixins: [SettingsPageMenu, GridUtils, DataTableFooterProps],

  data() {
    return {
      accessSelectionOptions: ACCESS_SELECTION_OPTIONS.concat([
        { divider: true },
        { title: "Remove access", value: REMOVE_ACCESS_VALUE }
      ]),
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Email",
          sortable: false,
          value: "email",
          width: "40%"
        },
        {
          text: "Access",
          sortable: false,
          value: "role",
          width: "200px"
        }
      ]
    };
  },

  methods: {
    ...mapActions(["getUsers", "updateUser", "removeUserFromAccount"]),

    getResultsActionName() {
      return "getUsers";
    },

    onUserAccessChanged(userId, access, oldAccess) {
      if (access === REMOVE_ACCESS_VALUE) {
        this._removeUserAccess(userId, oldAccess);
      } else {
        this._updateUserRole(userId, access, oldAccess);
      }
    },

    _removeUserAccess(userId, oldAccess) {
      this.$confirm(
        "This will revoke an access from the specified user to this account. Continue?",
        { buttonTrueText: "Continue", buttonFalseText: "Cancel" }
      ).then(result => {
        if (result) {
          this.loading = true;
          this.removeUserFromAccount(userId)
            .then(() => {
              // Removing a user record from a table
              this.results.splice(
                this.results.findIndex(user => user.id === userId),
                1
              );
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          // A little hack to reset role to previous value
          let user = this.results.find(user => user.id === userId);
          user.role = REMOVE_ACCESS_VALUE;
          this.$nextTick().then(() => (user.role = oldAccess));
        }
      });
    },

    _updateUserRole(userId, access, oldAccess) {
      let userData = this.results.find(user => {
        return user.id === userId;
      });

      this.loading = true;
      // Updating manually because we use "input" event handle and no v-innerValue binding
      // (to be able to get old "role" value)
      userData.role = access;

      this.updateUser(userData)
        .catch(() => {
          console.error("Wasn't able to change user's role: ", userData.id);
          userData.role = oldAccess;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onUserInvited(userData) {
      this.results.unshift(userData);
    }
  },

  metaInfo: {
    title: "Users"
  }
};
</script>
