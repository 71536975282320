import store from "@/store";
import {
  LOGIN_PAGE_PATH,
  CONFLICTS_HISTORY_PAGE_PATH
} from "@/components/constants"; // We need to import store like this here

export default () => {
  // Go to Dashboard or Login page from root path
  const accountId = store.getters.userCurrentAccountId;

  if (store.getters.isAuthorized) {
    if (store.getters.isConflictsManager) {
      return `/${accountId}${CONFLICTS_HISTORY_PAGE_PATH}`;
    } else {
      return `/${accountId}`;
    }
  } else {
    return LOGIN_PAGE_PATH;
  }
};
