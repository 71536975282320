<template>
  <v-flex xs12>
    <ValidationProvider
      ref="provider"
      :label="$attrs.label"
      :mode="$attrs.mode"
      :name="$attrs.name"
      :rules="validationRules"
      v-on="$listeners"
    >
      <v-autocomplete
        multiple
        v-model="model"
        chips
        deletable-chips
        return-object
        hide-selected
        append-icon=""
        :hide-no-data="hideNoData"
        :loading="isLoading"
        :search-input.sync="search"
        :items="innerItems"
        :item-text="keyAttr"
        :item-value="idAttr"
        :menu-props="{ closeOnContentClick: true }"
        v-bind="$attrs"
        v-on="$listeners"
        @change="onChange"
        slot-scope="{ errors }"
        :error-messages="errors"
        clearable
        outlined
      >
        <template v-slot:no-data v-if="createCallback && search">
          <v-list-item @click="createItem(search)">
            <v-list-item-title> {{ search }} (create new)</v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, parent }">
          <v-chip close @click:close="parent.selectItem(item)">
            <span class="text-truncate">{{ getItemText(item) }}</span>
          </v-chip>
        </template>
      </v-autocomplete>
    </ValidationProvider>
  </v-flex>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { FETCH_DATA_TIMEOUT } from "@/components/constants";

export default {
  name: "LabelsAutocomplete",

  components: {
    ValidationProvider
  },

  data() {
    return {
      select: [],
      search: null,
      innerItems: [],
      isLoading: false,
      model: null,
      timeout: null,
      hideNoData: true,
      searchMinCharsCount: 2
    };
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    validationRules: {
      type: [String, Object]
    },
    keyAttr: {
      type: String,
      default: null
    },
    idAttr: {
      type: String,
      default: "id"
    },
    fetchCallback: {
      type: Function
    },
    createCallback: {
      type: Function
    }
  },

  watch: {
    select(newVal) {
      this.$emit("input", newVal);
    },

    items(newVal) {
      this.innerItems = newVal;
    },

    async search(newVal) {
      const validationResult = await this._validate(newVal);

      if (!validationResult.valid) {
        return;
      }

      if (!this.fetchCallback) {
        this.$emit("change", newVal);
        return;
      }

      if (!this.hideNoData) {
        this.hideNoData = true;
      }

      if (this.isLoading) {
        return;
      }

      if (!newVal || newVal.length < this.searchMinCharsCount) {
        return;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.fetchData, FETCH_DATA_TIMEOUT, newVal);
    },

    value(newVal) {
      this.innerItems = newVal;
      this.model = newVal;
    }
  },

  methods: {
    fetchData(query) {
      this.isLoading = true;

      this.fetchCallback({
        page: 1,
        size: 20,
        query: query
      })
        .then(data => {
          this.innerItems = this.innerItems.concat(data.data);
          this.hideNoData = false;
        })
        .finally(() => (this.isLoading = false));
    },

    onChange(value) {
      if (value) {
        // removing an empty value
        const lastValue = value[value.length - 1];

        if (!lastValue) {
          value.pop();
        }
      }

      this.search = null;
    },

    async _validate(value) {
      /**
       * Force combobox validation on typing.
       * Default validation works just on change.
       */
      if (!value) {
        // reset to a valid state
        this.$refs.provider.setErrors([]);
        return { valid: true };
      }

      // this works only for "multiple"
      if (this.$refs.provider.value) {
        this.$refs.provider.value.push(value);
      } else {
        this.$refs.provider.value = [value];
      }

      const result = await this.$refs.provider.validate();

      if (this.$refs.provider.value) {
        // returning to a previous state
        this.$refs.provider.value.pop();
      }

      if (result.errors) {
        this.$refs.provider.setErrors(result.errors);
      }

      return result;
    },

    _modifyModel(newLabel) {
      // Replacing the plain-text value from the user with an object that was created by the server.
      let index = this.model.indexOf(newLabel.name);
      if (index !== -1) {
        this.model.splice(index, 1);
        this.model.push(newLabel);
      }
      this.model.push(newLabel);
    },

    createItem(item) {
      if (item && this.createCallback) {
        this.isLoading = true;

        this.createCallback({
          name: item
        })
          .then(({ data }) => {
            console.log(this.model);
            this._modifyModel(data);
          })
          .finally(() => {
            this.isLoading = false;
            this.search = null;
          });
      }
    },

    getItemText(item) {
      return typeof item === "object" ? item[this.keyAttr] : item;
    }
  }
};
</script>

<style lang="sass" scoped>
.v-chip.v-size--default
  max-width: 50%
</style>
