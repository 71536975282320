import { ACCOUNT_TYPE } from "@/components/enums";
import { mapGetters } from "vuex";

export const AccountMixin = {
  computed: {
    ...mapGetters(["userCurrentAccount"]),

    isCurrentAccountHasPublishingType() {
      return this.userCurrentAccount?.type === ACCOUNT_TYPE.publishing;
    }
  }
};
