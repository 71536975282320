import { request } from "@/services/api";

export default {
  name: "users",

  actions: {
    getUsers({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/users?page=${params.page}&size=${params.size}&query=${params.query}`
        )
        .then(data => {
          return {
            data: data?.data,
            meta: data?.meta
          };
        });
    },

    updateUser({ getters, rootState }, userData) {
      const accountId = getters.userCurrentAccountId,
        userId = userData.id;

      return request.put(
        `${rootState.apiPrefix}/accounts/${accountId}/users/${userId}`,
        userData
      );
    },

    removeUserFromAccount({ getters, rootState }, userId) {
      const accountId = getters.userCurrentAccountId;

      return request.delete(
        `${rootState.apiPrefix}/accounts/${accountId}/users/${userId}`
      );
    },

    inviteUser({ getters, rootState }, userData) {
      const accountId = getters.userCurrentAccountId;

      return request
        .post(`${rootState.apiPrefix}/accounts/${accountId}/users`, userData)
        .then(responseBody => responseBody?.data);
    }
  },

  mutations: {}
};
