import { request } from "@/services/api";

export default {
  name: "reports",

  actions: {
    getReports({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/reports?page=${params.page}&size=${params.size}`
      );
    },

    createReport({ getters, rootState }, reportData) {
      const accountId = getters.userCurrentAccountId;

      return request.post(
        `${rootState.apiPrefix}/accounts/${accountId}/reports`,
        reportData
      );
    },

    getReportDownloadUrl({ getters, rootState }, reportId) {
      const accountId = getters.userCurrentAccountId;

      return request.generateFullUrl(
        `${rootState.apiPrefix}/accounts/${accountId}/reports/${reportId}/download`
      );
    }
  }
};
