<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="460"
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :absolute="absolute"
        :fixed="fixed"
        right
        color="primary"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
        >{{ label }}</v-btn
      >
    </template>
    <v-card>
      <v-radio-group
        hide-details
        column
        @change="downloadType = $event"
        class="pa-5 pb-4 mt-0"
        :value="downloadType"
        v-if="!hideOptions"
      >
        <v-radio value="all" class="mb-1">
          <span slot="label" class="text-body-1">Download all records</span>
        </v-radio>
        <v-radio
          value="active_records"
          :disabled="!downloadLimit || downloadLimit === '0'"
        >
          <span slot="label" class="text-body-1">{{
            `Download ${downloadLimit} record(s)`
          }}</span>
        </v-radio>
      </v-radio-group>
      <v-card-text class="pb-0 text-body-1" :class="hideOptions ? 'pt-4' : ''">
        {{ isActiveRecordsDownloadTypeSelected ? dialogText : downloadAllText }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn color="primary" text @click="onDownloadMetadataClick">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DOWNLOAD_METADATA_TYPES } from "@/components/enums";
import { DownloadWindow } from "@/mixins/download-window";

export default {
  name: "DownloadMetadata",

  mixins: [DownloadWindow],

  data: () => ({
    loading: false,
    dialog: false,
    downloadTypes: DOWNLOAD_METADATA_TYPES,
    downloadTypeSelected: undefined
  }),

  props: {
    url: {
      type: String,
      required: true
    },

    dialogText: {
      type: String,
      required: true
    },

    downloadAllText: {
      type: String,
      required: true
    },

    downloadLimit: {
      type: String, // The limit can be represented as a string: 10K, 1M etc.
      required: true
    },

    label: {
      type: String,
      default: "Download Metadata"
    },

    absolute: {
      type: Boolean,
      default: true
    },

    fixed: {
      type: Boolean,
      default: true
    },

    basicRoute: {
      type: String,
      required: true
    },

    hideOptions: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async onDownloadMetadataClick() {
      this.dialog = false;

      this.loading = true;

      this.openDownloadWindow(this.url + "/" + this.downloadType, () => {
        this.loading = false;
        if (this.downloadType === DOWNLOAD_METADATA_TYPES.active_records) {
          this.$router.push(this.basicRoute);
          this.$emit("refresh-data");
        }
      });
    }
  },
  computed: {
    isActiveRecordsDownloadTypeSelected() {
      return this.downloadType === DOWNLOAD_METADATA_TYPES.active_records;
    },
    downloadType: {
      get() {
        if (this.downloadTypeSelected) {
          return this.downloadTypeSelected;
        } else {
          return this.downloadLimit && this.downloadLimit !== "0"
            ? DOWNLOAD_METADATA_TYPES.active_records
            : DOWNLOAD_METADATA_TYPES.all;
        }
      },
      set(val) {
        this.downloadTypeSelected = val;
      }
    }
  }
};
</script>
