import { formatDate } from "@/components/helpers";
import { DATE_FORMAT, EMPTY_VALUE } from "@/components/constants";

function date(value, format = DATE_FORMAT) {
  if (value) {
    return formatDate(value, format);
  }

  return EMPTY_VALUE;
}

export { date };
