var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card-title',{staticClass:"pl-1 justify-space-between"},[_vm._v(" Compositions "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadCompositions}},[_vm._v("Download")])],1)],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"selectable":"","headers":_vm.headers,"items":_vm.results,"options":_vm.options,"server-items-length":_vm.totalResultsCount,"loading":_vm.loading,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:item.id,staticClass:"table-row"},[_c('td',{staticClass:"text-start clickable",on:{"click":function($event){return _vm.onRowClicked(item)}}},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","right":"","offset-y":"","nudge-right":"60","max-width":"410","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-container',_vm._g(_vm._b({class:'px-0 py-1 ' + [hover ? 'primary--text' : '']},'v-container',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(item.meta.title)+" ")]),_c('br'),_vm._l((_vm.showLabels(item)),function(label,index){return [_c('v-chip',{key:index,staticClass:"mr-2 px-1 text-caption grey--text text--darken-2 font-weight-medium",attrs:{"label":"","link":"","x-small":""}},[_vm._v(_vm._s(label.name)+" ")])]}),_c('span',{staticClass:"caption grey--text text--darken-4"},[_vm._v(_vm._s(_vm.getRemainder(item)))])],2)]}}],null,true)})]}}],null,true)},[_c('composition-popup-detail',{attrs:{"item":item}})],1)],1),_c('td',{staticClass:"text-end table-secondary-column"},[_c('span',[_vm._v(_vm._s(_vm._f("default_value")(item.youtube_asset_id)))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("default_value")(item.meta.custom_id)))])]),_c('td',{staticClass:"text-end table-secondary-column"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(item.meta.created_at)))]),_c('br'),_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm._f("humanizeTime")(item.meta.created_at)))])]),_c('td',{staticClass:"text-end table-secondary-column"},[_c('span',[_vm._v(_vm._s(_vm._f("date")(item.meta.updated_at)))]),_c('br'),_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm._f("humanizeTime")(item.meta.updated_at)))])]),_c('td',{staticClass:"text-end table-secondary-column"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"data-table-actions-menu pa-0",attrs:{"nav":"","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.removeCompositionClick(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s("Delete")}})],1)],1)],1)],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }