<template>
  <v-container class="text--primary px-0 pt-0 pb-4 overflow-y-auto">
    <v-container class="pa-0">
      <v-card-text class="pb-0 text-truncate">
        {{ sourceRecording.metadata.title }}
      </v-card-text>
      <v-card-subtitle class="text-caption py-0">
        <v-icon small color="grey darken-1">mdi-music-note</v-icon>
        Sound recording (Source) -
        {{ sourceRecording.metadata.created_at | date(DATETIME_FORMAT) }}
      </v-card-subtitle>
    </v-container>

    <v-container class="pa-0">
      <v-card-subtitle class="text-caption pb-0">Metadata</v-card-subtitle>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card-text class="py-0">YouTube Asset ID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            sourceRecording.youtube_asset_id | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Custom ID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            sourceRecording.metadata.custom_id | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">ISRC</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            sourceRecording.metadata.isrc | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">GRID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            sourceRecording.metadata.grid | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Record label</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0 text-truncate">{{
            sourceRecording.metadata.record_label | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Artists</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            sourceRecording.metadata.artists
              ? sourceRecording.metadata.artists.join(",")
              : ""
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">UPC</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            {{ sourceRecording.metadata.upc | default_value }}
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Album</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            {{ sourceRecording.metadata.album | default_value }}
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider class="grey lighten-2 mx-4 mt-4"></v-divider>
    </v-container>

    <v-container class="pa-0">
      <div>
        <v-card-text
          class="pb-0 text-truncate"
          style="display: inline-block; max-width: calc(100% - 190px); vertical-align: bottom"
        >
          <highlighted-label
            v-if="candidateMetadata.title"
            :text="candidateMetadata.title"
            :highlighted-part="sourceRecording.metadata.title"
          >
          </highlighted-label>
        </v-card-text>
        <sound-recording-has-active-references-marker
          class="v-card__text pb-0 pl-0"
          :value="candidateMetadata.has_active_references"
        />
      </div>
      <v-card-subtitle class="text-caption py-0">
        <v-icon small color="grey darken-1">mdi-music-note</v-icon>
        Sound recording (Candidate) -
        {{ candidateMetadata.created_at | date(DATETIME_FORMAT) }}
      </v-card-subtitle>
    </v-container>

    <v-container class="pa-0">
      <v-card-subtitle class="text-caption pb-0">Metadata</v-card-subtitle>

      <v-row no-gutters>
        <v-col cols="4">
          <v-card-text class="py-0">YouTube Asset ID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              :text="candidate.youtube_asset_id"
              :highlighted-part="sourceRecording.youtube_asset_id"
            />
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Custom ID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              v-if="candidateMetadata.custom_id"
              :text="candidateMetadata.custom_id"
              :highlighted-part="sourceRecording.metadata.custom_id"
            />
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">ISRC</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              v-if="candidateMetadata.isrc"
              :text="candidateMetadata.isrc"
              :highlighted-part="sourceRecording.metadata.isrc"
            />
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">GRID</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              v-if="candidateMetadata.grid"
              :text="candidateMetadata.grid"
              :highlighted-part="sourceRecording.metadata.grid"
            />
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Record label</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              v-if="candidateMetadata.record_label"
              :text="candidateMetadata.record_label"
              :highlighted-part="sourceRecording.metadata.record_label"
            />
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Artists</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-labels
              :items="candidateMetadata.artists"
              :highlighted-parts="sourceRecording.metadata.artists"
            >
            </highlighted-labels>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">UPC</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              v-if="candidateMetadata.upc"
              :text="candidateMetadata.upc"
              :highlighted-part="sourceRecording.metadata.upc"
            />
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Album</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <highlighted-label
              v-if="candidateMetadata.album"
              :text="candidateMetadata.album"
              :highlighted-part="sourceRecording.metadata.album"
            />
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Owner</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">
            <template v-if="candidate.owners && candidate.owners.length">{{
              candidate.owners.join(", ")
            }}</template>
            <template v-else>—</template>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { DATETIME_FORMAT } from "@/components/constants";
import HighlightedLabels from "@/components/forms/HighlightedLabels";
import HighlightedLabel from "@/components/forms/HighlightedLabel";
import SoundRecordingHasActiveReferencesMarker from "@/pages/recordings/components/SoundRecordingHasActiveReferencesMarker";

export default {
  name: "SoundRecordingOwnershipCandidateCard",

  components: {
    HighlightedLabels,
    HighlightedLabel,
    SoundRecordingHasActiveReferencesMarker
  },

  props: {
    recording: {
      type: Object
    },
    titleMaxWidth: {
      type: Number,
      default: 600
    }
  },

  computed: {
    sourceRecording() {
      return this.recording.source;
    },

    candidate() {
      return this.recording.candidate;
    },

    candidateMetadata() {
      return this.candidate?.metadata ?? {};
    }
  },

  data: () => ({
    DATETIME_FORMAT
  })
};
</script>
