<template>
  <div>
    <v-divider v-if="position > 0" class="mb-4"></v-divider>

    <v-row>
      <v-col cols="3">
        <text-field
          v-model="innerValue.percent"
          label="Ownership %"
          outlined
          autocomplete="off"
          validation-rules="required|between:0.01,100"
          :error="!innerValue.percent"
        ></text-field>
      </v-col>

      <v-col cols="8">
        <v-radio-group
          v-model="innerValue.license_type"
          class="license-types-radio"
          mandatory
          row
          dense
        >
          <v-radio
            v-for="item in licenseTypeOptions"
            :key="item.value"
            :label="item.title"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="1" class="mt-3">
        <v-btn icon @click="onItemDelete(innerValue)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col class="py-0">
        <v-radio-group
          v-model="coverageTypeSwitcher"
          class="coverage-types-radio mt-0"
          mandatory
          dense
        >
          <v-radio
            v-for="item in coverageTypeOptions"
            :key="item.value"
            :label="item.title"
            :value="item.value"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row class="ml-5 mt-0" v-if="coverageTypeSwitcher === 'territories'">
      <v-radio-group
        v-model="innerValue.coverage_type"
        class="coverage-types-radio mt-0"
        mandatory
        row
        dense
      >
        <v-radio
          v-for="item in otherCoverageTypeOptions"
          :key="item.value"
          :label="item.title"
          :value="item.value"
        ></v-radio>
      </v-radio-group>

      <autocomplete
        label="Territories"
        :items="territories"
        v-model="innerValue.convertedTerritories"
        key-attr="1"
        id-attr="0"
        :validation-rules="{
          required: innerValue.coverage_type !== coverageTypeGlobal
        }"
        @change="onTerritoriesUpdate"
        @keydown="onKeyDown"
        @paste="onPaste"
        outlined
        multiple
        clearable
        style="max-width: 97%"
      >
        <template v-slot:append-outer v-if="innerValue.territories.length">
          <div class="v-input__icon">
            <v-btn icon @click="copySelected">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </template>
      </autocomplete>
    </v-row>
  </div>
</template>

<script>
import TextField from "@/components/forms/inputs/TextField";
import Autocomplete from "@/components/forms/inputs/Autocomplete";
import {
  OWNERSHIP_COVERAGE_TYPES,
  OWNERSHIP_LICENSE_TYPES_OPTIONS
} from "@/pages/compositions/components/enums";
import { TERRITORIES_COVERAGE_TYPE } from "@/components/constants";
import { clipboardCopy, clipboardPaste } from "@/components/helpers";

export default {
  name: "EditOwnershipItem",
  components: { TextField, Autocomplete },

  data: () => ({
    coverageTypeSwitcher: null,
    innerValue: {},
    coverageTypeGlobal: OWNERSHIP_COVERAGE_TYPES.global,
    coverageTypeOptions: [
      {
        title: "Global",
        value: OWNERSHIP_COVERAGE_TYPES.global
      },
      {
        title: "Specific territories",
        value: TERRITORIES_COVERAGE_TYPE
      }
    ],
    otherCoverageTypeOptions: [
      {
        title: "Own in selected territories",
        value: OWNERSHIP_COVERAGE_TYPES.include_territories
      },
      {
        title: "Own everywhere except selected territories",
        value: OWNERSHIP_COVERAGE_TYPES.exclude_territories
      }
    ],
    licenseTypeOptions: OWNERSHIP_LICENSE_TYPES_OPTIONS
  }),

  props: {
    value: Object,
    territories: Object,
    position: Number
  },

  mounted() {
    this.innerValue = this.value;

    if (this.innerValue.coverage_type !== OWNERSHIP_COVERAGE_TYPES.global) {
      this.coverageTypeSwitcher = TERRITORIES_COVERAGE_TYPE;
    }
  },

  watch: {
    coverageTypeSwitcher: function(value) {
      if (
        this.innerValue.coverage_type !== OWNERSHIP_COVERAGE_TYPES.global &&
        value === OWNERSHIP_COVERAGE_TYPES.global
      ) {
        this.innerValue.coverage_type = OWNERSHIP_COVERAGE_TYPES.global;
      }
    },

    value: function(value) {
      this.innerValue = this.value;
      this.$emit("input", value);
    },

    innerValue: {
      deep: true,
      handler(value) {
        this.innerValue = value;
        this.$emit("input", this.innerValue);
      }
    }
  },

  methods: {
    onTerritoriesUpdate(value) {
      let territories = [];

      for (let idx in value) {
        let countryCode = Array.isArray(value[idx]) ? value[idx][0] : value[idx];
        territories.push(countryCode);
      }

      this.innerValue.territories = territories;
    },

    onItemDelete(item) {
      this.$emit("itemDelete", item);
    },

    copySelected() {
      clipboardCopy(this.innerValue.convertedTerritories.map(el => el[0]));
    },

    onKeyDown(e) {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {
        e.preventDefault();
        this.pasteSelected();
      }
    },

    onPaste(e) {
      if (e instanceof ClipboardEvent && e.type === 'paste') {
        e.preventDefault();
        this.pasteSelected();
      }
    },

    pasteSelected() {
      clipboardPaste().then(data => {
        if (data.length) {
          data = data.split(",");

          for (let countryCode of data) {
            let countryName = this.territories[countryCode];

            if (countryName) {
              let item = [countryCode, countryName];

              if (!this.innerValue.convertedTerritories) {
                this.innerValue.convertedTerritories = [];
              }

              if (
                !this.innerValue.convertedTerritories.find(
                  el => el[0] === countryCode
                )
              ) {
                this.innerValue.convertedTerritories.push(item);
              }
            }
          }

          this.onTerritoriesUpdate(this.innerValue.convertedTerritories);
        }
      });
    }
  }
};
</script>

<style lang="sass">
.license-types-radio label.v-label,
.coverage-types-radio label.v-label
  font-size: 0.85em
</style>
