import { request } from "@/services/api";

export default {
  name: "settings",

  actions: {
    updateCurrentAccountSettings({ getters, rootState }, data) {
      const accountId = getters.userCurrentAccountId;
      const { payload } = data;

      return request
        .patch(`${rootState.apiPrefix}/accounts/${accountId}`, payload)
        .then(responseBody => {
          const updatedSettings = responseBody?.data?.settings ?? {};
          const account = getters.userCurrentAccount;

          account.settings = updatedSettings;

          return responseBody;
        });
    }
  }
};
