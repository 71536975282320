import { OWNERSHIP_COVERAGE_TYPES } from "@/pages/compositions/components/enums";

export default class OwnershipValidator {
  constructor(shares, territories) {
    this.shares = shares ?? [];
    this.territories = territories ?? {};

    this._invalidCountries = [];
  }

  validate() {
    const me = this;
    let isValid = true;

    if (this.shares.length > 1) {
      for (let item of this.shares) {
        let otherItems;

        if (item.coverage_type !== OWNERSHIP_COVERAGE_TYPES.global) {
          otherItems = this.shares.filter(
            el =>
              el.coverage_type === item.coverage_type &&
              el.license_type === item.license_type &&
              el.id !== item.id
          );

          // console.log("Current Item", item);
          // console.log("Other Items", otherItems);

          if (otherItems.length > 0 && item.territories.length > 0) {
            // console.log("Current country", item.territories);

            for (let otherItem of otherItems) {
              let currentItemTerritories = new Set(item.territories);
              let otherItemTerritories = new Set(otherItem.territories);
              let sameTerritories = new Set(
                [...currentItemTerritories].filter(x =>
                  otherItemTerritories.has(x)
                )
              );

              // console.log("Intersection", sameTerritories);

              if (sameTerritories.size > 0) {
                let sameCountriesList = [...sameTerritories];
                this._invalidCountries = sameCountriesList.map(
                  c => this.territories[c]
                );

                isValid = false;
              }
            }
          }
        } else {
          otherItems = this.shares.filter(
            el => el.license_type === item.license_type && el.id !== item.id
          );

          // console.log("OTHER ITEMS", otherItems);

          if (otherItems.length > 0) {
            this._invalidCountries = otherItems
              .flatMap(c => c.territories)
              .map(territoryCode => me.territories[territoryCode]);

            isValid = false;
          }
        }
      }
    }

    return isValid;
  }

  getInvalidCountries() {
    return this._invalidCountries;
  }
}
