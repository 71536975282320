var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"blur-all-on-close-dialog",rawName:"v-blur-all-on-close-dialog"}],attrs:{"max-width":"850px","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","disabled":_vm.globalLoading}},'v-btn',attrs,false),on),[_vm._v(" Edit ownership ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-toolbar',{staticClass:"headline",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" Edit ownership for – "+_vm._s(_vm.compositionName)+" ")])],1),_c('v-card-text',{staticStyle:{"max-height":"410px"}},[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-container',{staticClass:"pl-0 pt-0 mb-3 text--primary"},[_c('b',[_vm._v(_vm._s(_vm.accountName))]),_vm._v(" represents and warrants that the information it submits below is true. ")]),_vm._l((_vm.innerValue),function(share,index){return _c('edit-ownership-item',{key:share.id,attrs:{"territories":_vm.territories,"position":index},on:{"itemDelete":_vm.deleteOwnershipShares},model:{value:(_vm.innerValue[index]),callback:function ($$v) {_vm.$set(_vm.innerValue, index, $$v)},expression:"innerValue[index]"}})}),_c('v-spacer'),(_vm.shareErrorMessage)?_c('div',{staticClass:"red--text text-lighten-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.shareErrorMessage)+" ")]):_vm._e(),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":invalid || _vm.hasShareErrorMessage},on:{"click":_vm.addShareRow}},[_vm._v(" Add ownership split ")])],2)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","loading":_vm.removing,"disabled":_vm.saving || _vm.removing || !_vm.hasShares,"color":"primary"},on:{"click":function($event){return _vm.deleteOwnership(null)}}},[_vm._v(" Remove all ownership ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.saving || _vm.removing,"color":"primary"},on:{"click":_vm.onClosed}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.saving,"disabled":invalid ||
              _vm.saving ||
              _vm.removing ||
              (!_vm.hasDeletedShares && !_vm.innerValue.length) ||
              _vm.hasShareErrorMessage},on:{"click":function($event){$event.preventDefault();return _vm.saveOwnership($event)}}},[_vm._v(" Save ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }