import { mapGetters } from "vuex";
import { PageMenu } from "@/mixins/page-menu";

export const CompositionPageMenu = Object.assign({}, PageMenu, {
  computed: {
    ...mapGetters([
      "approvedRelationshipsCountGetter",
      "pendingRelationshipsCountGetter",
      "skippedRelationshipsCountGetter",
      "declinedRelationshipsCountGetter"
    ]),

    getMenuItems: function() {
      return [
        {
          title: "Metadata",
          icon: "mdi-clipboard-edit",
          routerName: "CompositionMetadata",
          visible: true
        },
        {
          title: "Ownership",
          icon: "mdi-book-variant",
          routerName: "CompositionOwnership",
          visible: true
        },
        {
          title: "Approved Relationships",
          icon: "mdi-link-plus",
          routerName: "ApprovedRelationships",
          visible: true,
          highlightDuringPartialRouteMatch: true,
          hasCounter: true,
          getCounter: () => this.approvedRelationshipsCountGetter
        },
        {
          title: "Pending Relationships",
          icon: "mdi-link",
          routerName: "PendingRelationships",
          visible: true,
          highlightDuringPartialRouteMatch: true,
          hasCounter: true,
          getCounter: () => this.pendingRelationshipsCountGetter
        },
        {
          title: "Skipped Relationships",
          icon: "mdi-link-lock",
          routerName: "SkippedRelationships",
          visible: true,
          highlightDuringPartialRouteMatch: true,
          hasCounter: true,
          getCounter: () => this.skippedRelationshipsCountGetter
        },
        {
          title: "Declined Relationships",
          icon: "mdi-link-off",
          routerName: "DeclinedRelationships",
          visible: true,
          highlightDuringPartialRouteMatch: true,
          hasCounter: true,
          getCounter: () => this.declinedRelationshipsCountGetter
        },
        {
          title: "Sound Recordings",
          icon: "mdi-music-note",
          routerName: "SoundRecordings",
          visible: true
        }
      ];
    },

    hasBackButton: function() {
      return true;
    },

    getPageDetailsSectionComponent: function() {
      return "CompositionNavigationDetails";
    }
  }
});
