var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_vm._t("title"),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-form',[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.changed || _vm.processing,"loading":false,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.undoChanges($event)}}},[_vm._v(" Undo changes ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.changed || _vm.processing || invalid,"loading":_vm.processing,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"ml-6",attrs:{"loading":false,"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.removeCompositionClick($event)}}},[_vm._v(" DELETE ")])],1),_c('v-divider'),_c('composition-info',{attrs:{"composition":_vm.composition}}),_c('v-divider'),_c('v-card-text',{staticClass:"mb-2"},[_c('div',{staticClass:"composition-meta-info"},[_c('h4',[_vm._v("Your metadata")])])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('text-field',{attrs:{"label":"Asset title (required)","name":"title","validation-rules":"required|max:255","outlined":"","dense":""},model:{value:(_vm.composition.meta.title),callback:function ($$v) {_vm.$set(_vm.composition.meta, "title", $$v)},expression:"composition.meta.title"}})],1),_c('v-col',{staticClass:"py-0"},[_c('text-field',{attrs:{"label":"Custom ID (required)","name":"custom_id","validation-rules":{
                required: true,
                max: 64,
                regex: /^[a-z_\-0-9.@:\/]+$/i
              },"outlined":"","dense":""},model:{value:(_vm.composition.meta.custom_id),callback:function ($$v) {_vm.$set(_vm.composition.meta, "custom_id", $$v)},expression:"composition.meta.custom_id"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('text-field',{attrs:{"label":"ISWC","name":"iswc","autocomplete":"off","hint":"Formats: T0000000000 or T-000.000.000-0","validation-rules":{
                regex: /(?:T\d{10}$)|(?:T-\d{3}.\d{3}.\d{3}-\d$)/
              },"outlined":"","dense":""},model:{value:(_vm.composition.meta.iswc),callback:function ($$v) {_vm.$set(_vm.composition.meta, "iswc", $$v)},expression:"composition.meta.iswc"}}),_c('combo-box',{attrs:{"label":"Writers","name":"writers","aria-multiline":"true","delimiters":['|'],"outlined":"","hint":"Enter pipe '|' separated values","counter":_vm.maxWritersCount,"validation-rules":{ maxItems: _vm.maxWritersCount }},model:{value:(_vm.composition.meta.writers),callback:function ($$v) {_vm.$set(_vm.composition.meta, "writers", $$v)},expression:"composition.meta.writers"}})],1),_c('v-col',{staticClass:"py-0"},[_c('text-field',{attrs:{"label":"HFA","name":"hfa","hint":"Starts with 1 uppercase letter and 5 digits","validation-rules":{ regex: /^([A-Z0-9]{6})$/ },"outlined":"","dense":""},model:{value:(_vm.composition.meta.hfa),callback:function ($$v) {_vm.$set(_vm.composition.meta, "hfa", $$v)},expression:"composition.meta.hfa"}}),_c('text-area-field',{attrs:{"label":"Notes","name":"notes","rows":"3","rules":"max:500"},model:{value:(_vm.composition.meta.notes),callback:function ($$v) {_vm.$set(_vm.composition.meta, "notes", $$v)},expression:"composition.meta.notes"}})],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('labels-autocomplete',{attrs:{"label":"Asset labels","name":"labels","fetch-callback":_vm.getLabels,"create-callback":_vm.createLabel,"key-attr":"name","outlined":"","counter":_vm.maxLabelsCount,"validation-rules":{
                maxItems: _vm.maxLabelsCount,
                regex: /^[^<>*%:&,\;|]+$/i,
                min: 2,
                max: 35
              }},model:{value:(_vm.composition.meta.labels),callback:function ($$v) {_vm.$set(_vm.composition.meta, "labels", $$v)},expression:"composition.meta.labels"}})],1)],1)],1)],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }