import { request } from "@/services/api";
import { filterListParams } from "@/store/helpers";

const getDefaultState = () => {
  return {
    currentConflictingRecording: null
  };
};

const state = getDefaultState();
export default {
  name: "ownership_conflicts",
  state,
  actions: {
    getAssetsConflictsHistory({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      let validParams = filterListParams(params);

      let conflictStartDate = Array.isArray(params.filter.conflictStartDate)
        ? params.filter.conflictStartDate
        : [params.filter.conflictStartDate];

      let conflictResolveDate = Array.isArray(params.filter.conflictResolveDate)
        ? params.filter.conflictResolveDate
        : [params.filter.conflictResolveDate];

      conflictStartDate.sort();
      conflictResolveDate.sort();

      validParams = {
        ...validParams,
        ...{
          "filter[youtube_asset_id]": params.filter.youtubeAssetId || null,
          "filter[custom_id]": params.filter.customId || null,
          "filter[status]": params.filter.status,
          "filter[latest_conflict.conflict_started_at][from]":
            conflictStartDate[0] || null,
          "filter[latest_conflict.conflict_started_at][to]":
            conflictStartDate[1] || null,
          "filter[latest_conflict.conflict_resolved_at][from]":
            conflictResolveDate[0] || null,
          "filter[latest_conflict.conflict_resolved_at][to]":
            conflictResolveDate[1] || null
        }
      };

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/ownership-conflicts/history?`,
        { params: validParams }
      );
    },

    getSingleAssetConflictsHistory({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      const validParams = filterListParams(params);

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/ownership-conflicts/history/${params.recordingId}`,
          { params: validParams }
        )
        .then(response => {
          return response;
        });
    },

    setCurrentConflictingRecording({ commit }, recording) {
      commit("mutateCurrentConflictingRecording", recording);
    },

    resetCurrentConflictingRecording({ commit }) {
      commit("mutateCurrentConflictingRecording", null);
    },

    getCompositionsConflictsSummary({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      let validParams = filterListParams(params);

      let conflictStartDate = Array.isArray(params.filter.conflictStartDate)
        ? params.filter.conflictStartDate
        : [params.filter.conflictStartDate];

      conflictStartDate.sort();

      validParams = {
        ...validParams,
        ...{
          "filter[youtube_asset_id]": params.filter.youtubeAssetId || null,
          "filter[custom_id]": params.filter.customId || null,
          "filter[latest_conflict.conflict_started_at][from]":
            conflictStartDate[0] || null,
          "filter[latest_conflict.conflict_started_at][to]":
            conflictStartDate[1] || null
        }
      };

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/ownership-conflicts/compositions?`,
        { params: validParams }
      );
    },

    getAssetsConflictsAnalytics({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      let ownersFilter = [];

      if (params.importDate) {
        params.importDate.sort();
      }

      if (params.owner) {
        for (const owner of params.owner) {
          ownersFilter.push(owner.id);
        }
      }

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/ownership-conflicts/analytics`,
        {
          params: {
            "filter[import_date][from]": params.importDate?.[0],
            "filter[import_date][to]": params.importDate?.[1],
            "filter[owner]": ownersFilter
          }
        }
      );
    }
  },

  getters: {
    currentConflictingRecording(state) {
      return state.currentConflictingRecording;
    }
  },

  mutations: {
    mutateCurrentConflictingRecording(state, recording) {
      state.currentConflictingRecording = recording;
    }
  }
};
