import { request } from "@/services/api";
import { prepareRequestParamsForCursorPaging } from "@/store/helpers";
import { PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA } from "@/components/enums";

const getDefaultState = () => {
  return {
    approvedRelationships: { count: null },
    pendingRelationships: { count: null },
    skippedRelationships: { count: null }
  };
};

const state = getDefaultState();

export default {
  name: "providers_data",
  state,
  actions: {
    processProvidersData({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      const data = { review_results: params.relatedMetadata };

      return request
        .put(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings-analysis/${params.status}/${params.recordingId}`,
          data
        )
        .then(response => {
          this.dispatch("updateGlobalProvidersDataCounts");
          return response;
        });
    },

    getProvidersData({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      // Remove status from params to prevent add status to query string
      let status = params.status;
      delete params.status;

      params = prepareRequestParamsForCursorPaging(
        params,
        PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA.RECORDINGS_ANALYSIS
      );

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings-analysis/${status}?`,
          { params }
        )
        .then(response => {
          return response;
        });
    },

    getSingleProvidersDataRecording({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings-analysis/${params.status}/${params.recordingId}`
        )
        .then(response => {
          return response;
        });
    },

    updateGlobalProvidersDataCounts({ getters, rootState, commit }) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings-analysis/counters`
        )
        .then(({ approved, pending, skipped }) => {
          commit("setGlobalApprovedProvidersDataCount", approved);
          commit("setGlobalPendingProvidersDataCount", pending);
          commit("setGlobalSkippedProvidersDataCount", skipped);
        });
    },

    getProvidersDataTotalCount({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/recordings-analysis/${params.status}/total-count`
        )
        .then(response => {
          return response;
        });
    },

    getDownloadRecordingAnalysisMetadataUrl({ rootState }, accountId) {
      return request.generateFullUrl(
        `${rootState.apiPrefix}/accounts/${accountId}/recordings-analysis/metadata`
      );
    }
  },

  getters: {
    globalApprovedProvidersDataCountGetter(state) {
      return state.approvedRelationships.count;
    },

    globalPendingProvidersDataCountGetter(state) {
      return state.pendingRelationships.count;
    },

    globalSkippedProvidersDataCountGetter(state) {
      return state.skippedRelationships.count;
    }
  },

  mutations: {
    setGlobalApprovedProvidersDataCount(state, value) {
      state.approvedRelationships.count = value || 0;
    },

    setGlobalPendingProvidersDataCount(state, value) {
      state.pendingRelationships.count = value || 0;
    },

    setGlobalSkippedProvidersDataCount(state, value) {
      state.skippedRelationships.count = value || 0;
    }
  }
};
