<template>
  <v-card
    elevation="0"
    class="pa-2 mb-1 d-flex composition-nav-details"
    height="80"
    max-width="93%"
  >
    <v-icon large>
      mdi-playlist-music
    </v-icon>
    <v-container>
      <p class="text-subtitle-2 mb-0 text-truncate" v-if="title">
        {{ title }}
      </p>
      <template v-else>
        <v-skeleton-loader type="text@2" class="mt-1"></v-skeleton-loader>
      </template>

      <p class="text-caption mb-0" v-if="title">{{ entityType }}</p>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { ACCOUNT_TYPE } from "@/components/enums";

export default {
  name: "OwnershipConflictsNavigationDetails",

  computed: {
    ...mapGetters(["currentConflictingRecording", "userCurrentAccount"]),

    isPublishingAccount() {
      return this.userCurrentAccount.type === ACCOUNT_TYPE.publishing;
    },

    entityType() {
      return this.isPublishingAccount ? "Composition" : "Sound recording";
    },

    title() {
      return this.isPublishingAccount
        ? this.currentConflictingRecording?.composition?.title
        : this.currentConflictingRecording.title;
    },

    icon() {
      return this.isPublishingAccount ? "mdi-playlist-music" : "mdi-music-note";
    }
  }
};
</script>

<style lang="sass">
.composition-nav-details .container .v-skeleton-loader__text:last-child
  width: 80px
</style>
