<template>
  <v-container>
    <div v-for="(section, index) in availableSections" :key="index">
      <v-row class="pt-3 pl-2 mb-3" v-if="section.title">
        <div class="text-xl-h5 text-lg-body-h6 font-weight-medium ml-1">
          {{ section.title }}
        </div>
      </v-row>

      <v-container class="pa-0 ma-0">
        <v-row class="pb-6">
          <v-col
            cols="12"
            sm="3"
            v-for="(item, index) in section.items"
            :key="index"
          >
            <statistics-item
              :title="item.title"
              :subtext="item.subtext"
              :count="item.value"
              :loading="loading"
            ></statistics-item>
          </v-col>
        </v-row>
      </v-container>

      <v-divider
        v-if="section.divider && index !== availableSections.length - 1"
        class="mb-4"
      />
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PageMenu } from "@/mixins/page-menu";
import StatisticsItem from "@/pages/dashboard/components/StatisticsItem";
import { DashboardSections } from "@/mixins/dashboard-sections";

export default {
  name: "Dashboard",

  components: { StatisticsItem },

  data: () => ({
    loading: false,
    statistics: {
      compositions: {
        total: 0,
        pending: 0,
        processing: 0
      },
      recordings: {
        total: 0,
        pending: 0,
        approved: 0
      },
      recordings_analysis: {
        pending: 0,
        approved: 0
      },
      sr_ownerships: {
        sr_pending: 0,
        sr_processing: 0,
        ownership_pending: 0,
        ownership_approved: 0
      }
    }
  }),

  mixins: [PageMenu, DashboardSections],

  computed: {
    ...mapGetters(["isConflictsManager"])
  },

  methods: {
    ...mapActions(["getStatistics"]),

    fetchStatistics() {
      this.loading = true;

      this.getStatistics()
        .then(result => {
          this.statistics = result;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  mounted() {
    if (!this.isConflictsManager) {
      this.fetchStatistics();
    }
  },

  metaInfo: {
    title: "Dashboard"
  }
};
</script>
