<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    persistent
    scrollable
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" @click="onDialogOpen">
        Import
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Import
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-stepper v-model="wizardStepId">
        <v-stepper-items>
          <file-selection-step
            @dialog-closed="onDialogClose"
            @set-file="setFile"
          ></file-selection-step>

          <upload-and-validate-file-step
            :file="fileWrapper.file"
            :import-type="importType"
            @dialog-closed="onDialogClose"
            @update-model="updateModel"
            @set-file="setFile"
          ></upload-and-validate-file-step>

          <import-data-step
            :model="model"
            :file="fileWrapper.file"
            :options="options"
            @dialog-closed="onDialogClose"
            @update-model="updateModel"
          ></import-data-step>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import FileSelectionStep from "@/components/import/steps/FileSelectionStep";
import UploadAndValidateFileStep from "@/components/import/steps/UploadAndValidateFileStep";
import ImportDataStep from "@/components/import/steps/ImportDataStep";
import { mapGetters } from "vuex";
import { IMPORT_STATUS, IMPORT_WIZARD_STEPS } from "@/components/enums";

export default {
  name: "ImportWizard",

  components: { FileSelectionStep, UploadAndValidateFileStep, ImportDataStep },

  data: () => ({
    dialog: false,
    wizardStepId: IMPORT_WIZARD_STEPS.file_select,
    model: null,
    fileWrapper: {
      file: null,
      valid: false
    }
  }),

  props: {
    importType: {
      type: String
    },

    options: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    ...mapGetters(["getNewImportModel"]),

    onDialogOpen() {
      this.setWizardStep(IMPORT_WIZARD_STEPS.file_select);
    },

    onDialogClose() {
      this.fileWrapper = {
        file: null,
        valid: false
      };
      this.dialog = false;
      this.setWizardStep("");
      this.model = this.getNewImportModel();
    },

    setWizardStep(value) {
      this.wizardStepId = value;
    },

    updateModel(value) {
      this.model = value;
      this.resolveNextStep();
    },

    setFile(value, isValid = false) {
      this.fileWrapper.file = value;
      this.fileWrapper.valid = isValid;
      this.resolveNextStep();
    },

    resolveNextStep() {
      if (
        this.fileWrapper.file &&
        this.fileWrapper.valid &&
        this.wizardStepId === IMPORT_WIZARD_STEPS.file_select
      ) {
        this.setWizardStep(IMPORT_WIZARD_STEPS.validate);
      } else if (
        this.model.id &&
        this.wizardStepId === IMPORT_WIZARD_STEPS.validate
      ) {
        this.setWizardStep(IMPORT_WIZARD_STEPS.import);
      } else if (this.model.processing_status === IMPORT_STATUS.processed) {
        this.$emit("onImportFinished");
        console.log("Import processed");
      } else {
        this.setWizardStep(IMPORT_WIZARD_STEPS.file_select);
      }
    }
  },

  created() {
    if (!this.model) {
      this.model = this.getNewImportModel();
    }
  }
};
</script>

<style lang="sass">
.v-stepper__content .v-card__text
  min-height: 300px
</style>
