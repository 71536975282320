<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Accounts
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="results"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :footer-props="tableFooterProps"
        :items-per-page="defaultItemsPerPage"
      >
        <template v-slot:item.is_active="{ item }">
          <active-account-switcher
            :disabled="isCurrentAccount(item)"
            :account="item"
            :processing="onUpdateAccountSettings"
            class="d-flex justify-center"
          ></active-account-switcher>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | date }}
        </template>
        <template v-slot:item.settings.recordings_search_enabled="{ item }">
          <settings-switcher
            :account="item"
            :processing="onUpdateAccountSettings"
            :setting-name="'recordings_search_enabled'"
            class="d-flex justify-center"
          ></settings-switcher>
        </template>
        <template v-slot:item.settings.backup_assets_reports="{ item }">
          <settings-switcher
            :account="item"
            :processing="onUpdateAccountSettings"
            :setting-name="'backup_assets_reports'"
            class="d-flex justify-center"
          ></settings-switcher>
        </template>
        <template v-slot:item.settings.import_assets_reports="{ item }">
          <settings-switcher
            :account="item"
            :processing="onUpdateAccountSettings"
            :setting-name="'import_assets_reports'"
            class="d-flex justify-center"
          ></settings-switcher>
        </template>
        <template v-slot:item.settings.api_access_enabled="{ item }">
          <settings-switcher
            :account="item"
            :processing="onUpdateAccountSettings"
            :setting-name="'api_access_enabled'"
            class="d-flex justify-center"
          ></settings-switcher>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list nav dense class="data-table-actions-menu pa-0">
              <v-list-item @click="editAccountClick(item)">
                <v-list-item-content>
                  <v-list-item-title v-text="`Edit`" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :disabled="isCurrentAccount(item)"
                @click="deleteAccountClick(item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="`Delete`" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.type | accountTypeToHumanReadable }}
        </template>
      </v-data-table>
    </v-card>
    <edit-account
      v-if="accountToEdit"
      :opened="!!accountToEdit"
      @onClose="onCloseEditModal"
      :account="accountToEdit"
      @onUpdate="onAccountUpdate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import { SupervisorPageMenu } from "@/mixins/supervisor-page-menu";
import ActiveAccountSwitcher from "@/pages/supervisor/components/ActiveAccountSwitcher";
import EditAccount from "@/pages/supervisor/components/EditAccount";
import { accountTypeToHumanReadable } from "@/filters/account_type";
import SettingsSwitcher from "@/components/SettingsSwitcher.vue";

export default {
  name: "Accounts",
  mixins: [SupervisorPageMenu, GridUtils, DataTableFooterProps],
  components: {
    SettingsSwitcher,
    EditAccount,
    ActiveAccountSwitcher
  },

  data: () => ({
    headers: [
      {
        text: "Name",
        value: "name",
        sortable: true
      },
      {
        text: "Content Owner ID",
        value: "content_owner_id",
        sortable: true
      },
      {
        text: "Type",
        value: "type",
        sortable: true
      },
      {
        text: "Created On",
        value: "created_at",
        sortable: true
      },
      {
        text: "Active",
        value: "is_active",
        sortable: true,
        align: "center"
      },
      {
        text: "Search Sound Recordings",
        value: "settings.recordings_search_enabled",
        sortable: true,
        align: "center"
      },
      {
        text: "Backup Assets Reports",
        value: "settings.backup_assets_reports",
        sortable: true,
        align: "center"
      },
      {
        text: "Import Assets Reports",
        value: "settings.import_assets_reports",
        sortable: true,
        align: "center"
      },
      {
        text: "API Access",
        value: "settings.api_access_enabled",
        sortable: true,
        align: "center"
      },
      {
        text: "Actions",
        align: "end",
        value: "actions",
        sortable: false,
        width: "50px"
      }
    ],
    accountToEdit: null,
    persistentStateKey: "supervisor.accounts"
  }),

  computed: {
    ...mapGetters(["userCurrentAccountId"]),
    defaultItemsPerPage() {
      return this.tableFooterProps["items-per-page-options"].slice(-1)[0];
    }
  },

  methods: {
    ...mapActions(["getAccounts", "updateAccountSettings", "deleteAccount"]),

    getResultsActionName() {
      return "getAccounts";
    },

    isCurrentAccount(account) {
      return account.id === this.userCurrentAccountId;
    },

    editAccountClick(item) {
      this.accountToEdit = item;
    },

    async deleteAccountClick(item) {
      const result = await this.$confirm(
        `Are you sure you want to remove the "${item.name}" account? ` +
          "All related assets & records will be removed. The operation cannot be undone.",
        { buttonTrueText: "Continue", buttonFalseText: "Cancel" }
      );

      if (result) {
        await this.deleteAccount(item.id);
        this.$delete(this.results, this.results.indexOf(item));
      }
    },

    onCloseEditModal() {
      this.accountToEdit = null;
    },

    onAccountUpdate() {
      this.results = [];
      this.$nextTick(() => {
        this.fetchData();
      });
    },

    onUpdateAccountSettings(data) {
      // Updating account settings in results without fetching data from API
      return this.updateAccountSettings(data).then(() => {
        this.results.forEach(account => {
          if (account.id === data.accountId) {
            this.$set(account, "is_active", data.payload.is_active);

            for (let key in data.payload.settings) {
              this.$set(account.settings, key, data.payload.settings[key]);
            }
          }
        });
      });
    }
  },

  metaInfo: {
    title: "Accounts"
  },

  filters: { accountTypeToHumanReadable }
};
</script>

<style lang="sass">
/* The only way to get rid of the hover effect. */
.v-data-table__wrapper > table > tbody > tr:hover
  background: inherit !important
</style>
