import { request } from "@/services/api";
import { prepareRequestParamsForCursorPaging } from "@/store/helpers";
import { PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA } from "@/components/enums";

const getDefaultState = () => {
  return {
    loading: false,
    approvedOwnershipCandidates: { count: null },
    pendingOwnershipCandidates: { count: null },
    skippedOwnershipCandidates: { count: null },
    declinedOwnershipCandidates: { count: null }
  };
};

const state = getDefaultState();

export default {
  name: "ownership-candidates",
  state,
  actions: {
    getCandidates({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates?page=${params.page}&size=${params.size}&query=${params.query}`
      );
    },

    updateOwnershipCandidatesCounts({ getters, rootState, commit }) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates/counters`
        )
        .then(({ approved, pending, skipped, declined }) => {
          commit("setApprovedOwnershipCandidatesCount", approved);
          commit("setPendingOwnershipCandidatesCount", pending);
          commit("setSkippedOwnershipCandidatesCount", skipped);
          commit("setDeclinedOwnershipCandidatesCount", declined);
        });
    },

    getOwnershipCandidates({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      // Remove status from params to prevent add status to query string
      let status = params.status;
      delete params.status;

      params = prepareRequestParamsForCursorPaging(
        params,
        PAGING_KEYS_FOR_FETCH_ITEMS_LIST_DATA.OWNERSHIP_CANDIDATES
      );

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates/${status}`,
          { params }
        )
        .then(response => {
          return response;
        });
    },

    getSingleOwnershipCandidate({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates/${params.status}/${params.recordingId}`
        )
        .then(response => {
          return response;
        });
    },

    getOwnershipCandidatesTotalCount({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;
      return request
        .get(
          `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates/${params.status}/total-count`
        )
        .then(response => {
          return response;
        });
    },

    getDownloadOwnershipCandidatesMetadataUrl(
      { rootState },
      { accountId, status }
    ) {
      return request.generateFullUrl(
        `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates/metadata/${status}`
      );
    },

    updateOwnershipCandidate({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request
        .put(
          `${rootState.apiPrefix}/accounts/${accountId}/ownership_candidates/${params.recordingId}/${params.status}`
        )
        .then(response => {
          this.dispatch("updateOwnershipCandidatesCounts");
          return response;
        });
    }
  },

  getters: {
    approvedOwnershipCandidatesCountGetter(state) {
      return state.approvedOwnershipCandidates.count;
    },

    pendingOwnershipCandidatesCountGetter(state) {
      return state.pendingOwnershipCandidates.count;
    },

    skippedOwnershipCandidatesCountGetter(state) {
      return state.skippedOwnershipCandidates.count;
    },

    declinedOwnershipCandidatesCountGetter(state) {
      return state.declinedOwnershipCandidates.count;
    }
  },

  mutations: {
    setApprovedOwnershipCandidatesCount(state, value) {
      state.approvedOwnershipCandidates.count = value || 0;
    },

    setPendingOwnershipCandidatesCount(state, value) {
      state.pendingOwnershipCandidates.count = value || 0;
    },

    setSkippedOwnershipCandidatesCount(state, value) {
      state.skippedOwnershipCandidates.count = value || 0;
    },

    setDeclinedOwnershipCandidatesCount(state, value) {
      state.declinedOwnershipCandidates.count = value || 0;
    }
  }
};
