<template>
  <v-container class="text--primary px-0 pt-0 pb-4">
    <div>
      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="text-subtitle-2 pb-2"
            >Canonical metadata</v-card-text
          >
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Title:</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.effective_metadata.title | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Provider:</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.effective_metadata.provider | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">YouTube Asset ID:</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0 d-flex ">
            {{ recording.youtube_asset_id | default_value }}
            <a
              :href="linkToYoutubeStudio"
              target="_blank"
              v-if="recording.youtube_asset_id && contentOwnerId"
              class="d-flex text-decoration-none ml-2"
            >
              <v-icon small color="blue">mdi-open-in-new</v-icon>
            </a>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">ISWC:</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.effective_metadata.iswc | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">HFA:</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.effective_metadata.hfa | default_value
          }}</v-card-text>
        </v-col>
      </v-row>

      <v-row no-gutters class="ma-0">
        <v-col cols="4">
          <v-card-text class="py-0">Writers:</v-card-text>
        </v-col>
        <v-col cols="8">
          <v-card-text class="py-0">{{
            recording.effective_metadata.writers | default_value
          }}</v-card-text>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RecordingCard",

  props: {
    recording: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["userCurrentAccount"]),
    contentOwnerId() {
      return this.userCurrentAccount.content_owner_id;
    },
    linkToYoutubeStudio() {
      let assetId = this.recording.youtube_asset_id;
      let contentOwnerId = this.contentOwnerId;
      return `https://studio.youtube.com/asset/${assetId}/composition?o=${contentOwnerId}`;
    }
  }
};
</script>

<style lang="sass">
div.canonical
  margin-bottom: 20px
</style>
