<template>
  <v-card
    elevation="0"
    class="pa-2 mb-1 d-flex composition-nav-details"
    height="80"
    max-width="93%"
  >
    <v-icon large>
      mdi-playlist-music
    </v-icon>
    <v-container>
      <p class="text-subtitle-2 mb-0 text-truncate" v-if="title">
        {{ title }}
      </p>
      <template v-else>
        <v-skeleton-loader type="text@2" class="mt-1"></v-skeleton-loader>
      </template>

      <p class="text-caption mb-0" v-if="title">Composition</p>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CompositionNavigationDetails",

  data() {
    return {
      title: null,
      emptyTitle: "Composition"
    };
  },

  computed: {
    ...mapGetters(["currentComposition"])
  },

  methods: {
    _initData() {
      this.title = this.currentComposition?.meta?.title;
    }
  },

  watch: {
    currentComposition: {
      deep: true,
      immediate: true,
      handler: function() {
        this._initData();
      }
    }
  }
};
</script>

<style lang="sass">
.composition-nav-details .container .v-skeleton-loader__text:last-child
  width: 80px
</style>
