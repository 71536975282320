import { render, staticRenderFns } from "./SoundRecordingMetadata.vue?vue&type=template&id=5d17f416&scoped=true&"
import script from "./SoundRecordingMetadata.vue?vue&type=script&lang=js&"
export * from "./SoundRecordingMetadata.vue?vue&type=script&lang=js&"
import style0 from "./SoundRecordingMetadata.vue?vue&type=style&index=0&id=5d17f416&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d17f416",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VCardText,VChip,VContainer,VHover,VIcon,VList,VListItem,VListItemSubtitle,VListItemTitle})
