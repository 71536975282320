<template>
  <v-list-item v-if="file">
    <v-list-item-content>
      <v-list-item-title>
        {{ file.name }}
        <span class="ml-3 text--secondary">
          {{ file.size | format_bytes }}
        </span>
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        @click.stop="onFileRemove"
        icon
        :disabled="removeButtonOptions.disabled"
        v-if="removeButtonOptions.visible"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "SelectedFile",

  props: {
    file: {
      type: File,
      default: null
    },

    removeButtonOptions: {
      type: Object
    }
  },

  methods: {
    onFileRemove() {
      this.$emit("remove-file");
    }
  }
};
</script>

<style scoped lang="sass">
.v-list-item
  border: 1px solid rgba(0, 0, 0, 0.12)
</style>
