<template>
  <ValidationProvider
    :label="$attrs.label"
    :mode="$attrs.mode"
    :name="name"
    :rules="rules"
  >
    <v-text-field
      slot-scope="{ errors }"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";

extend("number", {
  validate(value) {
    return value > -1;
  },
  message: "Must be a whole positive number"
});

export default {
  name: "NumberPositive",
  components: {
    ValidationProvider
  },
  props: {
    validationRules: {
      type: String
    },
    value: {
      type: null
    },
    name: {
      type: String
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    rules() {
      return this.validationRules || "required|number";
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
