<template>
  <ValidationProvider
    :label="$attrs.label"
    :mode="$attrs.mode"
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ errors }"
  >
    <v-text-field
      type="text"
      v-model="innerValue"
      :error-messages="errors"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "TextField",
  components: {
    ValidationProvider
  },
  props: {
    validationRules: {
      type: [String, Object]
    },
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    rules() {
      return this.validationRules;
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
