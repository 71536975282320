import { mapActions, mapGetters } from "vuex";
import store from "@/store/index";
import { ACCOUNT_TYPE } from "@/components/enums";
import { MENU_GROUP_TITLE_ACTIVE_COLOR } from "@/components/constants";

const PageMenu = {
  computed: {
    ...mapGetters([
      "userCurrentAccount",
      "isConflictsManager",
      "globalApprovedRelationshipsCountGetter",
      "globalPendingRelationshipsCountGetter",
      "globalSkippedRelationshipsCountGetter",
      "globalDeclinedRelationshipsCountGetter",
      "globalApprovedProvidersDataCountGetter",
      "globalPendingProvidersDataCountGetter",
      "globalSkippedProvidersDataCountGetter",
      "approvedOwnershipCandidatesCountGetter",
      "pendingOwnershipCandidatesCountGetter",
      "skippedOwnershipCandidatesCountGetter",
      "declinedOwnershipCandidatesCountGetter"
    ]),

    getMenuItems: function() {
      return [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          routerName: "Dashboard",
          visible: !this.isConflictsManager
        },
        {
          title: "Providers Data Analysis",
          icon: "mdi-table",
          visible: !this.isConflictsManager,
          color: MENU_GROUP_TITLE_ACTIVE_COLOR,
          routeGroupRegex: /\/[a-z0-9-]*\/recordings-analysis/,
          accountTypes: [ACCOUNT_TYPE.publishing],
          nestedItems: [
            {
              title: "Approved",
              onHoverTitle: "Approved Data Analysis",
              icon: "mdi-table-plus",
              routerName: "ApprovedProvidersDataAnalysis",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalApprovedProvidersDataCountGetter
            },
            {
              title: "Pending",
              onHoverTitle: "Pending Data Analysis",
              icon: "mdi-table",
              routerName: "PendingProvidersDataAnalysis",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalPendingProvidersDataCountGetter
            },
            {
              title: "Skipped",
              onHoverTitle: "Skipped Data Analysis",
              icon: "mdi-table-lock",
              routerName: "SkippedProvidersDataAnalysis",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalSkippedProvidersDataCountGetter
            }
          ]
        },
        {
          title: "Compositions",
          icon: "mdi-music-box-multiple",
          routerName: "Compositions",
          visible: !this.isConflictsManager,
          accountTypes: [ACCOUNT_TYPE.publishing]
        },
        {
          title: "Potential Relationships",
          icon: "mdi-link",
          visible: !this.isConflictsManager,
          color: MENU_GROUP_TITLE_ACTIVE_COLOR,
          routeGroupRegex: /\/[a-z0-9-]*\/recordings\//,
          accountTypes: [ACCOUNT_TYPE.publishing],
          nestedItems: [
            {
              title: "Approved",
              onHoverTitle: "Approved Potential Relationships",
              icon: "mdi-link-plus",
              routerName: "GlobalApprovedRelationships",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalApprovedRelationshipsCountGetter
            },
            {
              title: "Pending",
              onHoverTitle: "Pending Potential Relationships",
              icon: "mdi-link",
              routerName: "GlobalPendingRelationships",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalPendingRelationshipsCountGetter
            },
            {
              title: "Skipped",
              onHoverTitle: "Skipped Potential Relationships",
              icon: "mdi-link-lock",
              routerName: "GlobalSkippedRelationships",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalSkippedRelationshipsCountGetter
            },
            {
              title: "Declined",
              onHoverTitle: "Declined Potential Relationships",
              icon: "mdi-link-off",
              routerName: "GlobalDeclinedRelationships",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.globalDeclinedRelationshipsCountGetter
            }
          ]
        },
        {
          title: "SR Ownerships",
          icon: "mdi-microphone",
          visible: !this.isConflictsManager,
          color: MENU_GROUP_TITLE_ACTIVE_COLOR,
          routeGroupRegex: /\/[a-z0-9-]*\/ownership_candidates\//,
          accountTypes: [ACCOUNT_TYPE.sound_recordings_management],
          nestedItems: [
            {
              title: "Approved",
              onHoverTitle: "Approved SR ownership candidates",
              icon: "mdi-microphone-plus",
              iconStyle: "margin-left: 3px;",
              routerName: "ApprovedOwnershipCandidates",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.approvedOwnershipCandidatesCountGetter
            },
            {
              title: "Pending",
              onHoverTitle: "Pending SR ownership candidates",
              icon: "mdi-microphone",
              routerName: "PendingOwnershipCandidates",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.pendingOwnershipCandidatesCountGetter
            },
            {
              title: "Skipped",
              onHoverTitle: "Skipped SR ownership candidates",
              icon: "mdi-microphone-question",
              iconStyle: "margin-left: 3px;",
              routerName: "SkippedOwnershipCandidates",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.skippedOwnershipCandidatesCountGetter
            },
            {
              title: "Declined",
              onHoverTitle: "Declined SR ownership candidates",
              icon: "mdi-microphone-off",
              routerName: "DeclinedOwnershipCandidates",
              visible: true,
              highlightDuringPartialRouteMatch: true,
              hasCounter: true,
              getCounter: () => this.declinedOwnershipCandidatesCountGetter
            }
          ]
        },
        {
          title: "Sound Recordings",
          icon: "mdi-music-note",
          routerName: "AccountSoundRecordings",
          visible: !this.isConflictsManager,
          accountTypes: [ACCOUNT_TYPE.sound_recordings_management]
        },
        {
          title: "Import",
          icon: "mdi-application-import",
          routerName: "CompositionsImport",
          visible: !this.isConflictsManager,
          accountTypes: [ACCOUNT_TYPE.publishing]
        },
        {
          title: "Import",
          icon: "mdi-application-import",
          routerName: "SoundRecordingsImport",
          visible: !this.isConflictsManager,
          accountTypes: [ACCOUNT_TYPE.sound_recordings_management]
        },
        {
          title: "Ownership Conflicts",
          icon: "mdi-book-outline",
          visible: true,
          color: MENU_GROUP_TITLE_ACTIVE_COLOR,
          routeGroupRegex: /\/[a-z0-9-]*\/ownership-conflicts/,
          nestedItems: [
            {
              title: "Asset Conflicts History",
              onHoverTitle: "Asset Conflicts History",
              icon: "mdi-calendar-clock",
              routerName: "AssetsConflictsHistory",
              visible: true,
              highlightDuringPartialRouteMatch: true
            },
            {
              title: "Compositions Summary",
              onHoverTitle: "Composition Conflicts Summary",
              icon: "mdi-book-music-outline",
              routerName: "CompositionConflictsSummary",
              visible: this.isPublishingAccount,
              highlightDuringPartialRouteMatch: true
            },
            {
              title: "Analytics",
              onHoverTitle: "Asset Conflicts Analytics",
              icon: "mdi-chart-line",
              routerName: "ConflictsAnalytics",
              visible: true,
              highlightDuringPartialRouteMatch: true
            }
          ]
        },
        {
          divider: true,
          visible: !this.isConflictsManager
        },
        {
          title: "Supervisor",
          icon: "mdi-tune",
          routerName: "Accounts",
          visible: store.getters.isSupervisor
        },
        {
          title: "Settings",
          icon: "mdi-cog",
          routerName: "AccountManagement",
          visible: store.getters.hasAdminAccess
        }
      ];
    },

    hasBackButton: function() {
      return false;
    },

    getPageDetailsSectionComponent: function() {
      return "DashboardNavigationDetails";
    },

    isPublishingAccount() {
      return (
        this.$store.getters.userCurrentAccount?.type === ACCOUNT_TYPE.publishing
      );
    }
  },

  methods: {
    ...mapActions([
      "storeMenuItems",
      "storePrevPage",
      "storePageHasBackButton",
      "storePageDetailsSectionComponent",
      "updateGlobalRecordingsCounts",
      "updateGlobalProvidersDataCounts"
    ])
  },

  beforeMount() {
    if (!this.isConflictsManager) {
      if (this.isPublishingAccount) {
        this.$store.dispatch("updateGlobalRecordingsCounts");
        this.$store.dispatch("updateGlobalProvidersDataCounts");
      }

      this.$store.dispatch("updateOwnershipCandidatesCounts");
    }
  },

  created() {
    this.storeMenuItems(this.getMenuItems);
    this.storePageHasBackButton(this.hasBackButton);
    this.storePageDetailsSectionComponent(this.getPageDetailsSectionComponent);
  },

  beforeRouteEnter(to, from, next) {
    if (from.meta.canBeUsedAsBackButtonTarget) {
      store.dispatch("storePrevPage", from.path);
    }

    next();
  }
};

export { PageMenu };
