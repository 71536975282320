<template>
  <div class="empty-data-container">
    <v-layout justify-center align-center style="height: 300px">
      <span>No data available</span>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "EmptyData"
};
</script>

<style lang="sass" scoped>
.empty-data-container
  padding-top: 15px
  font-size: 0.875rem
  height: 48px
  color: rgba(0, 0, 0, 0.38)
</style>
