<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1 justify-space-between">
          Sound Recordings
          <v-btn color="primary" @click="downloadSoundRecordings"
            >Download</v-btn
          >
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="text-start px-1 py-0">
              <v-hover v-slot="{ hover }" :open-delay="500">
                <v-container>
                  <span>{{ item.metadata.title || "(no title)" }}</span>
                  <br />
                  <span class="caption grey--text text--darken-4">{{
                    getRemainder(item)
                  }}</span>
                  <template v-if="hover">
                    <v-card
                      max-width="450"
                      elevation="3"
                      class="ml-16 recording-block"
                    >
                      <sound-recording-metadata
                        :recording="item"
                        :hide-labels="true"
                      ></sound-recording-metadata>
                    </v-card>
                  </template>
                </v-container>
              </v-hover>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.youtube_asset_id | default_value }}</span
              ><br />
              <span>{{ item.metadata.custom_id | default_value }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.isrc | default_value }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.album | default_value }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.created_at | date }}</span
              ><br />
              <span class="grey--text text--darken-1">{{
                item.metadata.created_at | humanizeTime
              }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.metadata.updated_at | date }}</span
              ><br />
              <span class="grey--text text--darken-1">{{
                item.metadata.updated_at | humanizeTime
              }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list nav dense class="data-table-actions-menu pa-0">
                  <v-list-item @click="removeRecordingClick(item)">
                    <v-list-item-content>
                      <v-list-item-title v-text="`Delete`" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GridUtils } from "@/mixins/grid-utils";
import SoundRecordingMetadata from "@/pages/recordings/components/SoundRecordingMetadata";
import { humanizeTime } from "@/filters/time";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import { PageMenu } from "@/mixins/page-menu";
import { DownloadWindow } from "@/mixins/download-window";
import { RemoveRecording } from "@/mixins/remove-recording-mixin";

export default {
  name: "SoundRecordings",
  mixins: [
    GridUtils,
    DataTableFooterProps,
    PageMenu,
    DownloadWindow,
    RemoveRecording
  ],
  components: { SoundRecordingMetadata },
  filters: { humanizeTime },

  data: () => ({
    maxLabelsCount: 5,
    headers: [
      {
        text: "Title",
        align: "start",
        sortable: false,
        value: "meta.title"
      },
      {
        text: "Youtube Asset ID | Custom ID",
        align: "end",
        sortable: false,
        width: "200px"
      },
      {
        text: "ISRC",
        align: "end",
        sortable: false,
        width: "150px"
      },
      {
        text: "Album",
        align: "end",
        sortable: false,
        width: "150px"
      },
      {
        text: "Created On",
        align: "end",
        sortable: false,
        value: "meta.created_at",
        width: "150px"
      },
      {
        text: "Updated On",
        align: "end",
        sortable: false,
        value: "meta.updated_at",
        width: "150px"
      },
      {
        text: "Actions",
        align: "end",
        value: "actions",
        sortable: false,
        width: "100px"
      }
    ]
  }),

  methods: {
    ...mapActions(["getAccountRecordings", "getDownloadSoundRecordingsUrl"]),

    getQueryParams() {
      return {
        account: this.$route.params.accountId,
        page: this.options.page,
        size: this.options.itemsPerPage,
        query: this.search.length < this.searchMinCharsCount ? "" : this.search
      };
    },

    getResultsActionName() {
      return "getAccountRecordings";
    },

    showLabels(item) {
      return item.metadata.labels.slice(0, this.maxLabelsCount);
    },

    getRemainder(item) {
      let remainder = item.metadata.labels.length - this.maxLabelsCount;
      if (remainder > 0) {
        return `(+${remainder})`;
      }
    },

    async downloadSoundRecordings() {
      const query = this.getQueryParams();
      this.openDownloadWindow(
        await this.getDownloadSoundRecordingsUrl({
          account_id: this.$route.params.accountId,
          query: query.query
        })
      );
    },

    removeRecordingClick(item) {
      this.removeRecording(item.id);
    },

    afterRemoveRecording() {
      this.fetchData();
    }
  },

  mounted() {},

  metaInfo: {
    title: "Sound Recordings"
  }
};
</script>

<style lang="sass" scoped>
.recording-block
  position: absolute
</style>
