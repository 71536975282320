import { request } from "@/services/api";
import { filterListParams } from "@/store/helpers";

export default {
  name: "global_search",

  actions: {
    getGlobalSearchResults({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/search`,
        { params: filterListParams(params) }
      );
    }
  }
};
