<template>
  <v-stepper-content :step="stepId" class="pa-0 notransition">
    <v-card-text>
      <v-row v-if="errors" class="has-errors">
        <selected-file
          :file="selectedFile"
          :remove-button-options="selectedFileRemoveButtonOptions"
          @remove-file="onFileRemove"
        ></selected-file>

        <v-row v-if="errors">
          <validation-errors :errors="errors"></validation-errors>
        </v-row>
      </v-row>
      <v-row
        v-else
        class="d-flex flex-column upload-box"
        dense
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{ 'grey lighten-2': dragover }"
        @click="onDropAreaClick"
      >
        <div class="upload-box-content">
          <v-icon class="d-flex" size="80">mdi-cloud-upload</v-icon>
          <p>
            Drop the ZIP archive here or click to select one.
          </p>
          <input
            type="file"
            ref="clickUpload"
            accept="application/zip"
            style="display: none"
            @change="onFileInputChange"
            @click="onFileInputClick"
          />
        </div>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="onClose">Close</v-btn>
    </v-card-actions>
  </v-stepper-content>
</template>

<script>
import {
  IMPORT_ERRORS_TYPES,
  IMPORT_WIZARD_STEPS,
  ZIP_FILES_MIME_TYPES
} from "@/components/enums";
import SelectedFile from "@/components/import/SelectedFile";
import ValidationErrors from "@/components/import/ValidationErrors";
import { formatBytes } from "@/filters/files";

export default {
  name: "FileSelectionStep",

  components: { SelectedFile, ValidationErrors },

  data: () => ({
    dragover: false,
    selectedFile: null,
    errors: null
  }),

  computed: {
    selectedFileRemoveButtonOptions() {
      return { disabled: false, visible: true };
    },

    stepId() {
      return IMPORT_WIZARD_STEPS.file_select;
    }
  },

  methods: {
    onClose() {
      this.selectedFile = null;
      this.errors = null;
      this.$emit("set-file", null);
      this.$emit("dialog-closed");
    },

    onDrop(e) {
      this.dragover = false;

      if (e.dataTransfer.files.length > 1) {
        this.setErrors({
          _: ["Only one file can be uploaded at a time"]
        });
      } else {
        const file = this.getFile(e.dataTransfer);
        const isValid = this.validateFileSize(file);
        this.selectedFile = file;
        this.$emit("set-file", file, isValid);
      }
    },

    onDropAreaClick() {
      this.$refs.clickUpload.click();
    },

    onFileInputClick() {
      // a fix for the same file selection
      this.$refs.clickUpload.value = "";
    },

    onFileInputChange(e) {
      const target = e.target;

      if (target.files) {
        const file = this.getFile(target);
        const isValid = this.validateFileSize(file);
        this.selectedFile = file;
        this.$emit("set-file", file, isValid);
      }
    },

    setErrors(errors, type = IMPORT_ERRORS_TYPES.other) {
      this.errors = {
        type: type,
        data: errors
      };
    },

    getFile(fileList) {
      const file = fileList.files?.[0];
      return file && ZIP_FILES_MIME_TYPES.includes(file.type) ? file : null;
    },

    onFileRemove() {
      this.selectedFile = null;
      this.errors = null;
      this.$emit("set-file", null);
    },

    validateFileSize(file) {
      if (
        file &&
        file.size > parseInt(process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE)
      ) {
        this.setErrors({
          _: [
            `The selected file must be less than ${formatBytes(
              process.env.VUE_APP_UPLOAD_MAX_FILE_SIZE
            )}`
          ]
        });

        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped lang="sass">
.upload-box
  height: 275px
  cursor: pointer

  .upload-box-content
    margin: 0 auto
    padding-top: 85px

    p
      margin-bottom: 5px

    .file-type-hint
      padding-left: 30px

.has-errors
  padding: 16px
</style>
