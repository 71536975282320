<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Asset Labels
        </v-card-title>
      </v-col>
    </v-row>

    <edit-label
        :dialog-opened="dialog"
        :label="currentLabel"
        :callback="fetchData"
        @dialog-close="onDialogClose"
    ></edit-label>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list nav dense class="data-table-actions-menu pa-0">
              <v-list-item @click="onItemEdit(item)">
                <v-list-item-content>
                  <v-list-item-title v-text="`Edit`"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="onStartDelete(item)">
                <v-list-item-content>
                  <v-list-item-title v-text="`Delete`"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import { SettingsPageMenu } from "@/mixins/settings-page-menu";
import EditLabel from "./components/EditLabel";
import {deepCopy} from "../../components/helpers";


export default {
  name: "AssetLabels",
  components: {EditLabel},
  mixins: [SettingsPageMenu, GridUtils, DataTableFooterProps],

  data: () => ({
    dialog: false,
    currentLabel: {},
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name"
      },
      {
        text: "Actions",
        align: "end",
        value: "actions",
        sortable: false
      }
    ]
  }),

  methods: {
    ...mapActions(["getLabels", "deleteLabel"]),

    getResultsActionName() {
      return "getLabels";
    },

    onDelete(item) {
      this.loading = true;
      this.deleteLabel(item.id).then(() => {
        this.onDialogClose(true);
      });
    },

    onStartDelete(item) {
      this.$confirm(
        `Are you sure you want to remove this asset label?
                    All related assets will be updated in the background in this case.`,
        { buttonTrueText: "Continue", buttonFalseText: "Cancel" }
      ).then(result => {
        if (result) {
          this.onDelete(item);
        }
      });
    },

    onItemEdit(item) {
      this.dialog = true;
      this.currentLabel = deepCopy(item);
    },

    onDialogClose(refreshData = false) {
      this.dialog = false;
      this.currentLabel = {};

      if (refreshData) {
        this.fetchData();
      }
    }
  },

  metaInfo: {
    title: "Labels"
  }
};
</script>
