import { CarouselMixin } from "./carousel-mixin";

export const CarouselPagingByCursorMixin = {
  data() {
    return {
      currentItemNumber: 1,
      pagingDirection: undefined,
      // Name of item property to set in the paging cursor
      pagingCursorItemPropertyName: "id",
      scrollPrevented: false,
      redrawCarousel: false,
      isLastPageFromResponse: true,
      isFirstPageFromResponse: true,
      itemRouteParamPropName: "id"
    };
  },
  computed: {
    carouselConfig() {
      let config = CarouselMixin.computed.carouselConfig.call(this);
      config.multipleRecordsMode.counterText = `${this.currentItemNumber}/${this.totalItems}`;
      config.redrawCarousel = this.redrawCarousel;

      const defaultHeight = config.style.height;
      const newHeight = window.innerHeight * 0.76;

      if (newHeight > defaultHeight) {
        config.style.height = newHeight;
      }

      return config;
    },

    showForwardArrow() {
      return (
        this.multipleItemsMode && this.currentItemNumber !== this.totalItems
      );
    },

    showBackArrow() {
      return this.multipleItemsMode && this.currentItemNumber !== 1;
    },

    isLastPage() {
      return (
        this.isLastPageFromResponse ||
        // Need to add calculation because when switch from 2nd page to 1st - backend return next link even if next page does not exist
        this.currentItemNumber -
          this.currentItemIndex +
          this.items.length -
          1 ===
          this.totalItems
      );
    },

    isFirstPage() {
      return this.isFirstPageFromResponse;
      // return (
      //   this.currentItemNumber <= this.itemsPerPage &&
      //   this.currentItemNumber - this.currentItemIndex === 1
      // );
    },

    titleMaxWidth() {
      return this.carouselConfig.multipleRecordsMode.enabled ? 500 : 600;
    }
  },
  methods: {
    getTotalCounter() {
      throw new Error("Not implemented");
    },

    processResponseData(response) {
      if (response.data) {
        if (Array.isArray(response.data)) {
          this.multipleItemsMode = true;
          this.itemsPerPage = response.meta.per_page;
          this.items = response.data || [];
          this.isLastPageFromResponse = !response.links.next;
          this.isFirstPageFromResponse = !response.links.prev;
        } else {
          this.multipleItemsMode = false;
        }
      }
    },

    applyUpdateItem(status) {
      this.setGlobalLoading(true);
      this.disabledButton = true;

      let params = this.getRequestParams(status);

      this.updateItem(params)
        .then(async () => {
          this.setGlobalLoading(false);

          if (this.items.length === 1) {
            if (this.isFirstPage && this.isLastPage) {
              this.changePageUriToItemsList();
              return;
            }
            if (!this.isLastPage) {
              await this.scrollItemsForward({ preventChangeItemNumber: true });
            } else {
              await this.scrollItemsBack();
            }
          }
          // We can't change item index after we already did this above.  So we need "else" to prevent double paging processing.
          else {
            if (this.currentItemIndex > this.items.length - 1 - 1) {
              if (!this.isLastPage) {
                await this.scrollItemsForward({
                  preventChangeItemNumber: true
                });
              } else {
                await this.scrollItemsBack();
              }
            }
          }

          this.totalItems--;
          this.removeItemById(params[this.itemRouteParamName]);
          this.changePageUriToSingleItem();
        })
        .finally(() => {
          this.disabledButton = false;
        });
    },

    removeItemById(id) {
      this.items = this.items.filter(item => item.id !== id);
    },

    getPrevPageRequestParams() {
      return {
        cursor: this.items[0][this.pagingCursorItemPropertyName],
        direction: "DESC"
      };
    },

    getNextPageRequestParams() {
      return {
        cursor: this.items[this.items.length - 1][
          this.pagingCursorItemPropertyName
        ],
        direction: "ASC"
      };
    },

    getRequestParams(status = this.$route.meta.status) {
      let params =
        this.pagingDirection === "ASC"
          ? this.getNextPageRequestParams()
          : this.pagingDirection === "DESC"
          ? this.getPrevPageRequestParams()
          : [];

      params["status"] = status;
      params[this.itemRouteParamName] = this.items[this.currentItemIndex]?.[
        this.itemRouteParamPropName
      ];
      return params;
    },

    /**
     * @param {Boolean} preventChangeItemNumber - prevent changing currentItemNumber when next page loaded
     */
    async scrollItemsForward(
      { preventChangeItemNumber } = { preventChangeItemNumber: false }
    ) {
      if (this.scrollPrevented) return false;
      this.preventScroll();

      if (this.currentItemIndex >= this.items.length - 1) {
        await this.loadNextPage();
      } else {
        this.currentItemIndex++;
      }
      // On update items, if we deleted last item from items array - we must load next page (if exists) and show next item to user.
      // We can't change currentItemNumber because paging position is not changed.
      if (!preventChangeItemNumber) {
        this.currentItemNumber++;
      }

      this.$nextTick(() => {
        this.changePageUriToSingleItem();
      });

      this.allowScroll();
    },

    async scrollItemsBack() {
      if (this.scrollPrevented) return false;
      this.preventScroll();

      this.currentItemNumber--;
      if (this.currentItemIndex === 0 && !this.isFirstPage) {
        await this.loadPrevPage();
      } else {
        this.currentItemIndex--;
      }

      this.$nextTick(() => {
        this.changePageUriToSingleItem();
      });

      this.allowScroll();
    },

    initData() {
      this.pagingDirection = undefined;
      this.currentItemNumber = 1;
      this.items = [];
      return CarouselMixin.methods.initData.apply(this).finally(() => {
        this.fetchTotalCounter();
        this.changePageUriToSingleItem();
      });
    },

    fetchTotalCounter() {
      this.setGlobalLoading(true);
      let params = this.getRequestParams();
      this.getTotalCounter(params)
        .then(({ count }) => {
          this.totalItems = count;
        })
        .finally(() => {
          this.setGlobalLoading(false);
        });
    },

    async loadNextPage() {
      if (!this.isLastPage) {
        this.setGlobalLoading(true);
        this.pagingDirection = "ASC";
        await this.fetchData();
        this.currentItemIndex = 0;

        this.setGlobalLoading(false);
      }
    },

    async loadPrevPage() {
      if (!this.isFirstPage) {
        this.setGlobalLoading(true);
        this.pagingDirection = "DESC";
        await this.fetchData();
        this.currentItemIndex = this.items.length - 1;
        this.setGlobalLoading(false);
      }
    },

    preventScroll() {
      this.scrollPrevented = true;
    },

    allowScroll() {
      this.scrollPrevented = false;
    }
  }
};
