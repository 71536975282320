import {
  required,
  email,
  min,
  max,
  regex,
  between
} from "vee-validate/dist/rules";
import { extend, localize } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("min", {
  ...min,
  message: "Too short value."
});

extend("max", {
  ...max,
  message: "Too long value."
});

extend("regex", {
  ...regex,
  message: "Invalid format"
});

extend("confirm", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Passwords must match"
});

extend("maxItems", {
  params: ["max_value"],
  message: "Too many items",
  validate(value, { max_value }) {
    return value.length <= max_value;
  }
});

extend("between", {
  ...between,
  message: "Value must be in between {min} and {max}"
});

extend("before", {
  params: ["target"],
  validate(value, { target }) {
    return value <= target;
  },
  message: `Value must be less or equal {target}`
});

extend("after", {
  params: ["target"],
  validate(value, { target }) {
    return value >= target;
  },
  message: `Value must be greater or equal {target}`
});

localize({
  en: {
    names: {
      passwordConfirm: "Password",
      password: "Password"
    },
    fields: {
      password: {
        min: "{_field_} is too short."
      },
      passwordConfirm: {
        min: "{_field_} is too short."
      }
    }
  }
});
