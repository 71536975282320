<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
      v-blur-all-on-close-dialog
  >
    <validation-observer ref="form" v-slot="{ invalid }">
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>Edit label</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <text-field
                  label="Name"
                  v-model="innerLabel.name"
                  :validation-rules="{
                    required: true,
                    regex: /^[^<>*%:&,\;|]+$/i,
                    min: 2,
                    max: 35
                }"></text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="processing" @click="onClosed">
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="processing"
              :disabled="invalid || processing || !changed"
              @click.prevent="onSubmit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

import { deepCopy, objectsEqual } from "@/components/helpers";
import TextField from "@/components/forms/inputs/TextField";

export default {
  name: "EditLabel",

  components: {
    TextField
  },

  data: () => ({
    processing: false,
    invalid: false,
    changed: false,
    dialog: false,
    innerLabel: {}
  }),

  props: {
    dialogOpened: {
      type: Boolean,
      default: false
    },
    label: {
      type: Object,
      required: false,
      default: () => {}
    },
    callback: {
      type: Function
    }
  },

  watch: {
    dialog: function(value) {
      // Reset form each time we close the dialog
      if (!value) {
        this._resetForm();
        this.$emit('dialog-close');
      } else {
        this._setInnerLabel();
        this.$emit('dialog-open');
      }
    },

    dialogOpened: function(value) {
      this.dialog = value;
    },

    innerLabel: {
      deep: true,
      handler: function(val, oldVal) {
        const old = JSON.parse(JSON.stringify(oldVal));

        if (Object.keys(old).length > 0) {
          this.changed = !objectsEqual(this.innerLabel, this.label);
        }
      }
    }
  },

  methods: {
    ...mapActions(["updateLabel"]),

    _resetForm() {
      // Reset validation. It also should reset inputs, but it doesn't in our case,
      // not sure why, so we do it manually
      this.$refs.form.reset();
      this._setInnerLabel();
    },

    _setInnerLabel() {
      this.innerLabel = deepCopy(this.label);
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.processing = true;
        this.updateLabel(this.innerLabel)
            .then(data => {
              this.onClosed();

              this.callback && this.callback(data);
            })
            .catch(error => {
              const errors = error?.response?.data?.data;
              this.$refs.form.setErrors(errors);
            })
            .finally(() => {
              this.changed = false;
              this.processing = false;
            });
      }
    },

    onClosed() {
      this.dialog = false;
    }
  }
};
</script>
