<template>
  <layout>
    <template #header>
      <user-menu
        v-if="userCurrentAccount"
        :currentAccount="userCurrentAccount"
      />
    </template>

    <template #menu="{miniSideBar}">
      <sidebar-menu
        :toggle="miniSideBar"
        :items="pageMenuItems"
        :prev-page="prevPage"
        :detailsSectionComponent="pageDetailsSectionComponent"
        :detailsSectionComponentProps="pageDetailsSectionComponentProps"
        :currentAccount="userCurrentAccount"
      ></sidebar-menu>
    </template>
  </layout>
</template>

<script>
import { mapGetters } from "vuex";
import Layout from "@/layouts/components/Layout";
import UserMenu from "@/layouts/components/menus/UserMenu";
import SidebarMenu from "@/layouts/components/menus/SidebarMenu";

export default {
  components: { SidebarMenu, Layout, UserMenu },

  computed: {
    ...mapGetters([
      "userCurrentAccount",
      "pageMenuItems",
      "prevPage",
      "pageDetailsSectionComponent",
      "pageDetailsSectionComponentProps"
    ])
  }
};
</script>

<style lang="sass">
@import ~vuetify/src/styles/settings/_variables.scss

@media #{map-get($display-breakpoints, 'md-and-down')}
  .v-main
    padding: 56px 0px 0px 56px !important
</style>
