<template>
  <v-stepper-content :step="stepId" class="pa-0 notransition">
    <v-card-text>
      <selected-file :file="file" :remove-button-options="{}"></selected-file>

      <v-row class="d-flex flex-column" dense v-if="loading">
        <import-progress
          :message="progressMessage"
          v-model="progress"
        ></import-progress>
      </v-row>
      <v-row v-else class="d-flex flex-column pt-6 import-main-block" dense>
        <template v-if="!completed">
          <p>
            Validation passed successfully. The file contains
            {{ model.statistics.total_rows_count }} row(s) that will be imported
            to the <span class="font-weight-bold">{{ currentAccountName }}</span
            >.
          </p>

          <v-row
            class="override-records-block"
            align="end"
            v-if="allowOverride"
          >
            <v-checkbox
              v-model="override"
              :disabled="loading"
              label="Override existing records with information from CSV (empty values/columns will NOT erase the existing records data)"
            ></v-checkbox>
          </v-row>
        </template>
        <template v-else>
          <div>
            <p>
              We have successfully imported
              {{ model.statistics.imported_rows_count }} record(s) into your
              account.
            </p>
          </div>
        </template>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="onClose" :disabled="loading" v-if="!completed"
        >Close</v-btn
      >
      <v-btn
        text
        v-if="!completed"
        color="primary"
        @click="runImport"
        :disabled="loading"
        >Import</v-btn
      >
      <v-btn v-else text color="primary" @click="onClose">Done</v-btn>
    </v-card-actions>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { IMPORT_WIZARD_STEPS } from "@/components/enums";
import { IMPORT_PROGRESS_MONITORING_INTERVAL } from "@/components/constants";
import ImportProgress from "@/components/import/ImportProgress";
import SelectedFile from "@/components/import/SelectedFile";

export default {
  name: "ImportDataStep",

  components: { ImportProgress, SelectedFile },

  data: () => ({
    completed: false,
    loading: false,
    override: false,
    progress: 0,
    progressMessage:
      "Please wait while we importing content from your file. The process may take several minutes, depending on the file size."
  }),

  props: {
    model: {
      type: Object
    },
    file: {
      type: File,
      default: null
    },
    options: {
      type: Object,
      default: () => {
        return {
          allowOverride: true
        };
      }
    }
  },

  computed: {
    ...mapGetters(["userCurrentAccount"]),

    stepId() {
      return IMPORT_WIZARD_STEPS.import;
    },

    currentAccountName() {
      return this.userCurrentAccount?.name;
    },

    allowOverride() {
      return this.options?.allowOverride;
    }
  },

  methods: {
    ...mapActions(["contentImportExecute", "contentImportStatus"]),

    onClose() {
      this.completed = false;
      this.loading = false;
      this.override = false;
      this.progress = 0;
      this.$emit("dialog-closed");
    },

    calcProgress(statistics) {
      this.progress = Math.floor(
        ((statistics.successful_rows_count + statistics.failed_rows_count) /
          statistics.total_rows_count) *
          100
      );
    },

    async runImport() {
      const params = {
        importId: this.model.id,
        override: this.override
      };

      this.loading = true;

      try {
        let data = await this.contentImportExecute(params);

        if (data) {
          await this.waitUntilProcessed(params);
        }
      } finally {
        this.loading = false;
      }
    },

    async waitUntilProcessed(params) {
      let response = await this.contentImportStatus(params);

      this.calcProgress(response?.data?.statistics);

      if (response?.data?.processing_status !== "processed") {
        await new Promise(resolve =>
          setTimeout(resolve, IMPORT_PROGRESS_MONITORING_INTERVAL)
        );
        await this.waitUntilProcessed(params);
      } else {
        // a small delay allows to see completed progress before moving to the next screen
        await new Promise(resolve => setTimeout(resolve, 1000));

        this.$emit("update-model", response?.data || {});
        this.completed = true;
        return Promise.resolve();
      }
    }
  }
};
</script>

<style scoped lang="sass">
.import-main-block
  height: 225px
  padding: 0 5px

  .override-records-block
    margin: -12px 0px
</style>
