import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Rollbar from "rollbar";
import "./plugins/vee-validate";
import Meta from "vue-meta";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { date } from "@/filters/date";
import { default_value } from "@/filters/default_value";
import { formatBytes } from "@/filters/files";
import { BlurAllOnCloseDialogDirective } from "@/directives/BlurAllOnCloseDialog";

Vue.config.productionTip = true;

Vue.use(VueCompositionAPI);
Vue.use(Meta);

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Install filters globally
Vue.filter("date", date);
Vue.filter("default_value", default_value);
Vue.filter("format_bytes", formatBytes);

// Load directives
Vue.directive("blurAllOnCloseDialog", BlurAllOnCloseDialogDirective);

// Rollbar setup
Vue.prototype.$rollbar = new Rollbar({
  enabled: !["localhost", "127.0.0.1"].includes(location.hostname),
  accessToken: process.env.VUE_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.VUE_APP_ENV, //Added new variable VUE_APP_ENV because build command always replace NODE_ENV to `production`
  captureIp: "anonymize"
});

// Report uncaught errors to Rollbar
Vue.config.errorHandler = (err, vm, info) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

export const VueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    if (process.env.NODE_ENV === "development") {
      console.log("process.env: ", process.env);
    }

    // User may has set some specific accountId by directly editing URL
    const accountId = this.$route.params?.accountId;
    store.dispatch("processAccountIdInUrl", {
      accountId,
      path: this.$route.path
    });
  }
});

store
  .dispatch("initAction", {
    isPublicPage: router.currentRoute.meta?.public
  })
  .then(() => {
    VueApp.$mount("#app");
    return VueApp;
  });
