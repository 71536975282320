<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1 justify-space-between">
          <span
            >Conflicts History – {{ youtubeAssetId
            }}<v-hover v-slot="{ hover }" :open-delay="200">
              <span class="clickable">
                <sup class="text-sm-body-2">
                  <v-icon small color="primary">mdi-information</v-icon>
                </sup>
                <template v-if="hover">
                  <v-card
                    min-width="400"
                    max-width="450"
                    elevation="3"
                    class="conflict-popup-block"
                  >
                    <conflict-metadata
                      :recording="currentConflictingRecording"
                    ></conflict-metadata>
                  </v-card>
                </template>
              </span>
            </v-hover>
          </span>
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-data-table
        selectable
        :headers="headers"
        :items="results"
        :options.sync="options"
        :server-items-length="totalResultsCount"
        :loading="loading"
        class="elevation-0"
        :footer-props="tableFooterProps"
      >
        <template v-slot:item="{ item }">
          <tr class="table-row" :key="item.id">
            <td class="text-start">
              <span>
                {{ item.conflicting_views }}
              </span>
            </td>
            <td class="text-start">
              <span>{{ item.conflicting_owner.name }}</span>
            </td>
            <td class="text-start table-secondary-column">
              <span>{{ item.conflicting_right_type }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{
                formatOwnershipShares(item.ownership_shares) | default_value
              }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.conflict_started_at | date }}</span>
            </td>
            <td class="text-end table-secondary-column">
              <span>{{ item.conflict_resolved_at | date }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { OwnershipConflictsPageMenu } from "@/mixins/ownership-conflicts-page-menu";
import { GridUtils } from "@/mixins/grid-utils";
import { DataTableFooterProps } from "@/mixins/data-table-footer-props-mixin";
import ConflictMetadata from "@/pages/ownershipConflicts/history/components/ConflictMetadata.vue";

export default {
  name: "SingleAssetConflictsHistory",

  components: { ConflictMetadata },

  mixins: [OwnershipConflictsPageMenu, GridUtils, DataTableFooterProps],

  data: () => ({
    persistentStateKey: "ownership-conflicts-single-asset-history",
    headers: [
      {
        text: "Conflicting Views",
        sortable: true,
        value: "conflicting_views",
        width: "10%"
      },
      {
        text: "Conflicting Owner",
        sortable: false,
        width: "25%"
      },
      {
        text: "Conflicting Right Type",
        sortable: false,
        align: "start",
        width: "12%"
      },
      {
        text: "Conflicting Countries",
        sortable: false,
        align: "end",
        width: "30%"
      },
      {
        text: "In Conflict",
        sortable: true,
        value: "conflict_started_at",
        align: "end",
        width: "10%"
      },
      {
        text: "Conflict Resolved",
        sortable: true,
        value: "conflict_resolved_at",
        align: "end",
        width: "10%"
      }
    ]
  }),

  computed: {
    ...mapGetters(["currentConflictingRecording"]),

    youtubeAssetId() {
      return this.currentConflictingRecording?.youtube_asset_id;
    },

    pageTitle() {
      return "Conflicts History – " + this.youtubeAssetId;
    }
  },

  methods: {
    ...mapActions([
      "getSingleAssetConflictsHistory",
      "resetCurrentConflictingRecording"
    ]),

    getResultsActionName() {
      return "getSingleAssetConflictsHistory";
    },

    getQueryParams() {
      let params = GridUtils.methods.getQueryParams.call(this);

      return {
        ...params,
        ...{
          recordingId: this.$route.params.recordingId
        }
      };
    },

    formatOwnershipShares(shares) {
      return Object.entries(shares)
        .map(([countryCode, number]) => `${countryCode} (${number})`)
        .join(", ");
    }
  },

  beforeRouteLeave(to, from, next) {
    this.resetCurrentConflictingRecording();
    next();
  },

  metaInfo() {
    return {
      title: this.pageTitle
    };
  }
};
</script>

<style scoped lang="sass">
.conflict-popup-block
  position: absolute
  z-index: 999
</style>
