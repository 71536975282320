import { render, staticRenderFns } from "./RecordingMetadataCard.vue?vue&type=template&id=a42be646&scoped=true&"
import script from "./RecordingMetadataCard.vue?vue&type=script&lang=js&"
export * from "./RecordingMetadataCard.vue?vue&type=script&lang=js&"
import style0 from "./RecordingMetadataCard.vue?vue&type=style&index=0&id=a42be646&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a42be646",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCard,VCardText,VContainer,VDataTable,VSimpleCheckbox})
