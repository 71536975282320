<template>
  <v-container class="text--primary pa-0 overflow-hidden d-flex flex-column">
    <v-card-text class="text-subtitle-2">Providers metadata</v-card-text>

    <!-- Explicitly set "light" theme. Don't know why but it puts dark theme otherwise for any tables in cards-->
    <v-card class=" ma-4 mt-0 mb-1 d-flex overflow-hidden" light>
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="related_metadata"
        :items-per-page="-1"
        class="elevation-1"
        fixed-header
        :show-select="showCheckboxColumn"
        @toggle-select-all="selectAllToggle"
        no-data-text="No invalid data"
        height="100%"
        style="width: 100%"
        :value="selectedRelatedMetadataItems"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td class="table-secondary-column">
              <v-card-text class="px-0">{{
                item.provider | default_value
              }}</v-card-text>
            </td>

            <td class="text-end table-secondary-column">
              <highlighted-label
                :text="item.iswc | default_value"
                :highlighted-part="effective_metadata.iswc"
              >
              </highlighted-label>
            </td>

            <td class="text-end table-secondary-column">
              <highlighted-label
                :text="item.hfa | default_value"
                :highlighted-part="effective_metadata.hfa"
              >
              </highlighted-label>
            </td>

            <td class="text-end table-secondary-column">
              <highlighted-label
                :text="item.title | default_value"
                :highlighted-part="effective_metadata.title"
              >
              </highlighted-label>
            </td>

            <td class="text-end table-secondary-column">
              <highlighted-labels
                :items="stringToArray(item.writers)"
                :highlighted-parts="stringToArray(effective_metadata.writers)"
              >
              </highlighted-labels>
            </td>

            <td class="table-selector-column" v-if="showCheckboxColumn">
              <v-simple-checkbox
                v-model="item.is_suspicious"
                color="primary"
                :ripple="false"
              ></v-simple-checkbox>
            </td>
          </tr>
        </template>

        <!--        <template v-slot:header.data-table-select="{ props, on }">-->
        <!--          <v-simple-checkbox-->
        <!--            color="primary"-->
        <!--            v-bind="props"-->
        <!--            v-on="on"-->
        <!--          ></v-simple-checkbox>-->
        <!--        </template>-->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import HighlightedLabel from "@/components/forms/HighlightedLabel";
import HighlightedLabels from "@/components/forms/HighlightedLabels";
import { RECORDING_STATUS } from "@/components/enums";

export default {
  name: "RecordingMetadataCard",

  components: {
    HighlightedLabels,
    HighlightedLabel
  },

  props: {
    effective_metadata: {
      type: Object
    },
    related_metadata: {
      type: Array
    }
  },

  computed: {
    showCheckboxColumn() {
      return this.$route.meta.status !== RECORDING_STATUS.approved;
    },

    selectedRelatedMetadataItems() {
      return this.related_metadata.filter(item => item.is_suspicious);
    },
    headers() {
      return [
        ...this.defaultHeaders,
        ...(this.showCheckboxColumn ? [{ value: "data-table-select" }] : [])
      ];
    }
  },

  methods: {
    stringToArray(value) {
      return value ? value.split(",") : [];
    },

    selectAllToggle({ items, value: isSelected }) {
      items.forEach(item => {
        item.is_suspicious = isSelected;
      });
    }
  },

  data() {
    return {
      defaultHeaders: [
        {
          text: "Provider",
          align: "start",
          sortable: false,
          width: 200
        },
        {
          text: "ISWC",
          align: "end",
          sortable: false,
          width: "110px"
        },
        {
          text: "HFA",
          align: "end",
          sortable: false,
          width: "100px"
        },
        {
          text: "Title",
          align: "end",
          sortable: false,
          width: "40%"
        },
        {
          text: "Writers",
          align: "end",
          sortable: false,
          width: "40%"
        }
      ]
    };
  }
};
</script>

<style lang="sass" scoped>
.text-start
  max-width: 50px
  word-wrap: break-word
</style>
