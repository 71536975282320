var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"blur-all-on-close-dialog",rawName:"v-blur-all-on-close-dialog"}],attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-toolbar',{staticClass:"headline",attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Edit label")])],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form"},[_c('text-field',{attrs:{"label":"Name","validation-rules":{
                  required: true,
                  regex: /^[^<>*%:&,\;|]+$/i,
                  min: 2,
                  max: 35
              }},model:{value:(_vm.innerLabel.name),callback:function ($$v) {_vm.$set(_vm.innerLabel, "name", $$v)},expression:"innerLabel.name"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.processing},on:{"click":_vm.onClosed}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.processing,"disabled":invalid || _vm.processing || !_vm.changed},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" Save ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }