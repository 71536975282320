import { request } from "@/services/api";

export default {
  name: "labels",

  actions: {
    getLabels({ getters, rootState }, params) {
      const accountId = getters.userCurrentAccountId;

      return request.get(
        `${rootState.apiPrefix}/accounts/${accountId}/labels?page=${params.page}&size=${params.size}&query=${params.query}`
      );
    },

    createLabel({ getters, rootState }, newLabel) {
      const accountId = getters.userCurrentAccountId;

      return request.post(
        `${rootState.apiPrefix}/accounts/${accountId}/labels`,
        newLabel
      );
    },

    updateLabel({ getters, rootState }, label) {
      const accountId = getters.userCurrentAccountId;

      return request.put(
        `${rootState.apiPrefix}/accounts/${accountId}/labels/${label.id}`,
        label
      );
    },

    deleteLabel({ getters, rootState }, id) {
      const accountId = getters.userCurrentAccountId;

      return request.delete(
        `${rootState.apiPrefix}/accounts/${accountId}/labels/${id}`
      );
    }
  }
};
