export const DownloadWindow = {
  methods: {
    onDownloadWindowRequestAuthorization(downloadWindow) {
      if (
        downloadWindow.isAuthenticationRequired &&
        downloadWindow.isAuthenticationRequired()
      ) {
        downloadWindow.close();
        window.location.reload();
      }
    },
    openDownloadWindow(url, onCloseCallback = () => {}) {
      let interval;
      const downloadWindow = window.open(url, "_blank");
      downloadWindow.document.title = "Downloading..";

      interval = setInterval(() => {
        if (downloadWindow && downloadWindow.closed) {
          clearInterval(interval);
          onCloseCallback();
        }
        this.onDownloadWindowRequestAuthorization(downloadWindow);
      }, 500);
    }
  }
};
