<template>
  <noscript></noscript>
</template>

<script>
// Inspired by @burakgur (https://www.npmjs.com/package/google-one-tap)
export default {
  name: "GoogleOneTap",

  props: {
    client_id: {
      type: String,
      required: true
    },
    auto_select: {
      type: Boolean,
      default: false
    },
    cancel_on_tap_outside: {
      type: Boolean,
      default: null
    },
    context: {
      type: String
    },
    login_uri: {
      type: String
    },
    prompt_parent_id: {
      type: String
    },
    nonce: {
      type: String
    },
    state_cookie_domain: {
      type: String
    },
    ux_mode: {
      type: String
    },
    allowed_parent_origin: {
      type: Array
    },
    itp_support: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    _prepareOptions() {
      return Object.fromEntries(
        Object.entries(this.$props).filter(
          item => typeof item[1] !== "undefined" || item[1] !== null
        )
      );
    },

    _googleOneTap(
      {
        client_id,
        auto_select = false,
        cancel_on_tap_outside = false,
        context = "signin",
        ...otherOptions
      },
      callback
    ) {
      const googleScript = document.createElement("script");
      const contextValue = ["signin", "signup", "use"].includes(context)
        ? context
        : "signin";

      googleScript.src = "https://accounts.google.com/gsi/client";
      googleScript.async = true;
      googleScript.defer = true;

      document.head.appendChild(googleScript);

      window.onload = function() {
        if (window.google) {
          window.google.accounts.id.initialize({
            client_id: client_id,
            callback: callback,
            auto_select: auto_select,
            cancel_on_tap_outside: cancel_on_tap_outside,
            context: contextValue,
            ...otherOptions
          });

          window.google.accounts.id.prompt();
        }
      };
    }
  },

  mounted() {
    const options = this._prepareOptions();

    this._googleOneTap(options, response => {
      this.$emit("google-one-tap-oauth-success", response.credential);
    });
  }
};
</script>
