<template>
  <v-dialog
    v-model="dialog"
    max-width="850px"
    persistent
    scrollable
    v-blur-all-on-close-dialog
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="globalLoading">
        Edit ownership
      </v-btn>
    </template>
    <validation-observer ref="form" v-slot="{ invalid }" slim>
      <v-card>
        <v-toolbar dark class="headline" color="primary">
          <v-toolbar-title>
            Edit ownership for – {{ compositionName }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="max-height: 410px">
          <v-form ref="form">
            <v-container>
              <v-container class="pl-0 pt-0 mb-3 text--primary">
                <b>{{ accountName }}</b> represents and warrants that the
                information it submits below is true.
              </v-container>

              <edit-ownership-item
                v-for="(share, index) in innerValue"
                v-model="innerValue[index]"
                :key="share.id"
                :territories="territories"
                :position="index"
                @itemDelete="deleteOwnershipShares"
              ></edit-ownership-item>

              <v-spacer></v-spacer>

              <div
                class="red--text text-lighten-1 font-weight-bold"
                v-if="shareErrorMessage"
              >
                {{ shareErrorMessage }}
              </div>

              <v-btn
                text
                color="primary"
                @click="addShareRow"
                :disabled="invalid || hasShareErrorMessage"
              >
                Add ownership split
              </v-btn>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            text
            @click="deleteOwnership(null)"
            :loading="removing"
            :disabled="saving || removing || !hasShares"
            color="primary"
          >
            Remove all ownership
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            text
            :disabled="saving || removing"
            @click="onClosed"
            color="primary"
          >
            Close
          </v-btn>

          <v-btn
            color="primary"
            text
            :loading="saving"
            :disabled="
              invalid ||
                saving ||
                removing ||
                (!hasDeletedShares && !innerValue.length) ||
                hasShareErrorMessage
            "
            @click.prevent="saveOwnership"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditOwnershipItem from "@/pages/compositions/components/EditOwnershipItem";
import { convertSharesTerritories } from "@/pages/compositions/components/processors";
import OwnershipValidator from "@/pages/compositions/components/ownershipValidator";
import {
  OWNERSHIP_COVERAGE_TYPES,
  OWNERSHIP_LICENSE_TYPES
} from "@/pages/compositions/components/enums";
import { deepCopy } from "@/components/helpers";

export default {
  name: "EditOwnershipDialog",
  components: { EditOwnershipItem },

  data: () => ({
    saving: false,
    removing: false,
    invalid: false,
    dialog: false,
    innerValue: [],
    innerOwnershipId: null,
    shareErrorMessage: null,
    deletedShares: [],
    emptyItem: {
      license_type: OWNERSHIP_LICENSE_TYPES.synchronization,
      percent: null,
      coverage_type: OWNERSHIP_COVERAGE_TYPES.global,
      territories: []
    }
  }),

  props: {
    territories: [Object, Array],
    ownershipId: {
      type: Number
    },
    value: {
      type: Array,
      default: () => []
    },
    callback: {
      type: Function
    },
    compositionName: {
      type: String,
      default: ""
    },
    accountName: {
      type: String,
      default: ""
    }
  },

  watch: {
    dialog: function(value) {
      // Reset form each time we close the dialog
      if (!value) {
        this._resetForm();
      } else {
        this.innerValue = deepCopy(this.value);
      }
    },

    // @NOTE: How will this prop be changed outside?
    // value: function(value) {
    //   this.innerValue = this.value;
    //   this.$emit("input", value);
    // },

    innerValue: {
      deep: true,
      handler(value) {
        const me = this;

        this.innerValue = value;
        this.$emit("input", value);

        // There is a nuance with form initialization: looks like form is not ready before the first validation.
        // I'm sure it could be done in more correct way though.
        setTimeout(
          function(arg) {
            me._validateShares(arg);
          },
          100,
          value
        );
      }
    },

    ownershipId: function(newVal) {
      this.innerOwnershipId = newVal;
    }
  },

  computed: {
    ...mapGetters(["globalLoading"]),

    hasShares: function() {
      return this.innerValue.length > 0;
    },

    hasDeletedShares: function() {
      return this.deletedShares.length > 0;
    },

    hasShareErrorMessage: function() {
      return this.shareErrorMessage !== null;
    }
  },

  methods: {
    ...mapActions([
      "createCompositionOwnership",
      "deleteCompositionOwnership",
      "updateCompositionOwnership"
    ]),

    _validateShares(shares) {
      this.shareErrorMessage = null;

      this.$refs.form.validate().then(success => {
        // We dont need to validate shares if there is an error in the form's fields
        if (success) {
          const validator = new OwnershipValidator(shares, this.territories);

          if (!validator.validate()) {
            this.shareErrorMessage = `Invalid ownership split provided for the following territory: ${validator
              .getInvalidCountries()
              .join(", ")}`;
          }
        }
      });
    },

    _resetForm() {
      // Reset validation. It also should reset inputs, but it doesn't in our case,
      // not sure why, so we do it manually
      this.$refs.form.reset();
      this.innerValue = deepCopy(this.value);
    },

    onClosed() {
      this.dialog = false;
    },

    closeAndRunCallback() {
      this.onClosed();
      this.callback && this.callback();
    },

    deleteOwnership() {
      if (!this.innerOwnershipId) {
        // no model, nothing to delete, just clearing unsaved value.
        this.innerValue = [];
        return;
      }

      let data = {
        compositionId: this.$route.params.compositionId,
        ownershipId: this.innerOwnershipId
      };

      // consider we deleting the whole Ownership record with all value
      this.removing = true;
      this.deleteCompositionOwnership(data)
        .then(() => {
          this.innerValue = [];
          this.innerOwnershipId = null;

          this.closeAndRunCallback();
        })
        .finally(() => {
          this.removing = false;
        });
    },

    deleteOwnershipShares(item) {
      if (item.id) {
        if (!this.deletedShares.filter(el => el.id === item.id).length) {
          this.deletedShares.push(item);
        }
      }

      this.innerValue.splice(this.innerValue.indexOf(item), 1);
    },

    addShareRow() {
      this.innerValue.push(deepCopy(this.emptyItem));
    },

    createOwnership(value) {
      this.saving = true;

      this.createCompositionOwnership({
        compositionId: this.$route.params.compositionId,
        shares: value
      })
        .then(({ data }) => {
          this.innerValue = this._prepareShares(data);
          this.innerOwnershipId = data.id;

          this.closeAndRunCallback();
        })
        .finally(() => {
          this.saving = false;
        });
    },

    updateOwnership(value) {
      this.saving = true;

      if (this.hasDeletedShares) {
        let deleteRequestData = {
          compositionId: this.$route.params.compositionId,
          ownershipId: this.innerOwnershipId,
          shares_ids: this.deletedShares.map(el => el.id)
        };

        this.deleteCompositionOwnership(deleteRequestData)
          .then(() => {
            this.resetDeletedShares();
            if (!this.hasShares) {
              this.closeAndRunCallback();
            }
          })
          .finally(() => {
            this.saving = false;
          });
      }

      if (this.hasShares) {
        this.updateCompositionOwnership({
          compositionId: this.$route.params.compositionId,
          ownershipId: this.innerOwnershipId,
          shares: value
        })
          .then(({ data }) => {
            this.innerValue = this._prepareShares(data);

            this.closeAndRunCallback();
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },

    resetDeletedShares() {
      this.deletedShares = [];
    },

    _prepareShares(data) {
      return convertSharesTerritories(data.shares, this.territories);
    },

    saveOwnership() {
      if (!this.innerOwnershipId) {
        this.createOwnership(this.innerValue);
      } else {
        this.updateOwnership(this.innerValue);
      }
    }
  }
};
</script>
