<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          Ownership
        </v-card-title>
      </v-col>
    </v-row>

    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>

        <edit-ownership-dialog
          :territories="countries"
          :value="ownerships"
          :callback="onDialogClose"
          :ownership-id="ownershipId"
          :composition-name="compositionName"
          :account-name="accountName"
        ></edit-ownership-dialog>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="parsedOwnerships"
        :options="{ itemsPerPage: MAX_DISPLAYED_TERRITORIES }"
        :hide-default-footer="true"
        :disable-pagination="true"
        sort-by="territory"
        class="elevation-0"
        dense
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { CompositionPageMenu } from "@/mixins/composition-page-menu";
import EditOwnershipDialog from "@/pages/compositions/components/EditOwnershipDialog";
import { convertSharesTerritories } from "@/pages/compositions/components/processors";
import {
  OWNERSHIP_COVERAGE_TYPES,
  OWNERSHIP_LICENSE_TYPES_LABELS
} from "@/pages/compositions/components/enums";
import { EMPTY_VALUE, MAX_DISPLAYED_TERRITORIES } from "@/components/constants";

export default {
  name: "CompositionOwnership",
  components: { EditOwnershipDialog },
  mixins: [CompositionPageMenu],

  data: () => ({
    MAX_DISPLAYED_TERRITORIES,

    loading: false,
    ownershipId: null,
    ownerships: [],
    parsedOwnerships: [],
    countries: {},
    headers: [
      {
        text: "Territory",
        sortable: false,
        value: "territory"
      },
      {
        text: "Synchronization",
        value: "synchronization",
        sortable: false,
        width: "160px"
      },
      {
        text: "Performance",
        value: "performance",
        sortable: false,
        width: "160px"
      },
      {
        text: "Mechanical",
        value: "mechanical",
        sortable: false,
        width: "160px"
      },
      {
        text: "Lyric",
        value: "lyric",
        sortable: false,
        width: "120px"
      }
    ]
  }),

  computed: {
    accountName: function() {
      return this.userCurrentAccount().name;
    },

    compositionName: function() {
      return this.currentComposition()?.meta.title;
    }
  },

  beforeMount() {
    this.setGlobalLoading(true);
  },

  mounted() {
    this.initCurrentComposition(this.$route.params.compositionId).then(() => {
      this.fetchData();
    });

    this.getCountriesList().then(({ data }) => {
      this.countries = data;
    });
  },

  methods: {
    ...mapActions([
      "initCurrentComposition",
      "getCurrentCompositionOwnership",
      "deleteCompositionOwnership",
      "getCountriesList",
      "setGlobalLoading"
    ]),

    ...mapGetters(["currentComposition", "userCurrentAccount"]),

    fetchData() {
      this.getCurrentCompositionOwnership()
        .then(({ data }) => {
          let shares = [];

          this.ownershipId = data?.id;

          if (data?.shares?.length > 0) {
            shares = convertSharesTerritories(data.shares, this.countries);
          }

          this.ownerships = shares;
          this.parsedOwnerships = this._parseOwnerships(data);
          this._updateHeaderRow();
        })
        .finally(() => {
          this.setGlobalLoading(false);
        });
    },

    onDialogClose() {
      this.setGlobalLoading(true);
      this.fetchData();
    },

    _parseOwnerships(data) {
      let dataCopy = JSON.parse(JSON.stringify(data));
      let result = [];

      if (!Object.keys(dataCopy).length) {
        return [];
      }

      let emptyRow = {
        territory: EMPTY_VALUE,
        synchronization: EMPTY_VALUE,
        performance: EMPTY_VALUE,
        mechanical: EMPTY_VALUE,
        lyric: EMPTY_VALUE
      };

      for (const share of dataCopy.shares) {
        if (share.coverage_type === OWNERSHIP_COVERAGE_TYPES.global) {
          share.territories = Object.keys(this.countries);
        } else if (
          share.coverage_type === OWNERSHIP_COVERAGE_TYPES.exclude_territories
        ) {
          share.territories = Object.keys(this.countries).filter(
            value => !share.territories.includes(value)
          );
        }

        for (const territory of share.territories) {
          let row = { ...emptyRow };
          row.territory = this.countries?.[territory] ?? territory;
          row[share.license_type] = `${share.percent}%`;

          if (!(territory in result)) {
            result[territory] = row;
          } else {
            for (let attr in row) {
              if (attr in result[territory]) {
                if (
                  row[attr] !== EMPTY_VALUE &&
                  row[attr] !== result[territory][attr]
                ) {
                  result[territory][attr] = row[attr];
                }
              }
            }
          }
        }
      }

      return Object.values(result);
    },

    _updateHeaderRow() {
      let licenseTypeCounters = {
          synchronization: 0,
          performance: 0,
          mechanical: 0,
          lyric: 0
        },
        licenseTypes = Object.keys(licenseTypeCounters);

      for (const row of this.parsedOwnerships) {
        for (const licenseType of licenseTypes) {
          if (row[licenseType] !== EMPTY_VALUE) {
            licenseTypeCounters[licenseType]++;
          }
        }
      }

      for (let header of this.headers.values()) {
        header.text = header.text.replace(/\s\(\d+\)/, "");

        if (
          header.value in licenseTypeCounters &&
          licenseTypeCounters[header.value] !== 0
        ) {
          header.text = `${OWNERSHIP_LICENSE_TYPES_LABELS[header.value]} (${
            licenseTypeCounters[header.value]
          })`;
        }
      }
    }
  },

  metaInfo: {
    title: "Ownership"
  }
};
</script>
