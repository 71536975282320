<template>
  <v-card
    class="mx-auto mt-12 pa-4 public-app-layout"
    elevation="3"
    width="500"
  >
    <v-container class="d-flex flex-row justify-center align-center">
      <v-img
        max-height="50"
        min-height="50"
        max-width="50"
        min-width="50"
        :src="require('/public/logo.svg')"
      ></v-img>
      <span style="color: #00B8F1" class="text-h4 ml-2">Ad</span>
      <span style="color: #663366" class="text-h4">rev</span>
    </v-container>

    <v-app>
      <router-view></router-view>

      <div class="alert-wrapper">
        <alert />
      </div>
    </v-app>
  </v-card>
</template>

<script>
import Alert from "@/layouts/components/Alert";

export default {
  components: { Alert }
};
</script>

<!-- Didn't find a way to make it work with "scoped" mode -->
<style lang="sass">
.public-app-layout .v-application .v-application--wrap
  min-height: auto
.alert
  margin-right: 0
.alert-wrapper
  display: flex
  justify-content: center
</style>
