<template>
  <div>
    <v-row>
      <v-col>
        <v-card-title class="pl-1">
          General
        </v-card-title>
      </v-col>
    </v-row>
    <settings-switcher
      :processing="updateCurrentAccountSettings"
      :label="'Search sound recordings?'"
      :account="userCurrentAccount"
      :setting-name="'recordings_search_enabled'"
    ></settings-switcher>
    <settings-switcher
      :processing="updateCurrentAccountSettings"
      :label="'Backup assets reports?'"
      :account="userCurrentAccount"
      :setting-name="'backup_assets_reports'"
    ></settings-switcher>
    <settings-switcher
      :processing="updateCurrentAccountSettings"
      :label="'Import assets reports'"
      :account="userCurrentAccount"
      :setting-name="'import_assets_reports'"
    ></settings-switcher>
    <settings-switcher
      :processing="updateCurrentAccountSettings"
      :label="'API access'"
      :account="userCurrentAccount"
      :setting-name="'api_access_enabled'"
    ></settings-switcher>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SettingsPageMenu } from "@/mixins/settings-page-menu";
import SettingsSwitcher from "@/components/SettingsSwitcher.vue";

export default {
  name: "AccountManagement",
  mixins: [SettingsPageMenu],
  components: { SettingsSwitcher },

  computed: {
    ...mapGetters(["userCurrentAccount"])
  },

  methods: {
    ...mapActions(["updateCurrentAccountSettings"])
  },

  metaInfo: {
    title: "General"
  }
};
</script>
