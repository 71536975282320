import escapeRegExp from "lodash.escaperegexp";
import { normalizeText } from "@/components/helpers";

export const HighlightedLabelsMixin = {
  computed: {
    hasItems() {
      return this.items ? this.items.length : false;
    },

    matchedItems() {
      return this._getMatchedItems();
    },

    allItems() {
      /*
       *  In the YouTubeStudio we saw some valid records with "|" as limiter between writers.
       *  So we add "|" as writers limiter to solve matching issue.
       */
      return this.splitItemsBySymbol(this.items, "|");
    }
  },

  methods: {
    highlightWords(item) {
      let originalItem = item.slice();
      let ranges = this.matchedItems[item];
      let separator = ", ";

      if (ranges) {
        for (let range of ranges) {
          const part = originalItem.trim().substring(range[0], range[1]);
          let escapedPart = escapeRegExp(part);
          const pattern = `\\b(${escapedPart})(?![^<]*>|[^<>]*<\\/)`;

          item = item.replace(
            new RegExp(pattern, "i"),
            '<span class="highlighted-match">' + part + "</span>"
          );
        }
      }

      if (
        this.items.length > 1 &&
        this.items.indexOf(originalItem) !== this.items.length - 1
      ) {
        item += separator;
      }

      return item;
    },

    _isMatchFound(highlightedPart, item) {
      let splittedHighlightedPart = highlightedPart.split(/\s|_\//g);

      let matchesCount = 0;
      let matchRange = [];
      const badAssetPrefix = "**bad asset**";

      for (let partsWord of splittedHighlightedPart) {
        for (let itemsWord of item
          .replace(badAssetPrefix, "")
          .split(/\s|_\//g)) {
          if (partsWord === itemsWord || `${partsWord}.` === itemsWord) {
            matchesCount++;

            // Dot should be in highlightedPart to keep proper range for matched words,
            // but here it is being replaced to get a valid regex for further matching.
            const pattern = `\\b${partsWord.replace(/\./g, "")}\\b\\.?`;

            for (const matchIndex of [
              ...item.matchAll(new RegExp(pattern, "gi"))
            ].map(a => a.index)) {
              let firstIndex =
                matchIndex !== -1 ? matchIndex : item.indexOf(itemsWord);
              let lastIndex = firstIndex + itemsWord.length;

              matchRange.push([firstIndex, lastIndex]);
            }
          }
        }
      }

      return {
        hasMatch: !!matchesCount,
        range: matchRange
      };
    },

    _getMatchedItems() {
      let matches = {};

      for (let highlightedPart of this.highlightedParts) {
        for (let item of this.allItems) {
          let normalizedHighlightedPart = normalizeText(highlightedPart);
          let normalizedItem = normalizeText(item);
          let matchResult = this._isMatchFound(
            normalizedHighlightedPart,
            normalizedItem
          );

          if (matchResult.hasMatch) {
            if (item in matches) {
              matches[item] = matches[item].concat(matchResult.range);
            } else {
              matches[item] = matchResult.range;
            }
          }
        }
      }

      return matches;
    },

    splitItemsBySymbol(items, s) {
      return items.map(i => i.split(s)).flat();
    }
  }
};
