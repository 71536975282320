<template>
  <v-row class="mb-1 pb-6" v-if="headerVisible">
    <v-col></v-col>
    <v-col cols="auto" class="mr-auto align-self-center">
      <component :is="component" v-bind="downloadMetadataProps"></component>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import DownloadMetadata from "@/components/buttons/DownloadMetadata";
import DownloadReport from "@/components/buttons/DownloadReport";

export default {
  name: "ApprovedRecordingsHeader",
  props: {
    downloadMetadataProps: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function
    },
    component: {
      type: String,
      default: "DownloadMetadata"
    }
  },
  components: {
    DownloadReport,
    DownloadMetadata
  },
  computed: {
    ...mapGetters(["globalLoading"]),

    headerVisible() {
      return !this.globalLoading && this.$route.meta.showDownloadMetadataHeader;
    }
  }
};
</script>
