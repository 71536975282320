<template>
  <v-stepper-content :step="stepId" class="pa-0 notransition">
    <v-card-text>
      <selected-file
        :file="file"
        :remove-button-options="selectedFileRemoveButtonOptions"
        @remove-file="onFileRemove"
      ></selected-file>

      <v-row class="d-flex flex-column" dense v-if="loading">
        <import-progress :message="progressMessage"></import-progress>
      </v-row>
      <v-row v-else-if="parsedErrors">
        <validation-errors :errors="parsedErrors"></validation-errors>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="onClose" :disabled="loading">Close</v-btn>
      <v-btn
        text
        color="primary"
        @click="uploadAndValidate"
        :disabled="!file || loading || errors !== null"
        >Upload and verify</v-btn
      >
    </v-card-actions>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex";
import { IMPORT_ERRORS_TYPES, IMPORT_WIZARD_STEPS } from "@/components/enums";
import { VALIDATION_PROGRESS_MONITORING_INTERVAL } from "@/components/constants";
import SelectedFile from "@/components/import/SelectedFile";
import ImportProgress from "@/components/import/ImportProgress";
import ValidationErrors from "@/components/import/ValidationErrors";

export default {
  name: "UploadAndValidateFileStep",

  components: { SelectedFile, ImportProgress, ValidationErrors },

  props: {
    importType: {
      type: String
    },

    file: {
      type: File,
      default: null
    }
  },

  data: () => ({
    dragover: false,
    loading: false,
    errors: null,
    progressMessage:
      "Please wait while we uploading and verifying your file. The process may take several minutes, depending on the file size."
  }),

  computed: {
    stepId() {
      return IMPORT_WIZARD_STEPS.validate;
    },

    selectedFileRemoveButtonOptions() {
      return { disabled: this.loading, visible: true };
    },

    parsedErrors() {
      let convertedErrors = {
        type: IMPORT_ERRORS_TYPES.line_validation,
        data: {}
      };

      if (this?.errors?.data) {
        for (const value of Object.values(this.errors.data)) {
          if (typeof value?.[0] === "object") {
            for (const rowErrors of value) {
              convertedErrors.data[`line ${rowErrors.line}`] = rowErrors.errors;
            }
          }
        }
      }

      return Object.keys(convertedErrors.data).length
        ? convertedErrors
        : this.errors;
    }
  },

  methods: {
    ...mapActions(["contentUpload", "contentImportStatus"]),

    onClose() {
      this.loading = false;
      this.errors = null;
      this.onFileRemove();
      this.$emit("dialog-closed");
    },

    setErrors(errors, type = IMPORT_ERRORS_TYPES.other) {
      this.errors = {
        type: type,
        data: errors
      };
    },

    async uploadAndValidate() {
      this.loading = true;

      let response = await this.contentUpload({
        file: this.file,
        importType: this.importType
      });

      const params = {
        importId: response?.data?.id
      };

      try {
        await this.waitUntilValidated(params);
      } finally {
        this.loading = false;
      }
    },

    async waitUntilValidated(params) {
      let response = await this.contentImportStatus(params);

      if (response?.data?.processing_status !== "validated") {
        // retry in 20 seconds
        await new Promise(resolve =>
          setTimeout(resolve, VALIDATION_PROGRESS_MONITORING_INTERVAL)
        );
        await this.waitUntilValidated(params);
      } else {
        const errors = response?.data?.validation_errors || {};

        if (Object.keys(errors).length) {
          this.setErrors(errors);
        } else {
          this.$emit("update-model", response?.data || {});
        }
        return Promise.resolve();
      }
    },

    onFileRemove() {
      this.errors = null;
      this.$emit("set-file", null);
    }
  }
};
</script>
