<template>
  <div class="ongoing-operation d-flex pt-6">
    <v-progress-circular
      :size="25"
      :rotate="270"
      color="primary"
      :indeterminate="typeof value == 'undefined'"
      :value="value"
      class="mr-3"
    ></v-progress-circular>
    <p v-if="message">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: "ImportProgress",

  props: {
    message: {
      type: String,
      default: ""
    },

    value: {
      type: Number
    }
  }
};
</script>

<style scoped lang="sass">
.ongoing-operation
  padding: 0 12px
</style>
